import { Alert, Button, Input } from '@material-tailwind/react';
import { getCookie, removeCookie, setCookie } from '../../../utils/helper';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { updateWifis } from '../../../services/apiUsers';

function Wifi() {
  const auth = getCookie('token');
  const [ipAddress, setIpAddress] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    document.title = 'Cập nhật wifi nội bộ';
    axios
      .get('https://ipinfo.io/json')
      .then((response) => {
        setIpAddress(response.data.ip);
      })
      .catch((error) => {
        console.error('Error fetching IP address:', error);
      });
  }, []);

  const updateWifi = async () => {
    const body = {
      w: ipAddress,
    };
    const resultAPISIM = await updateWifis(body);
    if (resultAPISIM?.message === 'OK') {
      setOpenAlert(true);
      const info = { ...auth, wifi: ipAddress };
      removeCookie('token');
      setCookie('token', info);
    } else {
      setOpenAlert(false);
    }
  };
  return (
    <div>
      <Alert color={'green'} className="mb-2" open={openAlert} onClose={() => setOpenAlert(false)}>
        {' '}
        CẬP NHẬT THÀNH CÔNG
      </Alert>
      <h3 className="font-bold mb-1">CẬP NHẬT IP WIFI NỘI BỘ</h3>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
        <div className="col-span-2">
          <Input value={auth?.wifi} label="IP WIFI" disabled />
          {ipAddress !== auth?.wifi && (
            <span className="text-red-500 text-sm">
              IP MỚI: <b>{ipAddress}</b> (Bạn hãy ấn nút cập nhật lại IP MỚI)
            </span>
          )}
        </div>
        <div>
          <Button className="bg-gradient-to-r from-orange-400 to-orange-900" onClick={updateWifi}>
            CẬP NHẬT WIFI
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Wifi;
