import { ClockIcon, UserIcon, HeartIcon as AB } from '@heroicons/react/24/outline';
import images from '../assets/images';
import { API_URL } from '../utils/urls';
import { convertNumberToWords, decode, encode, getCookie } from '../utils/helper';
import { Button, Dialog, DialogHeader, DialogBody, Input } from '@material-tailwind/react';
import { useEffect, useRef, useState } from 'react';
import { ArrowDownTrayIcon, BellAlertIcon, HeartIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { checkPhoneChuDat, countThongKeViewSo } from '../services/apiBds';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import DialogBds from './DialogBds';
import { historysUser } from '../utils/history';
import { addYeuthich, removeYeuthich } from '../services/apiYeuthich';

const FrameShowBds = ({ data = {}, yeuthich = [] }) => {
  const imageAvt =
    data?.anhto?.split(',')?.length > 0 ? `${API_URL}/imgshop?name=${data?.anhto?.split(',')[0]}` : images.banner_login;
  const auth = getCookie('token');
  const [open, setOpen] = useState(false);
  const [isSubModal, setIsSubModal] = useState(0);
  const [pass, setPass] = useState('');
  const [phoneChudat, setPhoneChudat] = useState([]);
  const [isPhoneChudat, setIsPhoneChudat] = useState('');
  const [isYeuThich, setIsYeuThich] = useState(yeuthich);
  const [isCopy, setIsCopy] = useState(0);
  const [giaCmt, setGiaCmt] = useState(0);
  const [discountCmt, setDiscountCmt] = useState('');
  const gia = data?.bl?.length > 0 ? data?.bl?.filter((record) => parseInt(record?.gia) > 0) : [];
  const sdtchudat = data?.bl?.length > 0 ? data?.bl?.filter((record) => parseInt(record?.sdtchudat) > 0) : [];
  const sdtchudatValues = sdtchudat?.length > 0 ? sdtchudat.map((item) => item.sdtchudat) : [];
  const divRef = useRef(null);
  const handleOpen = () => {
    setOpen(!open);
    setPhoneChudat([]);
  };
  useEffect(() => {
    if (gia?.length > 0) {
      setGiaCmt(gia[0]['gia']);
      const discountPercentage =
        parseInt(data['gia']) > gia[0]['gia']
          ? 'THẤP hơn ' + Math.round(((data['gia'] - gia[0]['gia']) / data['gia']) * 100) + '%'
          : 'TĂNG hơn ' + Math.round(((gia[0]['gia'] - data['gia']) / data['gia']) * 100) + '%';
      setDiscountCmt(discountPercentage);
    }
  }, []);

  const typeImg = (str) => {
    const lastIndex = str.lastIndexOf('.');
    const result = str.substring(lastIndex + 1);
    if (result === 'png' || result === 'jpg' || result === 'jpeg') {
      return true;
    } else {
      return false;
    }
  };

  const downloadImage = (imageURL, index, key) => {
    saveAs(imageURL, `${index + 1}_${Date.now()}`);
    historysUser({
      id_bds: data?.id,
      truong: key,
      noidung: '1',
    });
  };

  const downloadImagesAsZip = (arrImage, namezip, key) => {
    const imageUrls = arrImage?.split(',').length > 0 ? arrImage?.split(',') : [];
    historysUser({
      id_bds: data?.id,
      truong: key,
      noidung: imageUrls.length,
    });

    const zip = new JSZip();

    const promises = imageUrls.map((imageUrl, index) => {
      return fetch(`${API_URL}/imgshop?name=${imageUrl}`)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(imageUrl, blob);
        });
    });

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, namezip + '.zip');
      });
    });
  };

  const checkInfoChuDat = async () => {
    const body = {
      user: auth.username,
      pass: pass,
      code: data.id,
    };
    const result = await checkPhoneChuDat(body);
    if (result.length > 0) {
      setPass('');
      setPhoneChudat([...result[0]['sdtchudat']?.split(','), ...sdtchudatValues]);
      setIsPhoneChudat('');
      historysUser({
        id_bds: data?.id,
        truong: 'xemsdt',
        noidung: '1',
      });
    } else {
      setPhoneChudat([]);
      setIsPhoneChudat('Nhập mật khẩu sai. Vui lòng nhập lại');
    }
  };

  const checkTotalSo = async () => {
    const checkTotalSo = await countThongKeViewSo({ data: { truong: 'xemso', id_bds: data?.id } });
    const perssion = auth?.permission === 3 ? 30 : auth?.permission === 4 ? 15 : 10000000000;
    if (checkTotalSo?.totalRows < perssion) {
      handleOpen();
      setIsSubModal(2);
      historysUser({
        id_bds: data?.id,
        truong: 'xemso',
        noidung: '1',
      });
    } else if (checkTotalSo?.isOn) {
      handleOpen();
      setIsSubModal(2);
      historysUser({
        id_bds: data?.id,
        truong: 'xemso',
        noidung: '1',
      });
    } else {
      handleOpen();
      setIsSubModal(5);
    }
  };

  const checkTotalSoChu = async () => {
    const checkTotalSo = await countThongKeViewSo({ data: { truong: 'xemsdt', id_bds: data?.id } });
    const perssion = auth?.permission === 3 ? 20 : auth?.permission === 4 ? 7 : 10000000000;
    if (checkTotalSo?.totalRows < perssion) {
      handleOpen();
      setIsSubModal(3);
    } else if (checkTotalSo?.isOn) {
      handleOpen();
      setIsSubModal(3);
    } else {
      handleOpen();
      setIsSubModal(6);
    }
  };

  const subAddDefault = async () => {
    let arr = { id_user: auth?.id, id_bds: data?.id, status: 1 };
    let arradd = { id_user: auth?.id, id: data?.id, status: 1 };
    const resultAPI = await addYeuthich(arr);
    if (resultAPI.message === 'OK') {
      setIsYeuThich([...isYeuThich, arradd]);
    }
  };

  const alertRemove = async (id) => {
    const body = {
      id: id,
    };
    const resultAPI = await removeYeuthich(body);
    if (resultAPI.message === 'OK') {
      const arr = isYeuThich.filter((row) => {
        return row.id !== id;
      });
      setIsYeuThich([...arr]);
    }
  };

  const handleCopyText = (data) => {
    const textToCopy = `✨🏡✨ ${data.loaibds ? data?.loaibds : ''}${data.loaivitri ? ' - ' + data?.loaivitri : ''}${
      data.duong ? ' - đường ' + data?.duong + ', ' + data?.xa + ', ' + data?.huyen : ''
    }${data.rongduong ? ', đường trước nhà ' + data?.rongduong + 'm' : ''}
    ${
      data?.dientich
        ? '\n👉 Diện tích: ' +
          data?.dientich +
          'm2' +
          (data.dientichdatnn !== '0'
            ? data?.dientichdatnn
              ? ' (' + data?.dientichdatnn + ' m2 đất nông nghiệp) '
              : ''
            : '') +
          (data?.ngang ? ' ,ngang ' + data?.ngang + 'm' : '')
        : ''
    }
    ${
      data.sotang !== '0'
        ? '\n👉 Kết cấu: ' +
          (data?.sotang ? data?.sotang + ' tầng' : '') +
          (data.sophong !== '0'
            ? (data?.sophong ? ' - ' + data?.sophong + ' phòng' : '') + (data.sowc ? ' - ' + data?.sowc + ' WC' : '')
            : '')
        : ''
    }
    ${data?.quyhoach ? '\n👉 Quy hoạch: ' + data?.quyhoach : ''}
    ${data?.phaply ? '\n👉 Pháp lý: ' + data?.phaply : ''}
    ${data?.huongbds ? '\n👉 Hướng: ' + data?.huongbds : ''}
    ${
      data?.dactrung || data?.tiennghi || data?.notekhach
        ? '\n👉 Mô tả: ' +
          (data?.tiennghi ? ' - ' + data?.tiennghi : '') +
          (data?.dactrung ? ' - ' + data?.dactrung : '') +
          (data?.notekhach ? ' - ' + data?.notekhach : '')
        : ''
    } 
    \n ${
      data.status === '2'
        ? 'Đã Bán'
        : `👉 ${'Giá bán: ' + convertNumberToWords(data?.gia ? data?.gia : data?.giathue)}
          `
    }
    ${'\n Mã sản phẩm: #' + encode(data?.id)}`;

    setIsCopy(data?.id);

    navigator.clipboard
      .writeText(
        textToCopy
          .split('\n')
          .filter((line) => line.trim() !== '')
          .join('\n'),
      )
      .then(() => {
        setTimeout(() => {
          setIsCopy(0);
        }, 1000);
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
      });
  };

  const checkPhoneNumber = (text) => {
    const phoneNumberRegex = /\b\d{9,10}\b/g;
    const numbers = text.match(phoneNumberRegex);

    if (numbers) {
      const numberWithZero = numbers.map((number) => {
        if (number.length === 9) {
          return '0' + number;
        } else {
          return number;
        }
      });

      return numberWithZero.join(', ');
    } else {
      return 'No phone numbers found.';
    }
  };

  return (
    <>
      <Dialog open={open} handler={handleOpen} size="lg">
        <div className="flex justify-end -mt-[15px] -mr-[15px]">
          {' '}
          <Button onClick={handleOpen} variant="filled" color="orange" size="sm" className="rounded-xl">
            <XMarkIcon className="h-5 w-5" />
          </Button>
        </div>
        <DialogHeader className="font-[UTM-Avo] justify-center p-1">
          {(isSubModal === 1 || isSubModal === 2) && (
            <div className="text-xl text-center">
              MS #{encode(data?.id)} -{' '}
              {`${data.loaibds && data?.loaibds}${data.loaivitri && ' - ' + data?.loaivitri}${
                data.duong && ' - đường ' + data?.duong + ', ' + data?.xa + ', ' + data?.huyen
              }`}
            </div>
          )}
          {isSubModal === 3 && (
            <div className="text-lg">
              <span className={`${data.is_user === 2 ? 'text-gray-500' : ''}`}>
                Người nhập: {data.username} {data.is_user === 2 ? '(Đã nghỉ)' : ''} - {data.address}
              </span>
            </div>
          )}
        </DialogHeader>
        <hr className="my-2 border-blue-gray-50" />
        <DialogBody
          className={
            isSubModal === 3 || isSubModal === 2 || isSubModal === 5 || isSubModal === 6
              ? 'overflow-scroll'
              : 'h-[40rem] overflow-scroll'
          }
        >
          <div className="flex flex-col justify-center">
            {isSubModal === 1 && (
              <div>
                <div className="grid justify-center mb-6">
                  <Link to={`/bds/${encode(data.id)}`}>
                    <div className="text-light-blue-700 hover:underline cursor-pointer">[Bình luận & xem chi tiết]</div>
                  </Link>
                  <div className="flex justify-center">
                    <div>
                      <span
                        className="text-xs text-center text-black bg-orange-400 rounded-md p-2 font-bold mx-1 cursor-pointer hover:bg-green-300"
                        onClick={() => setIsSubModal(2)}
                      >
                        Sổ
                      </span>
                      <span
                        className="bg-orange-400 text-black rounded-md p-2 whitespace-nowrap text-xs font-bold mx-1 cursor-pointer hover:bg-green-300"
                        onClick={() => setIsSubModal(3)}
                      >
                        Xem SĐT
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="my-2 border-blue-gray-50" />
                <PhotoProvider className="!z-[9999]">
                  <div className="grid grid-cols-3 lg:grid-cols-5 gap-2">
                    {data.anhto &&
                      data.anhto?.split(',').length > 0 &&
                      data.anhto?.split(',').map((imageSrc, i) => (
                        <figure className="relative w-full" key={i}>
                          <PhotoView src={`${API_URL}/imgshop?name=${imageSrc}`}>
                            <LazyLoadImage
                              alt={'không có ảnh'}
                              width={'100%'}
                              effect="blur"
                              src={`${API_URL}/imgshop?name=${imageSrc}`}
                              className=" border hover:border-amber-500 hover:border-dashed p-1 rounded-2xl cursor-pointer"
                              // onClick={() => setFixModal(false)}
                            />
                          </PhotoView>

                          <figcaption className="absolute top-[5px] left-[29px] flex px-1 -translate-x-2/4 justify-center border border-white bg-white text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm rounded-lg">
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={() => downloadImage(`${API_URL}/imgshop?name=${imageSrc}`, i, 'taihinh')}
                            >
                              <ArrowDownTrayIcon className="w-5 h-5 text-orange-600" />
                              <span className="text-orange-600 text-xs ml-1"> #{i + 1}</span>
                            </div>
                          </figcaption>
                        </figure>
                      ))}
                  </div>
                </PhotoProvider>
                {data.anhto && (
                  <div className="flex justify-center">
                    <div
                      className="flex items-center cursor-pointer p-2 bg-orange-500 text-gray-900 font-bold rounded-xl hover:bg-green-300"
                      onClick={() => downloadImagesAsZip(data.anhto, 'anhbds', 'taihinh')}
                    >
                      <ArrowDownTrayIcon className="w-5 h-5" />
                      <span className="ml-1">Tải về tất cả</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {isSubModal === 2 && (
              <div>
                <div className="grid justify-center mb-5">
                  {data.soto &&
                    data.soto?.split(',').length > 0 &&
                    data.soto?.split(',').map((row, i) => (
                      <div key={i} className="text-black">
                        {`Sổ#${i + 1}: `} <span className="font-bold">Tờ:</span> {row} |{' '}
                        <span className="font-bold">Thửa:</span>{' '}
                        {data.sothua?.split(',').length > 0 ? data.sothua?.split(',')[i] : ''} |{' '}
                        <span>
                          {data.xa},{data.huyen}
                        </span>
                      </div>
                    ))}
                </div>
                <PhotoProvider className="!z-[9999]">
                  <div className="grid grid-cols-3 lg:grid-cols-5 gap-2">
                    {data.anhthua &&
                      data.anhthua?.split(',').length > 0 &&
                      data.anhthua?.split(',').map((imageSrc, i) => (
                        <figure className="relative w-full" key={i}>
                          {typeImg(imageSrc) ? (
                            <PhotoView src={`${API_URL}/imgshop?name=${imageSrc}`}>
                              <LazyLoadImage
                                alt={'không có ảnh'}
                                width={'100%'}
                                effect="blur"
                                src={`${API_URL}/imgshop?name=${imageSrc}`}
                                className=" border hover:border-amber-500 hover:border-dashed p-1 rounded-2xl cursor-pointer"
                              />
                            </PhotoView>
                          ) : (
                            <div className="w-full h-full border rounded-xl flex justify-center items-center">
                              <a
                                href={`${API_URL}/imgshop?name=${imageSrc}`}
                                download={imageSrc}
                                className=" border hover:border-amber-500 hover:border-dashed p-1 rounded-xl"
                              >
                                {'file_' + (i + 1)}
                              </a>
                            </div>
                          )}
                          <figcaption className="absolute top-[5px] left-[29px] flex px-1 -translate-x-2/4 justify-center border border-white bg-white text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm rounded-lg">
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={() => downloadImage(`${API_URL}/imgshop?name=${imageSrc}`, i, 'taiso')}
                            >
                              <ArrowDownTrayIcon className="w-5 h-5 text-orange-600" />
                              <span className="text-orange-600 text-xs ml-1"> #{i + 1}</span>
                            </div>
                          </figcaption>
                        </figure>
                      ))}
                  </div>
                </PhotoProvider>
                {data.anhthua && (
                  <div className="flex justify-center mt-3">
                    <div
                      className="flex items-center cursor-pointer p-2 bg-orange-500 text-gray-900 font-bold rounded-xl hover:bg-green-300"
                      onClick={() => downloadImagesAsZip(data.anhthua, 'sobds', 'taiso')}
                    >
                      <ArrowDownTrayIcon className="w-5 h-5" />
                      <span className="ml-1">Tải về tất cả</span>
                    </div>
                  </div>
                )}
                <hr className="my-2 border-blue-gray-50" />
                <div className="grid justify-center mb-6">
                  <Link to={`/bds/${encode(data.id)}`}>
                    <div className="text-light-blue-700 hover:underline cursor-pointer">[Bình luận & xem chi tiết]</div>
                  </Link>
                  <div className="flex justify-center">
                    <div>
                      <span
                        className="text-xs text-center text-black bg-orange-400 rounded-md p-2 font-bold mx-1 cursor-pointer hover:bg-green-300"
                        onClick={() => setIsSubModal(1)}
                      >
                        Hình
                      </span>
                      <span
                        className="bg-orange-400 text-black rounded-md p-2 whitespace-nowrap text-xs font-bold mx-1 cursor-pointer hover:bg-green-300"
                        onClick={() => setIsSubModal(3)}
                      >
                        Xem SĐT
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="my-2 border-blue-gray-50" />
              </div>
            )}

            {isSubModal === 3 && (
              <div>
                {phoneChudat.length === 0 && (
                  <div>
                    <div className="text-xl text-black font-bold mb-5">
                      Nhập mật khẩu để xem thông tin chủ BĐS #{encode(data?.id)}
                    </div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b> Nhập Mật khẩu của tài khoản {auth.username}</b>
                    </label>

                    <Input
                      type="password"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setPass(e.target.value);
                      }}
                    />
                    <Button color="orange" size="sm" className="rounded-full mt-4" onClick={checkInfoChuDat}>
                      Xem thông tin
                    </Button>
                  </div>
                )}
                {phoneChudat.length > 0 && (
                  <div className="grid justify-center">
                    <div className="text-xl text-center text-black mb-3">
                      <span className="font-bold font-[UTM-Avo]">Thông tin chủ BĐS #{encode(data?.id)}</span>
                    </div>

                    {phoneChudat.map((row, i) => (
                      <div key={i} className="text-black flex gap-2 mb-1">
                        {`Sđt #${i + 1}: `} <span className="font-bold">{row}</span>
                        <a href={`https://zalo.me/${checkPhoneNumber(row)}`} target="_blank">
                          <img src={images.zalo} alt="alt" width={'30px'} height={'30px'} />
                        </a>
                      </div>
                    ))}

                    {data?.ishanche === 1 && (
                      <div>
                        <span className="text-red-500 font-bold">
                          Lưu ý: Cần việc quan trọng mới gọi chủ đất - hạn chế gọi nhiều{' '}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                {phoneChudat.length > 0 && (
                  <div>
                    <hr className="my-2 border-blue-gray-50" />

                    <div className="grid justify-center mb-6">
                      <Link to={`/bds/${encode(data.id)}`}>
                        <div className="text-light-blue-700 hover:underline cursor-pointer">
                          [Bình luận & xem chi tiết]
                        </div>
                      </Link>
                      <div className="flex justify-center">
                        <div>
                          <span
                            className="text-xs text-center text-black bg-orange-400 rounded-md p-2 font-bold mx-1 cursor-pointer hover:bg-green-300"
                            onClick={() => setIsSubModal(1)}
                          >
                            Hình
                          </span>
                          <span
                            className="text-xs text-center text-black bg-orange-400 rounded-md p-2 font-bold mx-1 cursor-pointer hover:bg-green-300"
                            onClick={() => setIsSubModal(2)}
                          >
                            Sổ
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr className="my-2 border-blue-gray-50" />
                  </div>
                )}
                <div>{isPhoneChudat ? <b className="text-red-500">{isPhoneChudat}</b> : ''}</div>
              </div>
            )}

            {isSubModal === 4 && (
              <div>
                <div className="grid grid-cols-3 lg:grid-cols-6 gap-2 mt-5 ">
                  {data.anhto?.split(',').length > 0 &&
                    data.anhto?.split(',').map((imageSrc, i) => (
                      <figure className="relative w-full" key={i}>
                        <img
                          src={`${API_URL}/imgshop?name=${imageSrc}`}
                          alt="not fount"
                          width={'100%'}
                          className=" border hover:border-amber-500 hover:border-dashed p-1 rounded-2xl"
                          loading="lazy"
                        />
                        <figcaption className="absolute top-[5px] left-[29px] flex px-1 -translate-x-2/4 justify-center border border-white bg-white text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm rounded-lg">
                          <div
                            className="flex items-center cursor-pointer"
                            onClick={() => downloadImage(`${API_URL}/imgshop?name=${imageSrc}`, i)}
                          >
                            <ArrowDownTrayIcon className="w-5 h-5 text-orange-600" />
                            <span className="text-orange-600 text-xs ml-1"> #{i + 1}</span>
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                </div>
                {data.anhto?.split(',').length > 0 && (
                  <div className="flex justify-center">
                    <div
                      className="flex items-center cursor-pointer p-2 bg-orange-500 text-gray-900 font-bold rounded-xl hover:bg-green-300"
                      onClick={() => downloadImagesAsZip(data.anhto, 'anhbds')}
                    >
                      <ArrowDownTrayIcon className="w-5 h-5" />
                      <span className="ml-1">Tải về tất cả</span>
                    </div>
                  </div>
                )}

                <div className="mt-4 leading-7 text-black">
                  <p className="font-bold">{data?.tieude}</p>
                  <p className=" font-sans">
                    {(data.sotang !== '0' ? (data?.sotang ? '👉 ' + data?.sotang + ' tầng' : '') : '') +
                      (data.sotang !== '0'
                        ? (data?.ngang ? ' - ngang ' + data?.ngang + 'm' : '') +
                          (data?.nganghau ? `(ngang hậu ${data?.nganghau}m)` : '')
                        : data?.ngang
                        ? (data?.ngang ? '👉 ngang' + data?.ngang + 'm' : '') +
                          (data?.nganghau ? `(ngang hậu ${data?.nganghau}m)` : '')
                        : '') +
                      (data.sophong !== '0' ? (data?.sophong ? '👉 ' + data?.sophong + ' phòng' : '') : '') +
                      (data.sowc ? ' - ' + data?.sowc + ' WC' : '') +
                      (data.dientich && data.dai
                        ? ` - ${data.ngang ? data.ngang + 'm x' : ''} ${data.dai ? data.dai + 'm' : ''} - ${
                            data.dientich
                          }m2`
                        : `  ${data.dientich ? (data.dientich ? ' - DT ' + data.dientich + 'm2' : '') : ''}`) +
                      (data.dientichdatnn !== '0'
                        ? data?.dientichdatnn
                          ? ' - ' + data?.dientichdatnn + ' m2 đất nông nghiệp'
                          : ''
                        : '') +
                      (data.dientichsan ? ' - tổng diện tích sàn ' + data?.dientichsan + 'm2' : '') +
                      (data.huongbds ? ' - hướng ' + data?.huongbds : '') +
                      (data.tiennghi ? ' - ' + data?.tiennghi : '') +
                      (data.dactrung ? ' - ' + data?.dactrung : '') +
                      (data.quyhoach ? ' - Quy hoạch ' + data?.quyhoach : '')}
                  </p>
                  <div
                    className=" font-sans"
                    dangerouslySetInnerHTML={{
                      __html: data?.notekhach ? data?.notekhach.replace(/\n/g, '<br />') : '',
                    }}
                  />

                  <div className="mt-5">
                    {data.status === '2' ? (
                      <p className="font-bold text-red-700 font-sans ml-1">Đã Bán</p>
                    ) : (
                      <p className="font-bold text-green-700 font-sans ml-1">
                        {giaCmt > 0
                          ? convertNumberToWords(giaCmt)
                          : convertNumberToWords(data?.gia ? data?.gia : data?.giathue)}{' '}
                        {giaCmt > 0
                          ? data?.dientich
                            ? `- ${convertNumberToWords(parseInt(giaCmt) / parseInt(data?.dientich))}/m2`
                            : ''
                          : data?.gia && data?.dientich
                          ? `- ${convertNumberToWords(parseInt(data?.gia) / parseInt(data?.dientich))}/m2`
                          : ''}
                        <span className="font-light text-gray-900">
                          {giaCmt > 0 && data?.gia
                            ? parseInt(data?.gia) > parseInt(giaCmt)
                              ? ` (đã giảm ${convertNumberToWords(parseInt(data?.gia) - parseInt(giaCmt))})`
                              : ` (đã tăng ${convertNumberToWords(parseInt(giaCmt) - parseInt(data?.gia))})`
                            : ''}{' '}
                        </span>
                        <span className="font-thin text-black">- MS: #{encode(data?.id)} </span>
                      </p>
                    )}
                    <p className="font-bold text-green-700">| {discountCmt}</p>
                    <Link
                      to={`/bang-gia-m2?lbs=${data?.loaibds}&lvt=${data?.loaivitri}&d=${data?.duong}&h=${data?.huyen}&gia=${data?.gia}&dt=${data?.dientich}`}
                      target="_blank"
                    >
                      <p className="text-blue-800 hover:underline hover:text-blue-500 cursor-pointer font-sans">
                        {data?.tlq?.totalRow > 0
                          ? `| ${convertNumberToWords(parseInt(data?.tlq?.price) / parseInt(data?.tlq?.dt))}/m2 (${
                              data?.tlq?.totalRow
                            } bđs)`
                          : ''}
                      </p>
                    </Link>
                  </div>
                </div>
                {data?.bl?.length > 0 && (
                  <div>
                    <hr className="my-2 border-blue-gray-50" />

                    {data?.bl?.map((row, i) => (
                      <p key={i} className={`flex items-center mb-2 pt-1 ${i !== 0 ? 'border-t' : ''}`}>
                        <span className="flex items-center text-sm text-gray-900">
                          <UserIcon className="w-4 h-4 mr-1" /> {row.username}{' '}
                          <ClockIcon className="w-4 h-4 ml-1 mr-1" /> {row?.update_date?.split('T')[1]} -{' '}
                          {row?.update_date?.split('T')[0]}:
                        </span>
                        <span className="ml-4 text-sm  text-gray-900">
                          {row?.gia > 0
                            ? parseInt(data?.gia) > parseInt(row?.gia)
                              ? ` đã GIẢM ${convertNumberToWords(parseInt(data?.gia))} còn ${convertNumberToWords(
                                  row?.gia,
                                )}`
                              : ` đã TĂNG ${convertNumberToWords(parseInt(data?.gia))} lên ${convertNumberToWords(
                                  row?.gia,
                                )}`
                            : ''}
                          {row?.noidung ? ' - ' + row?.noidung : ''}
                          {row?.sdtchudat ? ' - đã thêm số chủ đất' : ''}
                          {row?.anh ? ' - đã thêm ảnh' : ''}
                          {row?.anhso ? ' - đã thêm ảnh sổ' : ''}
                          {row?.nhaclich
                            ? ' - đã nhắc nhở ' + new Date(row?.nhaclich).toLocaleDateString() + ' hết hạn bán'
                            : ''}
                        </span>
                      </p>
                    ))}
                  </div>
                )}
                <hr className="my-2 border-blue-gray-50" />
                <div>
                  {data?.notenoibo && <span className="text-black">👉 Ghi chú (nội bộ): {data?.notenoibo}</span>}
                </div>
                <div className="flex mt-2">
                  <DialogBds data={data} />
                </div>
                <hr className="my-2 border-blue-gray-50" />
                <div className="mt-10 text-center">
                  <b>
                    👉 Nhập bởi:{' '}
                    <b className={`${data.is_user === 2 ? 'text-gray-500' : ''}`}>
                      {data.is_user === 2 ? 'ĐÂ NGHỈ' : ''} {data.name} - {data.username} - {data.address}
                    </b>
                  </b>
                </div>
              </div>
            )}

            {isSubModal === 5 && (
              <div className="grid justify-items-center  items-center">
                <BellAlertIcon className="h-20 w-20 text-red-400" />
                <span className="font-bold text-red-400">
                  BẠN ĐÃ HẾT SỐ LƯỢNG XEM SỔ MỚI TRONG NGÀY{' '}
                  {auth?.permission === 3 ? '30/30' : auth?.permission === 4 ? '15/15' : ''}
                </span>
              </div>
            )}

            {isSubModal === 6 && (
              <div className="grid justify-items-center  items-center">
                <BellAlertIcon className="h-20 w-20 text-red-400" />
                <span className="font-bold text-red-400">
                  BẠN ĐÃ HẾT SỐ LƯỢNG XEM SỐ CHỦ MỚI TRONG NGÀY{' '}
                  {auth?.permission === 3 ? '20/20' : auth?.permission === 4 ? '7/7' : ''}
                </span>
              </div>
            )}
          </div>
        </DialogBody>
      </Dialog>
      {Object.keys(data).length > 0 && (
        <div className="grid grid-cols-5 gap-2 rounded-[40px] border-t-2 border-t-amber-500 p-4 items-center hover:bg-[#fffdf4]">
          <div>
            <LazyLoadImage
              alt={'không có ảnh'}
              width={'100%'}
              effect="blur"
              src={imageAvt}
              onError={(e) => {
                e.target.src = images.banner_login;
              }}
              className="rounded-3xl w-96"
            />
          </div>

          <div className="col-span-4 leading-7">
            <Link to={`/bds/${encode(data.id)}`}>
              <h2 className="text-base font-bold mb-1 cursor-pointer">{`${data.loaibds ? data?.loaibds : ''}${
                data.loaivitri ? ' - ' + data?.loaivitri : ''
              }${data.phaply ? ' - ' + data?.phaply : ''}${
                data.duong ? ' - đường ' + data?.duong + ', ' + data?.xa + ', ' + data?.huyen : ''
              }${data.rongduong ? ', đường trước nhà ' + data?.rongduong + 'm' : ''}`}</h2>
            </Link>
            <p className=" font-sans">
              {' '}
              {(data.sotang !== '0' ? (data?.sotang ? ' 👉 ' + data?.sotang + ' tầng' : '') : '') +
                (data.sotang !== '0'
                  ? (data?.ngang ? ' - ngang ' + data?.ngang + 'm' : '') +
                    (data?.nganghau ? `(ngang hậu ${data?.nganghau}m)` : '')
                  : data?.ngang
                  ? (data?.ngang ? ' 👉 ngang' + data?.ngang + 'm' : '') +
                    (data?.nganghau ? `(ngang hậu ${data?.nganghau}m)` : '')
                  : '') +
                (data.sophong !== '0' ? (data?.sophong ? ' - ' + data?.sophong + ' phòng' : '') : '') +
                (data.sowc ? ' - ' + data?.sowc + ' WC' : '') +
                (data.dientich && data.dai
                  ? ` - ${data.ngang ? data.ngang + 'm x' : ''} ${data.dai ? data.dai + 'm' : ''} - ${data.dientich}m2`
                  : `  ${data.dientich ? ' - DT ' + data.dientich + 'm2' : ''}`) +
                (data.dientichdatnn !== '0'
                  ? data?.dientichdatnn
                    ? ' - ' + data?.dientichdatnn + ' m2 đất nông nghiệp'
                    : ''
                  : '') +
                (data.dientichsan ? ' - tổng diện tích sàn ' + data?.dientichsan + 'm2' : '') +
                (data.huongbds ? ' - hướng ' + data?.huongbds : '') +
                (data.tiennghi ? ' - ' + data?.tiennghi : '') +
                (data.dactrung ? ' - ' + data?.dactrung : '') +
                (data.quyhoach ? ' - Quy hoạch ' + data?.quyhoach : '')}
            </p>
            <div
              className=" font-sans"
              dangerouslySetInnerHTML={{
                __html: data?.notekhach ? data?.notekhach.replace(/\n/g, '<br />') : '',
              }}
            />
            <div className="flex items-center my-1 lg:px-5 font-sans">
              <div className="flex items-center mr-2">
                <UserIcon className="w-5 h-5 mr-1" />{' '}
                <label className={`${data?.is_user === 2 ? 'text-gray-400' : ''}`}>{data?.username}</label>{' '}
              </div>
              <div className="flex items-center">
                <ClockIcon className="w-5 h-5 mr-1" />{' '}
                <label>
                  {data?.create_date?.split('T')[1]} - {data?.create_date?.split('T')[0]}
                </label>{' '}
              </div>
            </div>

            <div className="lg:flex lg:items-center">
              {data.status === '2' ? (
                <p className="font-bold text-red-700 font-sans ml-1">Đã Bán</p>
              ) : (
                <p className="font-bold text-green-700 font-sans ml-1">
                  {giaCmt > 0
                    ? convertNumberToWords(giaCmt)
                    : convertNumberToWords(data?.gia ? data?.gia : data?.giathue)}{' '}
                  {giaCmt > 0
                    ? data?.dientich
                      ? `- ${convertNumberToWords(parseInt(giaCmt) / parseInt(data?.dientich))}/m2`
                      : ''
                    : data?.gia && data?.dientich
                    ? `- ${convertNumberToWords(parseInt(data?.gia) / parseInt(data?.dientich))}/m2`
                    : ''}
                  <span className="font-light text-gray-900">
                    {giaCmt > 0 && data?.gia
                      ? parseInt(data?.gia) > parseInt(giaCmt)
                        ? ` (đã giảm ${convertNumberToWords(parseInt(data?.gia) - parseInt(giaCmt))})`
                        : ` (đã tăng ${convertNumberToWords(parseInt(giaCmt) - parseInt(data?.gia))})`
                      : ''}{' '}
                  </span>
                </p>
              )}
              {discountCmt && <p className="font-bold text-green-700">| {discountCmt}</p>}
              <Link
                to={`/bang-gia-m2?lbs=${data?.loaibds}&lvt=${data?.loaivitri}&d=${data?.duong}&h=${data?.huyen}&gia=${data?.gia}&dt=${data?.dientich}`}
                target="_blank"
              >
                <p className="text-blue-800 hover:underline hover:text-blue-500 cursor-pointer font-sans">
                  {data?.tlq?.totalRow > 0
                    ? ` - ${convertNumberToWords(parseInt(data?.tlq?.price) / parseInt(data?.tlq?.dt))}/m2 (${
                        data?.tlq?.totalRow
                      } bđs)`
                    : ''}
                </p>
              </Link>
            </div>

            {data?.bl?.length > 0 && (
              <div>
                <hr className="my-2 border-blue-gray-50" />

                {data?.bl?.map((row, i) => (
                  <p key={i} className={`flex items-center mb-2 pt-1 ${i !== 0 ? 'border-t' : ''}`}>
                    <span className="flex items-center text-sm">
                      <UserIcon className="w-4 h-4 mr-1" /> {row.username} <ClockIcon className="w-4 h-4 ml-1 mr-1" />{' '}
                      {row?.update_date?.split('T')[1]} - {row?.update_date?.split('T')[0]}:
                    </span>
                    <span className="ml-4 text-sm">
                      {row?.gia > 0
                        ? parseInt(data?.gia) > parseInt(row?.gia)
                          ? ` đã GIẢM ${convertNumberToWords(parseInt(data?.gia))} còn ${convertNumberToWords(
                              row?.gia,
                            )}`
                          : ` đã TĂNG ${convertNumberToWords(parseInt(data?.gia))} lên ${convertNumberToWords(
                              row?.gia,
                            )}`
                        : ''}
                      {row?.noidung ? ' - ' + row?.noidung : ''}
                      {row?.sdtchudat ? ' - đã thêm số chủ đất' : ''}
                      {row?.anh ? ' - đã thêm ảnh' : ''}
                      {row?.anhso ? ' - đã thêm ảnh sổ' : ''}
                      {row?.nhaclich
                        ? ' - đã nhắc nhở ' + new Date(row?.nhaclich).toLocaleDateString() + ' hết hạn bán'
                        : ''}
                    </span>
                  </p>
                ))}
              </div>
            )}

            <hr className="my-2 border-blue-gray-50" />
            <div className="flex items-end justify-end">
              <div>
                <span className="mx-1">
                  {data?.isdocquyen === 1 ? (
                    <b>Độc quyền</b>
                  ) : data?.isdocquyen === 2 ? (
                    <b className="text-red-500">Hàng hot</b>
                  ) : (
                    ''
                  )}
                  {data?.ishanche === 2 ? <b> - Hạn chế</b> : ''}
                </span>
                <span className="mx-1 flex lg:inline">
                  +{data?.create_date?.split('T')[1]} - {data?.create_date?.split('T')[0]}
                </span>
                <span className="mx-1">#{encode(data?.id)}</span>
                <span
                  className="text-xs text-center bg-orange-400 rounded-md p-1 font-bold mx-1 cursor-pointer hover:bg-green-300"
                  onClick={() => {
                    handleOpen();
                    setIsSubModal(1);
                    historysUser({
                      id_bds: data?.id,
                      truong: 'xemhinh',
                      noidung: '1',
                    });
                  }}
                >
                  Hình
                </span>
                <span
                  className="text-xs text-center bg-orange-400 rounded-md p-1 font-bold mx-1 cursor-pointer hover:bg-green-300"
                  onClick={checkTotalSo}
                >
                  Sổ
                </span>
                <span
                  className={`${
                    isCopy === data?.id ? 'bg-green-400' : 'bg-orange-400'
                  } rounded-md p-1 whitespace-nowrap text-xs font-bold mx-1 cursor-pointer hover:bg-green-300`}
                  onClick={() => {
                    handleCopyText(data);
                  }}
                >
                  {isCopy === data?.id ? 'Đã copy!' : 'Chia sẻ'}
                </span>
                {data?.ishanche === 2 && (auth?.permission === 1 || auth?.permission === 2) ? (
                  <span
                    className="text-xs text-center bg-orange-400 rounded-md p-1 font-bold mx-1 cursor-pointer hover:bg-green-300"
                    onClick={checkTotalSoChu}
                  >
                    SĐT
                  </span> //|| auth?.username === data?.username
                ) : data?.isdocquyen === 1 &&
                  (auth?.permission === 1 || auth?.permission === 2 || auth?.username === data?.username) ? (
                  <span
                    className="text-xs text-center bg-orange-400 rounded-md p-1 font-bold mx-1 cursor-pointer hover:bg-green-300"
                    onClick={checkTotalSoChu}
                  >
                    SĐT
                  </span>
                ) : (data?.ishanche === 0 || data?.ishanche === 1) &&
                  (data?.isdocquyen === 0 || data?.isdocquyen === 2) ? (
                  <span
                    className="text-xs text-center bg-orange-400 rounded-md p-1 font-bold mx-1 cursor-pointer hover:bg-green-300"
                    onClick={checkTotalSoChu}
                  >
                    SĐT
                  </span>
                ) : (
                  ''
                )}
                <span
                  className="bg-orange-400 rounded-md p-1 whitespace-nowrap text-xs font-bold mx-1 cursor-pointer hover:bg-green-300"
                  onClick={() => {
                    handleOpen();
                    setIsSubModal(4);
                    historysUser({
                      id_bds: data?.id,
                      truong: 'xemchitiet',
                      noidung: '1',
                    });
                  }}
                >
                  Bình luận
                </span>
              </div>
              <div className="cursor-pointer">
                {isYeuThich.some((item) => item.id === data.id) ? (
                  <HeartIcon className="w-5 h-5 text-pink-500" onClick={() => alertRemove(data?.id)} />
                ) : (
                  <AB className="w-5 h-5" onClick={subAddDefault} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FrameShowBds;
