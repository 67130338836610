import {
  Button,
  Card,
  Drawer,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react';
import { getCookie, refreshTokenNew, removeCookie } from '../../utils/helper';
import { Link, useNavigate } from 'react-router-dom';
import images from '../../assets/images';
import { Bars3Icon, ChevronDownIcon, CursorArrowRaysIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import axios from 'axios';
const menuItems = [
  { title: 'Danh sách yêu thích', link: '/yeu-thich' },
  { title: 'Danh sách đã xem', link: '/bds-da-xem' },
  { title: 'Thông tin cá nhân', link: '/user' },
];

function MainLayout({ children }) {
  refreshTokenNew();
  const [openDrawers, setOpenDrawers] = useState(false);
  const openDrawer = () => setOpenDrawers(true);
  const closeDrawer = () => setOpenDrawers(false);
  const [openMenu, setOpenMenu] = useState(false);
  const auth = getCookie('token');
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get('https://ipinfo.io/json')
      .then((response) => {
        if (response.data.ip !== auth?.wifi && (auth?.permission === 3 || auth?.permission === 4)) {
          removeCookie('token');
          removeCookie('token_sv');
          removeCookie('exp_sv');
          navigate('/login', { replace: true });
        }
      })
      .catch((error) => {
        console.error('Error fetching IP address:', error);
      });
  }, []);

  const logOut = () => {
    removeCookie('token');
    removeCookie('token_sv');
    removeCookie('exp_sv');
    navigate('/login', { replace: true });
  };

  return (
    <div className="m-auto p-2 lg:p-6 bg-white overflow-x-hidden">
      <div className="container mx-auto hidden lg:block mb-8">
        <div className="flex items-center">
          <Link
            to={`/`}
            onClick={() => {
              window.location.href = '/';
            }}
          >
            <div className="text-3xl font-bold cursor-pointer text-amber-800 mr-10">
              <img src={images.logokland} alt="1 " width={'180px'} />
            </div>
          </Link>
          <Link to={`/`}>
            <span
              className={`text-base font-bold col-span-2 cursor-pointer ${
                window.location.pathname === '/' ? 'text-amber-600' : ''
              } mr-10`}
            >
              DANH MỤC SẢN PHẨM
            </span>
          </Link>
          <Link to={`/co-gi-moi`}>
            <span
              className={`text-base font-bold cursor-pointer mr-10 ${
                window.location.pathname === '/co-gi-moi' ? 'text-amber-600' : ''
              }`}
            >
              CÓ GÌ MỚI?
            </span>
          </Link>
          <Link to={`/bang-gia-m2`}>
            <span
              className={`text-base font-bold cursor-pointer mr-10 ${
                window.location.pathname === '/bang-gia-m2' ? 'text-amber-600' : ''
              }`}
            >
              BẢNG GIÁ M2
            </span>
          </Link>
          {/* <Link to="/user">
            <span className="text-base font-bold col-span-2 cursor-pointer mr-10">TÀI KHOẢN CỦA TÔI</span>
          </Link> */}
          <Menu open={openMenu} handler={setOpenMenu} allowHover>
            <MenuHandler>
              <Button variant="text" className="flex items-center gap-3 text-base capitalize tracking-normal font-bold">
                TÀI KHOẢN CỦA TÔI
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`h-3.5 w-3.5 transition-transform ${openMenu ? 'rotate-180' : ''}`}
                />
              </Button>
            </MenuHandler>
            <MenuList className="hidden overflow-visible lg:grid">
              <ul className="flex w-full flex-col gap-1">
                {menuItems.map(({ title, link }) => (
                  <Link to={link} key={title}>
                    <MenuItem>
                      <span className="mb-1 text-lg text-black font-[UTM-Avo] font-bold">{title}</span>
                    </MenuItem>
                  </Link>
                ))}
                <MenuItem onClick={logOut}>
                  <span className="mb-1 text-lg text-black font-[UTM-Avo] font-bold">Đăng xuất</span>
                </MenuItem>
              </ul>
            </MenuList>
          </Menu>
          <span className="mr-10">
            <Link to="/addbds">
              <Button color="orange">THÊM BĐS</Button>
            </Link>
          </span>
          <span className="mr-10">
            <Link to="/dashboard">
              <Button color="orange">Trang quản lý</Button>
            </Link>
          </span>
        </div>
      </div>

      <div className=" w-full">
        <Card className="lg:hidden grid w-full p-1 mb-2  rounded-xl bg-orange-400">
          <div className="grid grid-cols-3 items-center">
            <div>
              <IconButton variant="text" className="text-lg text-white" onClick={openDrawer}>
                <Bars3Icon className="h-10 w-10" />
              </IconButton>
            </div>
            <Link to={`/`}>
              <div className="flex justify-center items-center">
                <img src={images.logo} width={'60px'} className="sepia-[1]" />{' '}
                <span className="font-bold text-orange-50 text-2xl tracking-[3px]">KEYLAND</span>
              </div>
            </Link>
            <div className="flex justify-end">
              {' '}
              <MagnifyingGlassIcon className="h-10 w-10 text-white text-right" />
            </div>
          </div>
        </Card>
      </div>

      <Drawer open={openDrawers} onClose={closeDrawer} className="p-4 bg-orange-50">
        <Link to={`/`} onClick={closeDrawer}>
          <div className="mb-6 flex items-center justify-between py-3">
            <p className="text-black text-xl">DANH MỤC SẢN PHẨM</p>
          </div>
        </Link>
        <hr className="my-3 border-blue-gray-500" />
        <Link to={`/co-gi-moi`} onClick={closeDrawer}>
          <div className="mb-6 flex items-center justify-between py-3">
            <p className="black-black text-xl">CÓ GÌ MỚI?</p>
          </div>
        </Link>
        <hr className="my-3 border-blue-gray-500" />
        <Link to={`/bang-gia-m2`} onClick={closeDrawer}>
          <div className="mb-6 flex items-center justify-between py-3">
            <p className="black-black text-xl">BẢNG GIÁ M2</p>
          </div>
        </Link>
        <hr className="my-3 border-blue-gray-500" />
        <Link to={`/user`} onClick={closeDrawer}>
          <div className="mb-6 flex items-center justify-between py-3">
            <p className="black-black text-xl">TÀI KHOẢN CỦA TÔI</p>
          </div>
        </Link>
        <hr className="my-3 border-blue-gray-500" />
        <Link to={`/yeu-thich`} onClick={closeDrawer}>
          <div className="mb-6 flex items-center justify-between py-3">
            <p className="black-black text-xl">QUẢN LÝ YÊU THÍCH</p>
          </div>
        </Link>
        <hr className="my-3 border-blue-gray-500" />
        <Link to={`/bds-da-xem`} onClick={closeDrawer}>
          <div className="mb-6 flex items-center justify-between py-3">
            <p className="black-black text-xl">QUẢN LÝ ĐÃ XEM</p>
          </div>
        </Link>
        <hr className="my-3 border-blue-gray-500" />
        <Link to="/addbds">
          <div className="mb-6 flex items-center justify-between py-3">
            <p className="black-black text-xl">THÊM TIN BĐS</p>
          </div>
        </Link>
        <hr className="my-3 border-blue-gray-500" />
        <Link to="/dashboard">
          <div className="mb-6 flex items-center justify-between py-3">
            <p className="black-black text-xl">TRANG QUẢN LÝ</p>
          </div>
        </Link>
        <hr className="my-3 border-blue-gray-500" />
        <div className="mb-6 flex items-center justify-between py-3" onClick={logOut}>
          <p className="black-black text-xl">ĐĂNG XUẤT</p>
        </div>
      </Drawer>
      <div>{children}</div>
    </div>
  );
}

export default MainLayout;
