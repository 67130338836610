import { useEffect, useState } from 'react';
import { Button, Input, Typography } from '@material-tailwind/react';
import images from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import { getCookie, setCookie } from '../../utils/helper';
import Cookies from 'js-cookie';
import { checkLoginUsers } from '../../services/apiUsers';
import axios from 'axios';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

export default function Login() {
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [errorLogin, setErrorLogin] = useState('');
  const [loadingLogin, setLoadingLogin] = useState(true);
  const [isShowPass, setIsShowPass] = useState(false);
  let inFifteenMinutes = new Date(new Date().getTime() + 60 * 60 * 1000);
  const navigate = useNavigate();

  const hostname = window.location.hostname;

  const handleSubmit = async () => {
    setLoadingLogin(true);
    const dataUser = {
      user: user,
      pass: pass,
    };
    const checkLogin = await checkLoginUsers(dataUser);
    if (checkLogin?.totalRow > 0) {
      setLoadingLogin(false);
      Cookies.set('token_sv', checkLogin['token'], { expires: inFifteenMinutes });
      setCookie('exp_sv', inFifteenMinutes, { expires: inFifteenMinutes });
      setCookie('token', checkLogin['data'], { expires: inFifteenMinutes });
      setErrorLogin('');
      setUser('');
      setPass('');
      window.location.href = '/';
      // navigate('/dashboard', { replace: true });
    } else {
      setErrorLogin('Sai tài khoản hoặc mật khẩu đăng nhập');
      setLoadingLogin(false);
    }
  };

  useEffect(() => {
    document.title = 'Đăng Nhập Hệ Thống KEYLAND';
    const token = getCookie('token');
    if (token) {
      window.location.href = '/';
    } else {
      navigate('/login', { replace: true });
    }
    setLoadingLogin(false);
  }, []);

  const handleEnterKey = async (e) => {
    if (e.key === 'Enter') {
      setLoadingLogin(true);
      const dataUser = {
        user: user,
        pass: pass,
      };
      const checkLogin = await checkLoginUsers(dataUser);
      if (checkLogin?.totalRow > 0) {
        setLoadingLogin(false);
        Cookies.set('token_sv', checkLogin['token'], { expires: inFifteenMinutes });
        setCookie('exp_sv', inFifteenMinutes, { expires: inFifteenMinutes });
        setCookie('token', checkLogin['data'], { expires: inFifteenMinutes });
        setErrorLogin('');
        setUser('');
        setPass('');
        window.location.href = '/';
        // navigate('/dashboard', { replace: true });
      } else {
        setErrorLogin('Sai tài khoản hoặc mật khẩu đăng nhập');
        setLoadingLogin(false);
      }
    }
  };
  return (
    <section
      className={`h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center p-5 md:mx-0 md:my-0`}
      // style={{
      //   background: '#fffdf4',
      // }}
      style={{
        backgroundImage: `url(${images.banner_login})`,
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
        backgroundColor: '#fff',
      }}
    >
      <div className="md:w-1/3 max-w-sm bg-[#ffffffed] p-4 rounded-xl">
        <div className="my-5 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
          <p className="mx-4 mb-0 text-center font-semibold text-slate-500">ĐĂNG NHẬP HỆ THỐNG</p>
        </div>
        <label className="mt-1">Tài khoản</label>
        <input
          className="text-sm w-full px-4 py-2 border-2 border-solid border-orange-500 active:border-orange-500  rounded mb-4"
          type="text"
          placeholder="Nhập tài khoản"
          value={user}
          onChange={(e) => setUser(e.target.value)}
          onKeyPress={handleEnterKey}
        />
        <label className="mt-5">Mật khẩu</label>
        <div className="w-full flex justify-end items-center relative text-sm border-2 border-solid border-orange-500 active:border-orange-500 rounded">
          <input
            className="rounded  px-4 py-2 w-full"
            type={isShowPass ? 'text' : 'password'}
            placeholder="Nhập mật khẩu"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            onKeyPress={handleEnterKey}
          />
          {isShowPass ? (
            <EyeIcon
              className="absolute mr-2 w-5 h-5 text-gray-600 cursor-pointer"
              onClick={() => setIsShowPass(false)}
            />
          ) : (
            <EyeSlashIcon
              className="absolute mr-2 w-5 h-5 text-gray-600 cursor-pointer"
              onClick={() => setIsShowPass(true)}
            />
          )}
        </div>
        <div className="text-center md:text-left">
          <Typography variant="small" className="font-medium text-red-700">
            {errorLogin}
          </Typography>
          <Button
            loading={loadingLogin}
            className="w-full mt-3 flex justify-center font-[UTM-Avo]"
            color="orange"
            size="sm"
            onClick={handleSubmit}
          >
            {' '}
            ĐĂNG NHẬP
          </Button>
        </div>
      </div>
    </section>
  );
}
