import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Button } from '@material-tailwind/react';
import { encode } from '../../../utils/helper';

const ExportExcel = ({ data = {}, date = '' }) => {
  const dataArrSo =
    data?.tkso?.total?.length > 0
      ? data?.tkso?.total?.map((row) => {
          return `Mã #${encode(row.id_bds)} | ${row.view} lượt xem`;
        })
      : [];

  const dataArrSDT =
    data?.tksdt?.total?.length > 0
      ? data?.tksdt?.total?.map((row) => {
          return `Mã #${encode(row.id_bds)} | ${row.view} lượt xem`;
        })
      : [];

  const dataArrHinh =
    data?.tkhinh?.total?.length > 0
      ? data?.tkhinh?.total?.map((row) => {
          return `Mã #${encode(row.id_bds)} | ${row.view} lượt tải`;
        })
      : [];

  const maxLength = Math.max(dataArrSo.length, dataArrSDT.length, dataArrHinh.length);
  // Tạo workbook mới

  const header = [
    `Xem Sổ (${data?.tkso?.sum} lượt xem)`,
    `Xem SĐT (${data?.tksdt?.sum} lượt xem)`,
    `Tải Hình (${data?.tkhinh?.sum} lượt tải)`,
  ];
  let arrData = Array.from({ length: maxLength }, (_, index) => ({
    [header[0]]: dataArrSo[index] || '',
    [header[1]]: dataArrSDT[index] || '',
    [header[2]]: dataArrHinh[index] || '',
  }));

  const dataArrSoUser =
    data?.tkso?.arr?.length > 0
      ? data?.tkso?.arr?.map((row) => {
          return `Mã #${encode(row.id_bds)} | User: ${row.username} | ${row.view} lượt xem`;
        })
      : [];

  const dataArrSDTUser =
    data?.tksdt?.arr?.length > 0
      ? data?.tksdt?.arr?.map((row) => {
          return `Mã #${encode(row.id_bds)} | User: ${row.username} | ${row.view} lượt xem`;
        })
      : [];

  const dataArrHinhUser =
    data?.tkhinh?.arr?.length > 0
      ? data?.tkhinh?.arr?.map((row) => {
          return `Mã #${encode(row.id_bds)} | User: ${row.username} | ${row.view} lượt tải`;
        })
      : [];

  const maxLengthUser = Math.max(dataArrSo.length, dataArrSDT.length, dataArrHinh.length);
  // Tạo workbook mới

  const headerUser = [`Xem Sổ `, `Xem SĐT`, `Tải Hình `];
  let arrDataUser = Array.from({ length: maxLengthUser }, (_, index) => ({
    [headerUser[0]]: dataArrSoUser[index] || '',
    [headerUser[1]]: dataArrSDTUser[index] || '',
    [headerUser[2]]: dataArrHinhUser[index] || '',
  }));
  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Tạo worksheet mới cho từng category
    const worksheet = XLSX.utils.json_to_sheet(arrData);
    const wscols = [{ wch: 25 }, { wch: 25 }, { wch: 25 }];

    worksheet['!cols'] = wscols;

    // Thêm worksheet vào workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, `Tổng ${date}`);

    // Tạo worksheet mới cho từng category
    const worksheet1 = XLSX.utils.json_to_sheet(arrDataUser);
    const wscols1 = [{ wch: 40 }, { wch: 40 }, { wch: 40 }];

    worksheet1['!cols'] = wscols1;

    // Thêm worksheet vào workbook
    XLSX.utils.book_append_sheet(workbook, worksheet1, `Theo User ${date}`);

    // Chuyển đổi workbook thành dạng binary tương ứng với file Excel
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Tạo blob từ dữ liệu excel để tải xuống
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Sử dụng hàm saveAs từ thư viện file-saver để tải xuống tệp
    saveAs(blob, `Thong_ke_${date}.xlsx`);
  };
  // Gọi hàm exportToExcel khi bạn muốn tải xuống
  const handleExport = () => {
    exportToExcel();
  };
  return (
    <div>
      <Button color="green" onClick={handleExport}>
        XUẤT EXCEL
      </Button>
    </div>
  );
};
export default ExportExcel;
