import routes from './configRoutes';
import DashboardLayout from '../layouts/DashboardLayout';
import DefaultLayout from '../layouts/DefaultLayout';
import MainLayout from '../layouts/MainLayout';
import { getCookie } from '../utils/helper';

//Page
import Dashboard from '../pages/Admin/Dashboard';
import Login from '../pages/Login';
import User from '../pages/Admin/User';
import ChangePassWord from '../pages/Admin/User/changePassWord';
import Users from '../pages/Admin/User/users';
import DsUsers from '../pages/Admin/User/dsuser';
import Streets from '../pages/Admin/Category/Streets';
import Khuquyhoach from '../pages/Admin/Category/Khuquyhoach';
import Quyhoach from '../pages/Admin/Category/Quyhoach';
import Phaply from '../pages/Admin/Category/Phaply';
import Huongbds from '../pages/Admin/Category/Huongbds';
import Vitri from '../pages/Admin/Category/Vitri';
import Loaibds from '../pages/Admin/Category/Loaibds';
import Tiennghi from '../pages/Admin/Category/Tiennghi';
import Dactrung from '../pages/Admin/Category/Dactrung';
import AddBds from '../pages/Admin/Bds/addBds';
import DsBds from '../pages/Admin/Bds/dsBds';
import DsDuyetbds from '../pages/Admin/Bds/dsduyetbds';
import Home from '../pages/Main/Home';
import BdsDetail from '../pages/Main/Home/BdsDetail';
import Banggia from '../pages/Main/Banggia';
import Yeuthich from '../pages/Main/Yeuthich';
import Cogihot from '../pages/Main/Home/Cogihot';
import Thongke from '../pages/Admin/Thongke';
import Wifi from '../pages/Admin/Wifi';
import BdsDaXem from '../pages/Main/Home/BdsDaxem';

//component pages

const token = getCookie('token');
//PUBLIC
const publicRoutes = [
  { path: routes.home, component: Home, layout: MainLayout },
  { path: routes.dashboardBDSDetail, component: BdsDetail, layout: MainLayout },
  { path: routes.homeDaXem, component: BdsDaXem, layout: MainLayout },
  { path: routes.homeCogihot, component: Cogihot, layout: MainLayout },
  { path: routes.homeListYeuThich, component: Yeuthich, layout: MainLayout },
  { path: routes.homeBangGia, component: Banggia, layout: MainLayout },
  { path: routes.dashboardLogin, component: Login, layout: DefaultLayout },
  { path: routes.checkRouters, component: Dashboard, layout: DashboardLayout },
];

//PRIVATE
const privateRoutes = [
  { path: routes.home, component: Home, layout: MainLayout },
  { path: routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: routes.dashboardThongKe, component: Thongke, layout: DashboardLayout },
  { path: routes.dashboardUser, component: User, layout: DashboardLayout },
  { path: routes.dashboardUsers, component: Users, layout: DashboardLayout },
  { path: routes.dashboardDsUsers, component: DsUsers, layout: DashboardLayout },
  { path: routes.dashboardChangePass, component: ChangePassWord, layout: DashboardLayout },
  { path: routes.dashboardDiadiem, component: Streets, layout: DashboardLayout },
  { path: routes.dashboardKQH, component: Khuquyhoach, layout: DashboardLayout },
  { path: routes.dashboardQH, component: Quyhoach, layout: DashboardLayout },
  { path: routes.dashboardPhaply, component: Phaply, layout: DashboardLayout },
  { path: routes.dashboardHuongbds, component: Huongbds, layout: DashboardLayout },
  { path: routes.dashboardVitri, component: Vitri, layout: DashboardLayout },
  { path: routes.dashboardLoaiBDS, component: Loaibds, layout: DashboardLayout },
  { path: routes.dashboardTiennghi, component: Tiennghi, layout: DashboardLayout },
  { path: routes.dashboardDactrung, component: Dactrung, layout: DashboardLayout },
  { path: routes.dashboardWifi, component: Wifi, layout: DashboardLayout },
  // BĐS
  { path: routes.dashboardAddBDS, component: AddBds, layout: DashboardLayout },
  { path: routes.dashboardDsBDS, component: DsBds, layout: DashboardLayout },
  { path: routes.dashboardDsDuyetBDS, component: DsDuyetbds, layout: DashboardLayout },
];
const privateRoutes2 = [
  { path: routes.home, component: Home, layout: MainLayout },
  { path: routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: routes.dashboardUser, component: User, layout: DashboardLayout },
  { path: routes.dashboardChangePass, component: ChangePassWord, layout: DashboardLayout },
  // BĐS
  { path: routes.dashboardAddBDS, component: AddBds, layout: DashboardLayout },
  { path: routes.dashboardDsBDS, component: DsBds, layout: DashboardLayout },
  { path: routes.dashboardDsDuyetBDS, component: DsDuyetbds, layout: DashboardLayout },
];

const privateRoutes3 = [
  { path: routes.home, component: Home, layout: MainLayout },
  { path: routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: routes.dashboardUser, component: User, layout: DashboardLayout },
  { path: routes.dashboardChangePass, component: ChangePassWord, layout: DashboardLayout },
  // BĐS
  { path: routes.dashboardAddBDS, component: AddBds, layout: DashboardLayout },
  { path: routes.dashboardDsBDS, component: DsBds, layout: DashboardLayout },
];
const privateRoutes4 = [
  { path: routes.home, component: Home, layout: MainLayout },
  { path: routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: routes.dashboardUser, component: User, layout: DashboardLayout },
  { path: routes.dashboardChangePass, component: ChangePassWord, layout: DashboardLayout },
  // BĐS
  { path: routes.dashboardAddBDS, component: AddBds, layout: DashboardLayout },
  { path: routes.dashboardDsBDS, component: DsBds, layout: DashboardLayout },
];

const privateRoutesAll =
  token &&
  (token?.permission === 1
    ? privateRoutes
    : token?.permission === 2
    ? privateRoutes2
    : token?.permission === 3
    ? privateRoutes3
    : privateRoutes4);

export { publicRoutes, privateRoutesAll };
