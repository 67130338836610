import { PhotoIcon } from '@heroicons/react/24/outline';
import { Typography } from '@material-tailwind/react';

function LoadingFrame() {
  return (
    <div className="grid grid-cols-5 gap-2 animate-pulse rounded-[40px] border-t-2 border-t-amber-500 p-4 items-center hover:bg-[#fffdf4]">
      <div className="grid h-full w-full place-items-center rounded-lg bg-gray-200">
        <PhotoIcon className="h-10 w-10 text-gray-500" />
      </div>
      <div className="col-span-4">
        {/* <div className="w-max"> */}
        <Typography as="div" variant="h1" className="mb-4 h-3 w-full rounded-full bg-gray-300">
          &nbsp;
        </Typography>
        <Typography as="div" variant="paragraph" className="mb-2 h-2 w-full rounded-full bg-gray-300">
          &nbsp;
        </Typography>
        <Typography as="div" variant="paragraph" className="mb-2 h-2 w-full rounded-full bg-gray-300">
          &nbsp;
        </Typography>
        <Typography as="div" variant="paragraph" className="mb-2 h-2 w-full rounded-full bg-gray-300">
          &nbsp;
        </Typography>
        <Typography as="div" variant="paragraph" className="mb-2 h-2 w-full rounded-full bg-gray-300">
          &nbsp;
        </Typography>
        <Typography as="div" variant="paragraph" className="mb-2 h-2 w-full rounded-full bg-gray-300">
          &nbsp;
        </Typography>
        <Typography as="div" variant="paragraph" className="mb-2 h-2 w-full rounded-full bg-gray-300">
          &nbsp;
        </Typography>
        {/* </div> */}
      </div>
    </div>
  );
}

export default LoadingFrame;
