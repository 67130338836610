import { ChevronUpDownIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Button, Chip, IconButton, Input, Option, Select } from '@material-tailwind/react';
import FrameShowBds from '../../../components/FrameShowBds';
import {
  countThongKeNewFee,
  countThongKeViewSo,
  countTinLienQuan,
  listProductIDAllCount,
  listProductIDAllDaxemCount,
  listProductIDAllShowCount,
  listProductIDShow,
  listProductIDShowDaxem,
  listProductTodayCount,
} from '../../../services/apiBds';
import { useEffect, useState } from 'react';
import { ArrowLeftIcon, ArrowRightIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import { convertNumberToWords, getCookie, decode, numberFormat, numberFormatDeleteDots } from '../../../utils/helper';
import LoadingFrame from '../../../components/loadingFrame';
import {
  listAllDactrung,
  listAllHuongBds,
  listAllLoaibds,
  listAllPhaply,
  listAllQhs,
  listAllStreets,
  listAllTiennghi,
  listAllVitri,
} from '../../../services/apiCategory';
import images from '../../../assets/images';
import { listAllYeuthich } from '../../../services/apiYeuthich';
import { Link } from 'react-router-dom';

export default function Home() {
  const arrSapxep = [
    { title: 'Sắp xếp - Ngẫu nhiên', value: '' },
    { title: 'Sắp xếp - Mới cập nhật', value: '1' },
    { title: 'Cập nhật: Mới đây', value: '2' },
    { title: 'Cập nhật: Cũ trước', value: '3' },
    { title: 'Tổng giảm giá', value: '4' },
    { title: 'Giá m2 thấp', value: '5' },
    // { title: 'Giá m2 thấp hơn TT-KV-PK', value: '6' },
    { title: 'Thu nhập cao', value: '7' },
    { title: 'Đường trước nhà', value: '8' },
    { title: 'Số Tầng', value: '9' },
    { title: 'Giá: Giảm dần', value: '10' },
    { title: 'Giá: Tăng dần', value: '11' },
    { title: 'Ngày: cũ trước', value: '12' },
    { title: 'Ngày: mới nhất', value: '13' },
    { title: 'Ngang lớn trước', value: '14' },
    { title: 'Ngang nhỏ trước', value: '15' },
    { title: 'Dài - Giảm dần', value: '16' },
    { title: 'Dài - Tăng dần', value: '17' },
    { title: 'Tổng DT Đất', value: '18' },
    // {title: 'DTXD (thổ cư)', value: '19'},
  ];
  const auth = getCookie('token');
  const [resultListBds, setResultListBds] = useState([]);
  const [resultListBdsDaXem, setResultListBdsDaXem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [countBds, setCountBds] = useState(0);
  const [countBdsDaXem, setCountBdsDaXem] = useState(0);
  const [page, setPage] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [objBoLoc, setObjBoLoc] = useState({
    code: '',
    status: '',
    text: '',
    loaibds: '',
    xa: '',
    duong: '',
    huyen: '',
    loaivitri: '',
    dientichstart: 0,
    dientichend: '',
    giastart: 0,
    giaend: '',
    huongbds: '',
    sotangstart: 0,
    sotangend: '',
    sophongstart: 0,
    sophongend: '',
    ngangstart: 0,
    ngangend: '',
    isdangtin: '',
    username: '',
    tiennghi: '',
    dactrung: '',
    phaply: '',
    quyhoach: '',
    ngaydangstart: '',
    ngaydangend: '',
    ngaycapnhatstart: '',
    ngaycapnhatend: '',
    istrangthai: '',
  });
  const [countThongKe, setCountThongKe] = useState({ xemso: '', baiviet: '', chuaduyet: '', sdt: '', view: '' });
  const [arrOptionSelect, setArrOptionSelect] = useState({ streets: [], loaibds: [] });
  const [arrOptionSelectSearch, setArrOptionSelectSearch] = useState({});
  const [open, setOpen] = useState(0);
  const [isYeuThich, setIsYeuThich] = useState([]);

  const arrStatus = [
    { name: 'Còn bán', value: '1' },
    { name: '', value: '2' },
    { name: 'Độc quyền', value: '3' },
    { name: 'Hạn chế gọi', value: '4' },
    { name: 'Không được gọi', value: '5' },
    { name: 'Tạm dừng bán', value: '6' },
  ];

  const listAllYeuthichs = async (pages = 1) => {
    const resultAPI = await listAllYeuthich(pages, '', auth?.id);
    if (resultAPI.length > 0) {
      setIsYeuThich(resultAPI);
    }
  };

  const listBdsAll = async (pages = 1, status = '', code = '', text = '') => {
    setLoading(true);
    const resultBDSAll = await listProductIDShow(pages, status, code, text);
    // console.log(resultBDSAll);
    if (resultBDSAll.length > 0) {
      setResultListBds(resultBDSAll);
      const countBds = await listProductIDAllShowCount(status, code, text);
      setCountBds(countBds.totalRows);
      setLoading(false);
    } else {
      setLoading(false);
      setResultListBds([]);
      setCountBds(0);
    }
  };

  const listBdsAllDaXem = async (
    pages = 1,
    username,
    code = '',
    text = '',
    loaibds = '',
    xa = '',
    huyen = '',
    sdt = '',
    soto = '',
    sothua = '',
    loaivitri = '',
    dientich = '',
    giaban = '',
    huongbds = '',
    sotang = '',
    sophong = '',
    ngang = '',
    trangthai = '',
    loaidangtin = '',
    tiennghi = '',
    dactrung = '',
    phaply = '',
    quyhoach = '',
    ngaydang = '',
    capnhat = '',
    duong = '',
  ) => {
    const resultBDSAll = await listProductIDShowDaxem(
      pages,
      '',
      username,
      decode(code),
      checkStartingText(text) ? decode(text) : text,
      loaibds,
      xa,
      huyen,
      sdt,
      soto,
      sothua,
      loaivitri,
      dientich,
      giaban,
      huongbds,
      sotang,
      sophong,
      ngang,
      trangthai,
      loaidangtin,
      tiennghi,
      dactrung,
      phaply,
      quyhoach,
      ngaydang,
      capnhat,
      duong,
    );
    // console.log(resultBDSAll);
    if (resultBDSAll.length > 0) {
      setResultListBdsDaXem(resultBDSAll);

      const countBds = await listProductIDAllDaxemCount(
        '',
        username,
        decode(code),
        checkStartingText(text) ? decode(text) : text,
        loaibds,
        xa,
        huyen,
        sdt,
        soto,
        sothua,
        loaivitri,
        dientich,
        giaban,
        huongbds,
        sotang,
        sophong,
        ngang,
        trangthai,
        loaidangtin,
        tiennghi,
        dactrung,
        phaply,
        quyhoach,
        ngaydang,
        capnhat,
        duong,
      );
      setCountBdsDaXem(countBds.totalRows);
    } else {
      setResultListBdsDaXem([]);
    }
  };

  const listBdsAllSearch = async (
    pages = 1,
    status = '1',
    code = '',
    text = '',
    loaibds = '',
    xa = '',
    huyen = '',
    sdt = '',
    soto = '',
    sothua = '',
    loaivitri = '',
    dientich = '',
    giaban = '',
    huongbds = '',
    sotang = '',
    sophong = '',
    ngang = '',
    trangthai = '',
    username = '',
    loaidangtin = '',
    tiennghi = '',
    dactrung = '',
    phaply = '',
    quyhoach = '',
    ngaydang = '',
    capnhat = '',
    duong = '',
  ) => {
    const resultBDSAll = await listProductIDShow(
      pages,
      status,
      decode(code),
      checkStartingText(text) ? decode(text) : text,
      loaibds,
      xa,
      huyen,
      sdt,
      soto,
      sothua,
      loaivitri,
      dientich,
      giaban,
      huongbds,
      sotang,
      sophong,
      ngang,
      trangthai,
      username,
      loaidangtin,
      tiennghi,
      dactrung,
      phaply,
      quyhoach,
      ngaydang,
      capnhat,
      duong,
    );
    setLoading(true);
    if (resultBDSAll.length > 0) {
      setResultListBds(resultBDSAll);
      setLoading(false);
      setOpenFilter(false);
      const countBds = await listProductIDAllShowCount(
        status,
        decode(code),
        checkStartingText(text) ? decode(text) : text,
        loaibds,
        xa,
        huyen,
        sdt,
        soto,
        sothua,
        loaivitri,
        dientich,
        giaban,
        huongbds,
        sotang,
        sophong,
        ngang,
        trangthai,
        username,
        loaidangtin,
        tiennghi,
        dactrung,
        phaply,
        quyhoach,
        ngaydang,
        capnhat,
        duong,
      );

      setCountBds(countBds.totalRows);
    } else {
      setResultListBds([]);
      setLoading(false);
      setCountBds(0);
    }
  };

  const getItemProps = (index) => ({
    variant: page === index ? 'filled' : 'text',
    color: 'gray',
    onClick: () => {
      setPage(index);
      listBdsAllSearch(
        index,
        objBoLoc.status,
        objBoLoc.code,
        objBoLoc.text,
        objBoLoc.loaibds,
        objBoLoc.xa,
        objBoLoc.huyen,
        objBoLoc.sdtchudat,
        objBoLoc.soto,
        objBoLoc.sothua,
        objBoLoc.loaivitri,
        `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
        `${objBoLoc.giastart},${objBoLoc.giaend}`,
        objBoLoc.huongbds,
        `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
        `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
        `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
        objBoLoc.istrangthai,
        objBoLoc.username,
        objBoLoc.isdangtin,
        objBoLoc.tiennghi,
        objBoLoc.dactrung,
        objBoLoc.phaply,
        objBoLoc.quyhoach,
        `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
        `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
        objBoLoc.duong,
      );
      scrollToTop();
    },
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const next = () => {
    if (page === countBds) return;

    const total = Math.ceil(countBds / 50);
    if (total > page) {
      setPage(page + 1);
      listBdsAllSearch(
        page + 1,
        objBoLoc.status,
        objBoLoc.code,
        objBoLoc.text,
        objBoLoc.loaibds,
        objBoLoc.xa,
        objBoLoc.huyen,
        objBoLoc.sdtchudat,
        objBoLoc.soto,
        objBoLoc.sothua,
        objBoLoc.loaivitri,
        `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
        `${objBoLoc.giastart},${objBoLoc.giaend}`,
        objBoLoc.huongbds,
        `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
        `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
        `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
        objBoLoc.istrangthai,
        objBoLoc.username,
        objBoLoc.isdangtin,
        objBoLoc.tiennghi,
        objBoLoc.dactrung,
        objBoLoc.phaply,
        objBoLoc.quyhoach,
        `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
        `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
        objBoLoc.duong,
      );
      scrollToTop();
    }
  };

  const prev = () => {
    if (page === countBds) return;

    const total = Math.ceil(countBds / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listBdsAllSearch(
        page - 1,
        objBoLoc.status,
        objBoLoc.code,
        objBoLoc.text,
        objBoLoc.loaibds,
        objBoLoc.xa,
        objBoLoc.huyen,
        objBoLoc.sdtchudat,
        objBoLoc.soto,
        objBoLoc.sothua,
        objBoLoc.loaivitri,
        `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
        `${objBoLoc.giastart},${objBoLoc.giaend}`,
        objBoLoc.huongbds,
        `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
        `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
        `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
        objBoLoc.istrangthai,
        objBoLoc.username,
        objBoLoc.isdangtin,
        objBoLoc.tiennghi,
        objBoLoc.dactrung,
        objBoLoc.phaply,
        objBoLoc.quyhoach,
        `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
        `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
        objBoLoc.duong,
      );
      scrollToTop();
    }
  };

  // const getStreets = async () => {
  //   const resultAPIXA = await listAllStreets(1, 1000);
  //   if (resultAPIXA.length > 0) {
  //     setArrOptionSelect({ ...arrOptionSelect, streets: resultAPIXA });
  //   }
  // };

  const getLoaiBDS = async () => {
    const resultAPI = await listAllLoaibds(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, loaibds: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, loaibds: resultAPI });
      const resultAPIXA = await listAllStreets(1, 1000);
      if (resultAPIXA.length > 0) {
        setArrOptionSelect({ loaibds: resultAPI, streets: resultAPIXA });
      }
    }
  };
  const thongkeNgayMoi = async () => {
    const thongke = await countThongKeNewFee();
    setCountThongKe({
      xemso: thongke.so,
      sdt: thongke.dt,
      view: thongke.view,
      baiviet: thongke.today,
      chuaduyet: thongke.isbv,
      binhluan: thongke.cmt,
    });
  };

  useEffect(() => {
    listBdsAll(1);
    listBdsAllDaXem(1, auth.username);
    // document.title = '';
    thongkeNgayMoi();
    getLoaiBDS();
    listAllYeuthichs();
  }, []);

  function checkStartingText(text) {
    const regex = /^#/;
    return regex.test(text);
  }

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      listBdsAllSearch(
        1,
        objBoLoc.status,
        objBoLoc.code,
        objBoLoc.text,
        objBoLoc.loaibds,
        objBoLoc.xa,
        objBoLoc.huyen,
        objBoLoc.sdtchudat,
        objBoLoc.soto,
        objBoLoc.sothua,
        objBoLoc.loaivitri,
        `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
        `${objBoLoc.giastart},${objBoLoc.giaend}`,
        objBoLoc.huongbds,
        `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
        `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
        `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
        objBoLoc.istrangthai,
        objBoLoc.username,
        objBoLoc.isdangtin,
        objBoLoc.tiennghi,
        objBoLoc.dactrung,
        objBoLoc.phaply,
        objBoLoc.quyhoach,
        `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
        `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
        objBoLoc.duong,
      );
      listBdsAllDaXem(
        1,
        auth.username,
        objBoLoc.code,
        objBoLoc.text,
        objBoLoc.loaibds,
        objBoLoc.xa,
        objBoLoc.huyen,
        objBoLoc.sdtchudat,
        objBoLoc.soto,
        objBoLoc.sothua,
        objBoLoc.loaivitri,
        `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
        `${objBoLoc.giastart},${objBoLoc.giaend}`,
        objBoLoc.huongbds,
        `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
        `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
        `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
        objBoLoc.istrangthai,
        objBoLoc.isdangtin,
        objBoLoc.tiennghi,
        objBoLoc.dactrung,
        objBoLoc.phaply,
        objBoLoc.quyhoach,
        `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
        `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
        objBoLoc.duong,
      );
    }
  };

  const pagesToShow = 5;
  const totalNumbers = Array.from(new Array(Math.ceil(countBds / 50)), (val, index) => index + 1);

  const firstPage = Math.max(1, page - Math.floor(pagesToShow / 2));
  const lastPage = Math.min(Math.ceil(countBds / 50), firstPage + pagesToShow - 1);

  const prevPages = firstPage > 1 ? [1, '...'] : [];
  const nextPages = lastPage < Math.ceil(countBds / 50) ? ['...', Math.ceil(countBds / 50)] : [];

  const pageNumbers = [...prevPages, ...totalNumbers.slice(firstPage - 1, lastPage), ...nextPages];

  const uniqueData = (data, key) => {
    return data.filter((item, index, self) => index === self.findIndex((t) => t[key] === item[key]));
  };

  const handleSearchOptionSelect = (key, val, keysearch) => {
    if (val) {
      const results = arrOptionSelect[key].filter((item) => item[keysearch].toLowerCase().includes(val.toLowerCase()));
      setArrOptionSelectSearch({ ...arrOptionSelect, [key]: results });
    } else {
      setArrOptionSelectSearch({ ...arrOptionSelect, [key]: arrOptionSelect[key] });
    }
  };

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const removeDataBds = (data, key, index) => {
    const updatedData = data.filter((item, i) => i !== index);
    setObjBoLoc({
      ...objBoLoc,
      [key]: updatedData.join(','),
    });
  };

  const checkText = (data1, data2) => {
    const textArray1 = data1?.split(','); // Split data1 into an array
    const textArray2 = data2?.split(','); // Split data2 into an array

    const isMatch = textArray1.some((text) => textArray2.includes(text));

    return isMatch;
  };

  const getQH = async () => {
    const resultAPI = await listAllQhs(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, quyhoach: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, quyhoach: resultAPI });
    }
  };
  const getPhaply = async () => {
    const resultAPI = await listAllPhaply(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, phaply: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, phaply: resultAPI });
    }
  };
  const getHuongBds = async () => {
    const resultAPI = await listAllHuongBds(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, huongbds: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, huongbds: resultAPI });
    }
  };
  const getLoaiVitri = async () => {
    const resultAPI = await listAllVitri(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, loaivitri: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, loaivitri: resultAPI });
    }
  };
  const getTiennghi = async () => {
    const resultAPI = await listAllTiennghi(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, tiennghi: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, tiennghi: resultAPI });
    }
  };
  const getDactrung = async () => {
    const resultAPI = await listAllDactrung(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, dactrung: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, dactrung: resultAPI });
    }
  };

  const handleFilter = () => {
    // console.log(objBoLoc);
    listBdsAllSearch(
      1,
      objBoLoc.status,
      objBoLoc.code,
      objBoLoc.text,
      objBoLoc.loaibds,
      objBoLoc.xa,
      objBoLoc.huyen,
      objBoLoc.sdtchudat,
      objBoLoc.soto,
      objBoLoc.sothua,
      objBoLoc.loaivitri,
      `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
      `${objBoLoc.giastart},${objBoLoc.giaend}`,
      objBoLoc.huongbds,
      `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
      `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
      `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
      objBoLoc.istrangthai,
      objBoLoc.username,
      objBoLoc.isdangtin,
      objBoLoc.tiennghi,
      objBoLoc.dactrung,
      objBoLoc.phaply,
      objBoLoc.quyhoach,
      `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
      `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
      objBoLoc.duong,
    );
    listBdsAllDaXem(
      1,
      auth.username,
      objBoLoc.code,
      objBoLoc.text,
      objBoLoc.loaibds,
      objBoLoc.xa,
      objBoLoc.huyen,
      objBoLoc.sdtchudat,
      objBoLoc.soto,
      objBoLoc.sothua,
      objBoLoc.loaivitri,
      `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
      `${objBoLoc.giastart},${objBoLoc.giaend}`,
      objBoLoc.huongbds,
      `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
      `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
      `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
      objBoLoc.istrangthai,
      objBoLoc.isdangtin,
      objBoLoc.tiennghi,
      objBoLoc.dactrung,
      objBoLoc.phaply,
      objBoLoc.quyhoach,
      `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
      `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
      objBoLoc.duong,
    );
  };

  return (
    <div className="m-auto">
      {loading && (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
          <div className="lg:col-span-3">
            <LoadingFrame />
          </div>
          <div className="hidden lg:block lg:col-span-2">
            <LoadingFrame />
          </div>
        </div>
      )}
      {!loading && (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
          <div className="sm:hidden">
            <div className="mt-1 mb-2">
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Tìm nhanh</b>
              </label>
              <Input
                //   value={arrBDS?.sonha}
                placeholder="Tìm Tên Đường, mã số hoặc từ khóa... "
                className="w-full rounded-xl !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                onChange={(e) => {
                  setObjBoLoc({ ...objBoLoc, text: e.target.value });
                }}
                onKeyPress={handleEnterKey}
              />
            </div>
            <div>
              <div className="grid grid-cols-2 gap-2 ">
                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Loại BĐS</b>
                  </label>
                  <div className="relative mb-2">
                    <select
                      value={objBoLoc.loaibds}
                      onChange={(e) => {
                        listBdsAllSearch(
                          1,
                          objBoLoc.status,
                          objBoLoc.code,
                          objBoLoc.text,
                          e.target.value,
                          objBoLoc.xa,
                          objBoLoc.huyen,
                          objBoLoc.sdtchudat,
                          objBoLoc.soto,
                          objBoLoc.sothua,
                          objBoLoc.loaivitri,
                          `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
                          `${objBoLoc.giastart},${objBoLoc.giaend}`,
                          objBoLoc.huongbds,
                          `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
                          `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
                          `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
                          objBoLoc.istrangthai,
                          objBoLoc.username,
                          objBoLoc.isdangtin,
                          objBoLoc.tiennghi,
                          objBoLoc.dactrung,
                          objBoLoc.phaply,
                          objBoLoc.quyhoach,
                          `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
                          `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
                          objBoLoc.duong,
                        );
                        setObjBoLoc({ ...objBoLoc, loaibds: e.target.value });
                        listBdsAllDaXem(
                          1,
                          auth.username,
                          objBoLoc.code,
                          objBoLoc.text,
                          e.target.value,
                          objBoLoc.xa,
                          objBoLoc.huyen,
                          objBoLoc.sdtchudat,
                          objBoLoc.soto,
                          objBoLoc.sothua,
                          objBoLoc.loaivitri,
                          `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
                          `${objBoLoc.giastart},${objBoLoc.giaend}`,
                          objBoLoc.huongbds,
                          `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
                          `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
                          `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
                          objBoLoc.istrangthai,
                          objBoLoc.isdangtin,
                          objBoLoc.tiennghi,
                          objBoLoc.dactrung,
                          objBoLoc.phaply,
                          objBoLoc.quyhoach,
                          `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
                          `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
                          objBoLoc.duong,
                        );
                      }}
                      className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                    >
                      <option value="">Chọn Loại BĐS</option>
                      {arrOptionSelect?.loaibds.length > 0 &&
                        arrOptionSelect?.loaibds.map((row, i) => (
                          <option key={i} value={row.name}>
                            {row.name}
                          </option>
                        ))}
                    </select>
                    <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                  </div>

                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Phường, xã</b>
                  </label>
                  <div className="relative mb-2">
                    <select
                      value={objBoLoc.xa}
                      onChange={(e) => {
                        listBdsAllSearch(
                          1,
                          objBoLoc.status,
                          objBoLoc.code,
                          objBoLoc.text,
                          objBoLoc.loaibds,
                          e.target.value,
                          objBoLoc.huyen,
                          objBoLoc.sdtchudat,
                          objBoLoc.soto,
                          objBoLoc.sothua,
                          objBoLoc.loaivitri,
                          `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
                          `${objBoLoc.giastart},${objBoLoc.giaend}`,
                          objBoLoc.huongbds,
                          `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
                          `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
                          `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
                          objBoLoc.istrangthai,
                          objBoLoc.username,
                          objBoLoc.isdangtin,
                          objBoLoc.tiennghi,
                          objBoLoc.dactrung,
                          objBoLoc.phaply,
                          objBoLoc.quyhoach,
                          `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
                          `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
                          objBoLoc.duong,
                        );
                        listBdsAllDaXem(
                          1,
                          auth.username,
                          objBoLoc.code,
                          objBoLoc.text,
                          objBoLoc.loaibds,
                          e.target.value,
                          objBoLoc.huyen,
                          objBoLoc.sdtchudat,
                          objBoLoc.soto,
                          objBoLoc.sothua,
                          objBoLoc.loaivitri,
                          `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
                          `${objBoLoc.giastart},${objBoLoc.giaend}`,
                          objBoLoc.huongbds,
                          `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
                          `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
                          `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
                          objBoLoc.istrangthai,
                          objBoLoc.isdangtin,
                          objBoLoc.tiennghi,
                          objBoLoc.dactrung,
                          objBoLoc.phaply,
                          objBoLoc.quyhoach,
                          `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
                          `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
                          objBoLoc.duong,
                        );
                        setObjBoLoc({ ...objBoLoc, xa: e.target.value });
                      }}
                      className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                    >
                      <option value="">Chọn Phường, xã</option>
                      {/* {arrOptionSelect?.streets.length === 0 && (
                        <option>
                          <b>Đang tải ...</b>
                        </option>
                      )} */}
                      {arrOptionSelect?.streets.length > 0 &&
                        uniqueData(arrOptionSelect?.streets, 'xa')?.map((row, index) => (
                          <option key={index} value={row?.xa}>
                            {row?.xa},{row?.huyen}
                          </option>
                        ))}
                    </select>
                    <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                  </div>
                </div>

                <div className="grid justify-center">
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Hôm nay có gì mới</b>
                  </label>
                  {countThongKe?.baiviet > 0 ? <p>+{countThongKe?.baiviet} bđs mới</p> : ''}
                  {countThongKe?.chuaduyet > 0 ? <p>+{countThongKe?.chuaduyet} bđs chưa duyệt</p> : ''}
                  {/* <p>+5 lượt bình luận mới</p> */}
                  {countThongKe?.xemso > 0 ? <p>+{countThongKe?.xemso} lượt xem sổ</p> : ''}
                  {countThongKe?.sdt > 0 ? <p>+{countThongKe?.sdt} lượt xem SĐT</p> : ''}
                  {countThongKe?.view > 0 ? <p>+{countThongKe?.view} lượt xem bài</p> : ''}
                  {countThongKe?.binhluan > 0 ? <p>+{countThongKe?.binhluan} lượt bình luận</p> : ''}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:col-span-3">
            <div className="flex items-center justify-center mb-2 gap-4">
              <label className="font-bold text-lg">{countBds} bđs đang bán</label>
              <div className="lg:max-w-xs lg:w-full">
                <div className="relative">
                  <select
                    onChange={(e) =>
                      listBdsAllSearch(
                        1,
                        e.target.value,
                        decode(objBoLoc.code),
                        objBoLoc.text,
                        objBoLoc.loaibds,
                        objBoLoc.xa,
                        objBoLoc.huyen,
                        objBoLoc.sdtchudat,
                        objBoLoc.soto,
                        objBoLoc.sothua,
                        objBoLoc.loaivitri,
                        `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
                        `${objBoLoc.giastart},${objBoLoc.giaend}`,
                        objBoLoc.huongbds,
                        `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
                        `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
                        `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
                        objBoLoc.istrangthai,
                        objBoLoc.username,
                        objBoLoc.isdangtin,
                        objBoLoc.tiennghi,
                        objBoLoc.dactrung,
                        objBoLoc.phaply,
                        objBoLoc.quyhoach,
                        `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
                        `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
                        objBoLoc.duong,
                      )
                    }
                    className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                  >
                    {arrSapxep.map((row, i) => (
                      <option key={i} value={row.value}>
                        {row.title}
                      </option>
                    ))}
                  </select>
                  <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                </div>
              </div>
            </div>
            {resultListBds.length > 0 &&
              resultListBds?.map((row, i) => (
                <div key={i}>
                  {' '}
                  <FrameShowBds data={row} yeuthich={isYeuThich} />
                </div>
              ))}
            <hr className="my-4 border-blue-gray-50" />
            <div className="flex items-center justify-center">
              <div>
                <Button
                  variant="text"
                  size="sm"
                  className="flex items-center gap-1"
                  onClick={prev}
                  disabled={page === 1}
                >
                  <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Trước
                </Button>
              </div>
              <div className="flex">
                {pageNumbers.map((number, index) =>
                  number !== '...' ? (
                    <IconButton key={index} {...getItemProps(number)} size="sm">
                      {number}
                    </IconButton>
                  ) : (
                    <IconButton key={index} variant="text" size="sm">
                      {number}
                    </IconButton>
                  ),
                )}
              </div>

              <div>
                <Button
                  variant="text"
                  size="sm"
                  className="flex items-center gap-1"
                  onClick={next}
                  disabled={page === Math.ceil(countBds / 50)}
                >
                  Sau
                  <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>

          <div className="lg:col-span-2 gap-4">
            <div className="hidden lg:block mt-1 mb-2">
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Tìm nhanh</b>
              </label>
              <Input
                //   value={arrBDS?.sonha}
                placeholder="Tìm Tên Đường, mã số hoặc từ khóa... "
                className="w-full rounded-xl !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                onChange={(e) => {
                  setObjBoLoc({ ...objBoLoc, text: e.target.value });
                }}
                onKeyPress={handleEnterKey}
              />
            </div>
            <div className="hidden lg:block">
              <div className="grid grid-cols-2 gap-2 ">
                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Loại BĐS</b>
                  </label>
                  <div className="relative mb-2">
                    <select
                      value={objBoLoc.loaibds}
                      onChange={(e) => {
                        listBdsAllSearch(
                          1,
                          objBoLoc.status,
                          objBoLoc.code,
                          objBoLoc.text,
                          e.target.value,
                          objBoLoc.xa,
                          objBoLoc.huyen,
                          objBoLoc.sdtchudat,
                          objBoLoc.soto,
                          objBoLoc.sothua,
                          objBoLoc.loaivitri,
                          `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
                          `${objBoLoc.giastart},${objBoLoc.giaend}`,
                          objBoLoc.huongbds,
                          `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
                          `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
                          `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
                          objBoLoc.istrangthai,
                          objBoLoc.username,
                          objBoLoc.isdangtin,
                          objBoLoc.tiennghi,
                          objBoLoc.dactrung,
                          objBoLoc.phaply,
                          objBoLoc.quyhoach,
                          `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
                          `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
                          objBoLoc.duong,
                        );
                        setObjBoLoc({ ...objBoLoc, loaibds: e.target.value });
                        listBdsAllDaXem(
                          1,
                          auth.username,
                          objBoLoc.code,
                          objBoLoc.text,
                          e.target.value,
                          objBoLoc.xa,
                          objBoLoc.huyen,
                          objBoLoc.sdtchudat,
                          objBoLoc.soto,
                          objBoLoc.sothua,
                          objBoLoc.loaivitri,
                          `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
                          `${objBoLoc.giastart},${objBoLoc.giaend}`,
                          objBoLoc.huongbds,
                          `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
                          `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
                          `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
                          objBoLoc.istrangthai,
                          objBoLoc.isdangtin,
                          objBoLoc.tiennghi,
                          objBoLoc.dactrung,
                          objBoLoc.phaply,
                          objBoLoc.quyhoach,
                          `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
                          `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
                          objBoLoc.duong,
                        );
                      }}
                      className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                    >
                      <option value="">Chọn Loại BĐS</option>
                      {arrOptionSelect?.loaibds.length > 0 &&
                        arrOptionSelect?.loaibds.map((row, i) => (
                          <option key={i} value={row.name}>
                            {row.name}
                          </option>
                        ))}
                    </select>
                    <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                  </div>

                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Phường, xã</b>
                  </label>
                  <div className="relative mb-2">
                    <select
                      value={objBoLoc.xa}
                      onChange={(e) => {
                        listBdsAllSearch(
                          1,
                          objBoLoc.status,
                          objBoLoc.code,
                          objBoLoc.text,
                          objBoLoc.loaibds,
                          e.target.value,
                          objBoLoc.huyen,
                          objBoLoc.sdtchudat,
                          objBoLoc.soto,
                          objBoLoc.sothua,
                          objBoLoc.loaivitri,
                          `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
                          `${objBoLoc.giastart},${objBoLoc.giaend}`,
                          objBoLoc.huongbds,
                          `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
                          `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
                          `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
                          objBoLoc.istrangthai,
                          objBoLoc.username,
                          objBoLoc.isdangtin,
                          objBoLoc.tiennghi,
                          objBoLoc.dactrung,
                          objBoLoc.phaply,
                          objBoLoc.quyhoach,
                          `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
                          `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
                          objBoLoc.duong,
                        );
                        listBdsAllDaXem(
                          1,
                          auth.username,
                          objBoLoc.code,
                          objBoLoc.text,
                          objBoLoc.loaibds,
                          e.target.value,
                          objBoLoc.huyen,
                          objBoLoc.sdtchudat,
                          objBoLoc.soto,
                          objBoLoc.sothua,
                          objBoLoc.loaivitri,
                          `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
                          `${objBoLoc.giastart},${objBoLoc.giaend}`,
                          objBoLoc.huongbds,
                          `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
                          `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
                          `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
                          objBoLoc.istrangthai,
                          objBoLoc.isdangtin,
                          objBoLoc.tiennghi,
                          objBoLoc.dactrung,
                          objBoLoc.phaply,
                          objBoLoc.quyhoach,
                          `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
                          `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
                          objBoLoc.duong,
                        );
                        setObjBoLoc({ ...objBoLoc, xa: e.target.value });
                      }}
                      className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                    >
                      <option value="">Chọn Phường, xã</option>
                      {/* {arrOptionSelect?.streets.length === 0 && (
                        <option>
                          <b>Đang tải ...</b>
                        </option>
                      )} */}
                      {arrOptionSelect?.streets.length > 0 &&
                        uniqueData(arrOptionSelect?.streets, 'xa')?.map((row, index) => (
                          <option key={index} value={row?.xa}>
                            {row?.xa},{row?.huyen}
                          </option>
                        ))}
                    </select>
                    <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                  </div>
                </div>

                <div className="grid justify-center">
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Hôm nay có gì mới</b>
                  </label>
                  {countThongKe?.baiviet > 0 ? <p>+{countThongKe?.baiviet} bđs mới</p> : ''}
                  {countThongKe?.chuaduyet > 0 ? <p>+{countThongKe?.chuaduyet} bđs chưa duyệt</p> : ''}
                  {/* <p>+5 lượt bình luận mới</p> */}
                  {countThongKe?.xemso > 0 ? <p>+{countThongKe?.xemso} lượt xem sổ</p> : ''}
                  {countThongKe?.sdt > 0 ? <p>+{countThongKe?.sdt} lượt xem SĐT</p> : ''}
                  {countThongKe?.view > 0 ? <p>+{countThongKe?.view} lượt xem bài</p> : ''}
                  {countThongKe?.binhluan > 0 ? <p>+{countThongKe?.binhluan} lượt bình luận</p> : ''}
                </div>
              </div>
            </div>

            {resultListBdsDaXem.length > 0 && (
              <div>
                <hr className="lg:hidden my-2 border-blue-gray-50" />
                <div className="text-center text-2xl my-5">
                  <b>Có thể bạn đã quên</b>
                </div>
                {resultListBdsDaXem?.map((row, i) => (
                  <div key={i}>
                    {' '}
                    <FrameShowBds data={row} yeuthich={isYeuThich} />
                  </div>
                ))}
                {countBdsDaXem > 20 && (
                  <div>
                    <hr className="my-4 border-blue-gray-50" />
                    <div className="flex justify-center">
                      <Link to={`/bds-da-xem`}>
                        <Button variant="outlined" color="orange">
                          Xem thêm
                        </Button>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {openFilter && (
        <div
          className="fixed top-0 left-0 w-full h-full z-[999] backdrop-blur-[3px]"
          onClick={() => setOpenFilter(!openFilter)}
        ></div>
      )}
      {openFilter && (
        <div className="w-[95%] lg:w-1/2 fixed bottom-[80px] right-[0px] bg-white border-4 border-orange-500 rounded-xl h-[30rem] overflow-scroll shadow-2xl z-[1000] animate-slideLeft mr-2">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 p-2">
            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Tìm tên đường</b>
              </label>

              <div>
                <Input
                  value={objBoLoc.duong}
                  placeholder="Nhập tên đường"
                  className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  onChange={(e) => {
                    handleSearchOptionSelect('streets', e.target.value, 'duong');
                    setOpen(e.target.value ? 1 : 0);
                    setObjBoLoc({ ...objBoLoc, duong: e.target.value });
                  }}
                />

                {open === 1 && (
                  <div className="border rounded-lg absolute z-[99999] bg-white shadow-2xl mt-1 h-[150px] overflow-scroll">
                    {arrOptionSelectSearch?.streets &&
                      arrOptionSelectSearch?.streets.map((row, index) => (
                        <div
                          className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer"
                          key={index}
                          onClick={() => {
                            setObjBoLoc({ ...objBoLoc, duong: row?.duong });
                            handleOpen(0);
                          }}
                        >
                          {row?.duong},{row?.xa},{row?.huyen}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>SĐT Chủ</b>
              </label>
              <Input
                value={objBoLoc?.sdtchudat}
                placeholder="Nhập đủ 10 chữ số ĐT - vd: 0912345678"
                className="w-full rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                onChange={(e) => {
                  setObjBoLoc({ ...objBoLoc, sdtchudat: e.target.value });
                }}
              />
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Số Tờ</b>
              </label>
              <Input
                value={objBoLoc?.soto}
                placeholder="Nhập số tờ"
                className="w-full rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                onChange={(e) => {
                  setObjBoLoc({ ...objBoLoc, soto: e.target.value });
                }}
              />
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Số Thửa</b>
              </label>
              <Input
                value={objBoLoc?.sothua}
                placeholder="Nhập số thửa"
                className="w-full rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                onChange={(e) => {
                  setObjBoLoc({ ...objBoLoc, sothua: e.target.value });
                }}
              />
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Phường, xã</b>
              </label>
              <div className="relative mb-2">
                <select
                  value={objBoLoc.xa}
                  onChange={(e) => {
                    listBdsAllSearch(
                      1,
                      objBoLoc.status,
                      objBoLoc.code,
                      objBoLoc.text,
                      objBoLoc.loaibds,
                      e.target.value,
                      objBoLoc.huyen,
                      objBoLoc.sdtchudat,
                      objBoLoc.soto,
                      objBoLoc.sothua,
                      objBoLoc.loaivitri,
                      `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
                      `${objBoLoc.giastart},${objBoLoc.giaend}`,
                      objBoLoc.huongbds,
                      `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
                      `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
                      `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
                      objBoLoc.istrangthai,
                      objBoLoc.username,
                      objBoLoc.isdangtin,
                      objBoLoc.tiennghi,
                      objBoLoc.dactrung,
                      objBoLoc.phaply,
                      objBoLoc.quyhoach,
                      `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
                      `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
                      objBoLoc.duong,
                    );
                    listBdsAllDaXem(
                      1,
                      auth.username,
                      objBoLoc.code,
                      objBoLoc.text,
                      objBoLoc.loaibds,
                      e.target.value,
                      objBoLoc.huyen,
                      objBoLoc.sdtchudat,
                      objBoLoc.soto,
                      objBoLoc.sothua,
                      objBoLoc.loaivitri,
                      `${objBoLoc.dientichstart},${objBoLoc.dientichend}`,
                      `${objBoLoc.giastart},${objBoLoc.giaend}`,
                      objBoLoc.huongbds,
                      `${objBoLoc.sotangstart},${objBoLoc.sotangend}`,
                      `${objBoLoc.sophongstart},${objBoLoc.sophongend}`,
                      `${objBoLoc.ngangstart},${objBoLoc.ngangend}`,
                      objBoLoc.istrangthai,
                      objBoLoc.isdangtin,
                      objBoLoc.tiennghi,
                      objBoLoc.dactrung,
                      objBoLoc.phaply,
                      objBoLoc.quyhoach,
                      `${objBoLoc.ngaydangstart},${objBoLoc.ngaydangend}`,
                      `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}`,
                      objBoLoc.duong,
                    );
                    setObjBoLoc({ ...objBoLoc, xa: e.target.value });
                  }}
                  className="w-full border-gray-400 rounded-xl bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                >
                  <option value="">Chọn Phường, xã</option>
                  {arrOptionSelect?.streets.length > 0 &&
                    uniqueData(arrOptionSelect?.streets, 'xa')?.map((row, index) => (
                      <option key={index} value={row?.xa}>
                        {row?.xa},{row?.huyen}
                      </option>
                    ))}
                </select>
                <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
              </div>
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Loại Vị trí</b>
              </label>

              <div className="z-[99999]">
                <div className="relative">
                  <button
                    className="inline-flex w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                    onClick={() => {
                      handleOpen(2);
                      getLoaiVitri();
                    }}
                  >
                    {' '}
                    {objBoLoc.loaivitri ? (
                      <span className="mr-2 grid grid-cols-2 gap-2">
                        {objBoLoc.loaivitri?.split(',')?.map((row, i) => (
                          <Chip
                            value={row}
                            variant="ghost"
                            icon={<XCircleIcon />}
                            key={i}
                            onClick={() => removeDataBds(objBoLoc.loaivitri?.split(','), 'loaivitri', i)}
                          />
                        ))}
                      </span>
                    ) : (
                      <span className="mr-2">Mặt Tiền,Hẻm...</span>
                    )}
                  </button>
                  {open === 2 && (
                    <div
                      className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                    >
                      <Input
                        // value={''}
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => handleSearchOptionSelect('loaivitri', e.target.value, 'name')}
                      />

                      <div className="pl-1">
                        <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                      </div>
                      {arrOptionSelectSearch?.loaivitri &&
                        arrOptionSelectSearch?.loaivitri.map((row, index) => (
                          <div
                            className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                            key={index}
                            onClick={() => {
                              if (!checkText(objBoLoc.loaivitri, row?.name)) {
                                setObjBoLoc({
                                  ...objBoLoc,
                                  loaivitri: `${objBoLoc['loaivitri'] ? objBoLoc['loaivitri'] + ',' : ''}${row?.name}`,
                                });
                                handleOpen(0);
                              }
                            }}
                          >
                            <b className={`${checkText(objBoLoc.loaivitri, row?.name) ? 'text-gray-500' : ''}`}>
                              {row?.name}
                            </b>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="lg:col-span-2">
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Tìm nhanh</b>
              </label>
              <Input
                value={objBoLoc?.text}
                placeholder="Tìm Tên Đường, mã số, từ khóa"
                className="w-full rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                onChange={(e) => {
                  setObjBoLoc({ ...objBoLoc, text: e.target.value });
                }}
                onKeyPress={handleEnterKey}
              />
            </div>

            <div>
              <label className=" text-sm mb-1">
                <b>Tổng DT Đất</b>
              </label>
              <div className="grid grid-cols-2 gap-2">
                <Input
                  value={objBoLoc?.dientichstart}
                  placeholder="từ..."
                  className="rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  type="number"
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, dientichstart: e.target.value });
                  }}
                />
                <Input
                  value={objBoLoc?.dientichend}
                  placeholder="đến..."
                  className=" rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  type="number"
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, dientichend: e.target.value });
                  }}
                  // onKeyPress={handleEnterKey}
                />
              </div>
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Loại BĐS</b>
              </label>

              <div className="z-[99999]">
                <div className="relative">
                  <button
                    className="inline-flex w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                    onClick={() => {
                      handleOpen(3);
                      getLoaiBDS();
                    }}
                  >
                    {' '}
                    {objBoLoc.loaibds ? (
                      <span className="mr-2 grid grid-cols-2 gap-2">
                        {objBoLoc.loaibds?.split(',')?.map((row, i) => (
                          <Chip
                            value={row}
                            variant="ghost"
                            icon={<XCircleIcon />}
                            key={i}
                            onClick={() => removeDataBds(objBoLoc.loaibds?.split(','), 'loaibds', i)}
                          />
                        ))}
                      </span>
                    ) : (
                      <span className="mr-2">Chọn Loại BĐS</span>
                    )}
                  </button>
                  {open === 3 && (
                    <div
                      className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                    >
                      <Input
                        // value={''}
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => handleSearchOptionSelect('loaibds', e.target.value, 'name')}
                      />

                      <div className="pl-1">
                        <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                      </div>
                      {arrOptionSelectSearch?.loaibds &&
                        arrOptionSelectSearch?.loaibds.map((row, index) => (
                          <div
                            className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                            key={index}
                            onClick={() => {
                              if (!checkText(objBoLoc.loaibds, row?.name)) {
                                setObjBoLoc({
                                  ...objBoLoc,
                                  loaibds: `${objBoLoc['loaibds'] ? objBoLoc['loaibds'] + ',' : ''}${row?.name}`,
                                });
                                handleOpen(0);
                              }
                            }}
                          >
                            <b className={`${checkText(objBoLoc.loaibds, row?.name) ? 'text-gray-500' : ''}`}>
                              {row?.name}
                            </b>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <label className=" text-sm mb-1">
                <b>Giá bán</b>
              </label>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <Input
                    value={numberFormat(objBoLoc?.giastart)}
                    placeholder="từ..."
                    className="rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                    labelProps={{
                      className: 'hidden',
                    }}
                    containerProps={{
                      className: 'min-w-[0px!important]',
                    }}
                    onChange={(e) => {
                      setObjBoLoc({
                        ...objBoLoc,
                        giastart: e.target.value ? numberFormatDeleteDots(e.target.value) : '',
                      });
                    }}
                  />
                  <b className="text-red-600 text-sm">{convertNumberToWords(objBoLoc?.giastart)}</b>
                </div>
                <div>
                  <Input
                    value={numberFormat(objBoLoc?.giaend)}
                    placeholder="đến..."
                    className=" rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                    labelProps={{
                      className: 'hidden',
                    }}
                    containerProps={{
                      className: 'min-w-[0px!important]',
                    }}
                    onChange={(e) => {
                      setObjBoLoc({
                        ...objBoLoc,
                        giaend: e.target.value ? numberFormatDeleteDots(e.target.value) : '',
                      });
                    }}
                  />
                  <b className="text-red-600 text-sm">{convertNumberToWords(objBoLoc?.giaend)}</b>
                </div>
              </div>
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Hướng BĐS</b>
              </label>

              <div className="z-[99999]">
                <div className="relative">
                  <button
                    className="inline-flex w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                    onClick={() => {
                      handleOpen(4);
                      getHuongBds();
                    }}
                  >
                    {' '}
                    {objBoLoc.huongbds ? (
                      <span className="mr-2 grid grid-cols-2 gap-2">
                        {objBoLoc.huongbds?.split(',')?.map((row, i) => (
                          <Chip
                            value={row}
                            variant="ghost"
                            icon={<XCircleIcon />}
                            key={i}
                            onClick={() => removeDataBds(objBoLoc.huongbds?.split(','), 'huongbds', i)}
                          />
                        ))}
                      </span>
                    ) : (
                      <span className="mr-2">Đông, Tây...</span>
                    )}
                  </button>
                  {open === 4 && (
                    <div
                      className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                    >
                      <Input
                        // value={''}
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => handleSearchOptionSelect('huongbds', e.target.value, 'name')}
                      />

                      <div className="pl-1">
                        <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                      </div>
                      {arrOptionSelectSearch?.huongbds &&
                        arrOptionSelectSearch?.huongbds.map((row, index) => (
                          <div
                            className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                            key={index}
                            onClick={() => {
                              if (!checkText(objBoLoc.huongbds, row?.name)) {
                                setObjBoLoc({
                                  ...objBoLoc,
                                  huongbds: `${objBoLoc['huongbds'] ? objBoLoc['huongbds'] + ',' : ''}${row?.name}`,
                                });
                                handleOpen(0);
                              }
                            }}
                          >
                            <b className={`${checkText(objBoLoc.huongbds, row?.name) ? 'text-gray-500' : ''}`}>
                              {row?.name}
                            </b>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <label className=" text-sm mb-1">
                <b>Số tầng</b>
              </label>
              <div className="grid grid-cols-2 gap-2">
                <Input
                  value={objBoLoc?.sotangstart}
                  placeholder="từ..."
                  className="rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, sotangstart: e.target.value });
                  }}
                />
                <Input
                  value={objBoLoc?.sotangend}
                  placeholder="đến..."
                  className=" rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, sotangend: e.target.value });
                  }}
                />
              </div>
            </div>

            <div>
              <label className=" text-sm mb-1">
                <b>Số phòng</b>
              </label>
              <div className="grid grid-cols-2 gap-2">
                <Input
                  value={objBoLoc?.sophongstart}
                  placeholder="từ..."
                  className="rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, sophongstart: e.target.value });
                  }}
                />
                <Input
                  value={objBoLoc?.sophongend}
                  placeholder="đến..."
                  className=" rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, sophongend: e.target.value });
                  }}
                />
              </div>
            </div>
            <div>
              <label className=" text-sm mb-1">
                <b>Ngang</b>
              </label>
              <div className="grid grid-cols-2 gap-2">
                <Input
                  value={objBoLoc?.ngangstart}
                  placeholder="từ..."
                  className="rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, ngangstart: e.target.value });
                  }}
                />
                <Input
                  value={objBoLoc?.ngangend}
                  placeholder="đến..."
                  className=" rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, ngangend: e.target.value });
                  }}
                />
              </div>
            </div>

            <div></div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Trạng thái</b>
              </label>

              <div className="z-[99999]">
                <div className="relative">
                  <button
                    className="inline-flex w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                    onClick={() => {
                      handleOpen(9);
                    }}
                  >
                    {' '}
                    {objBoLoc.istrangthai ? (
                      <span className="mr-2 grid grid-cols-2 gap-2">
                        {objBoLoc.istrangthai?.split(',')?.map((row, i) => (
                          <Chip
                            value={arrStatus[row - 1]['name']}
                            variant="ghost"
                            icon={<XCircleIcon />}
                            key={i}
                            onClick={() => removeDataBds(objBoLoc.istrangthai?.split(','), 'istrangthai', i)}
                          />
                        ))}
                      </span>
                    ) : (
                      <span className="mr-2">Độc quyền, đã bán...</span>
                    )}
                  </button>
                  {open === 9 && (
                    <div
                      className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                    >
                      <div className="pl-1">
                        <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                      </div>
                      {arrStatus &&
                        arrStatus.map((row, index) => (
                          <div
                            className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                            key={index}
                            onClick={() => {
                              if (!checkText(objBoLoc.istrangthai, row?.value)) {
                                setObjBoLoc({
                                  ...objBoLoc,
                                  istrangthai: `${objBoLoc['istrangthai'] ? objBoLoc['istrangthai'] + ',' : ''}${
                                    row?.value
                                  }`,
                                });
                                handleOpen(0);
                              }
                            }}
                          >
                            <b className={`${checkText(objBoLoc.istrangthai, row?.value) ? 'text-gray-500' : ''}`}>
                              {row?.name}
                            </b>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Quận, huyện</b>
              </label>
              <div className="relative mb-2">
                <select
                  value={objBoLoc.huyen}
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, huyen: e.target.value });
                  }}
                  className="w-full border-gray-400 rounded-xl bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                >
                  <option value="">Chọn quận , huyện ...</option>
                  {arrOptionSelect?.streets.length > 0 &&
                    uniqueData(arrOptionSelect?.streets, 'huyen')?.map((row, index) => (
                      <option key={index} value={row?.huyen}>
                        {row?.huyen}, {row?.tinh}
                      </option>
                    ))}
                </select>
                <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
              </div>
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Người nhập</b>
              </label>
              <Input
                value={objBoLoc?.username}
                placeholder="vd: kland01, admin1"
                className="w-full rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                onChange={(e) => {
                  setObjBoLoc({ ...objBoLoc, username: e.target.value });
                }}
              />
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Loại đăng tin</b>
              </label>
              <div className="relative mb-2">
                <select
                  value={objBoLoc.isdangtin}
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, isdangtin: e.target.value });
                  }}
                  className="w-full border-gray-400 rounded-xl bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                >
                  <option value="">Loại đăng tin</option>
                  <option value="1">Bán</option>
                  <option value="2">Cho thuê</option>
                </select>
                <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
              </div>
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Tiện nghi, kết cấu</b>
              </label>

              <div className="z-[99999]">
                <div className="relative">
                  <button
                    className="inline-flex w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                    onClick={() => {
                      handleOpen(5);
                      getTiennghi();
                    }}
                  >
                    {' '}
                    {objBoLoc.tiennghi ? (
                      <span className="mr-2 grid grid-cols-2 gap-2">
                        {objBoLoc.tiennghi?.split(',')?.map((row, i) => (
                          <Chip
                            value={row}
                            variant="ghost"
                            icon={<XCircleIcon />}
                            key={i}
                            onClick={() => removeDataBds(objBoLoc.tiennghi?.split(','), 'tiennghi', i)}
                          />
                        ))}
                      </span>
                    ) : (
                      <span className="mr-2">Hầm, thang máy...</span>
                    )}
                  </button>
                  {open === 5 && (
                    <div
                      className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                    >
                      <Input
                        // value={''}
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => handleSearchOptionSelect('tiennghi', e.target.value, 'name')}
                      />

                      <div className="pl-1">
                        <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                      </div>
                      {arrOptionSelectSearch?.tiennghi &&
                        arrOptionSelectSearch?.tiennghi.map((row, index) => (
                          <div
                            className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                            key={index}
                            onClick={() => {
                              if (!checkText(objBoLoc.tiennghi, row?.name)) {
                                setObjBoLoc({
                                  ...objBoLoc,
                                  tiennghi: `${objBoLoc['tiennghi'] ? objBoLoc['tiennghi'] + ',' : ''}${row?.name}`,
                                });
                                handleOpen(0);
                              }
                            }}
                          >
                            <b className={`${checkText(objBoLoc.tiennghi, row?.name) ? 'text-gray-500' : ''}`}>
                              {row?.name}
                            </b>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Đặc trưng</b>
              </label>

              <div className="z-[99999]">
                <div className="relative">
                  <button
                    className="inline-flex w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                    onClick={() => {
                      handleOpen(6);
                      getDactrung();
                    }}
                  >
                    {' '}
                    {objBoLoc.dactrung ? (
                      <span className="mr-2 grid grid-cols-2 gap-2">
                        {objBoLoc.dactrung?.split(',')?.map((row, i) => (
                          <Chip
                            value={row}
                            variant="ghost"
                            icon={<XCircleIcon />}
                            key={i}
                            onClick={() => removeDataBds(objBoLoc.dactrung?.split(','), 'dactrung', i)}
                          />
                        ))}
                      </span>
                    ) : (
                      <span className="mr-2">Có view, nở hậu...</span>
                    )}
                  </button>
                  {open === 6 && (
                    <div
                      className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                    >
                      <Input
                        // value={''}
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => handleSearchOptionSelect('dactrung', e.target.value, 'name')}
                      />

                      <div className="pl-1">
                        <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                      </div>
                      {arrOptionSelectSearch?.dactrung &&
                        arrOptionSelectSearch?.dactrung.map((row, index) => (
                          <div
                            className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                            key={index}
                            onClick={() => {
                              if (!checkText(objBoLoc.dactrung, row?.name)) {
                                setObjBoLoc({
                                  ...objBoLoc,
                                  dactrung: `${objBoLoc['dactrung'] ? objBoLoc['dactrung'] + ',' : ''}${row?.name}`,
                                });
                                handleOpen(0);
                              }
                            }}
                          >
                            <b className={`${checkText(objBoLoc.dactrung, row?.name) ? 'text-gray-500' : ''}`}>
                              {row?.name}
                            </b>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Pháp lý</b>
              </label>

              <div className="z-[99999]">
                <div className="relative">
                  <button
                    className="inline-flex w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                    onClick={() => {
                      handleOpen(7);
                      getPhaply();
                    }}
                  >
                    {' '}
                    {objBoLoc.phaply ? (
                      <span className="mr-2 grid grid-cols-2 gap-2">
                        {objBoLoc.phaply?.split(',')?.map((row, i) => (
                          <Chip
                            value={row}
                            variant="ghost"
                            icon={<XCircleIcon />}
                            key={i}
                            onClick={() => removeDataBds(objBoLoc.phaply?.split(','), 'phaply', i)}
                          />
                        ))}
                      </span>
                    ) : (
                      <span className="mr-2">Lọc Pháp lý</span>
                    )}
                  </button>
                  {open === 7 && (
                    <div
                      className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                    >
                      <Input
                        // value={''}
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => handleSearchOptionSelect('phaply', e.target.value, 'name')}
                      />

                      <div className="pl-1">
                        <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                      </div>
                      {arrOptionSelectSearch?.phaply &&
                        arrOptionSelectSearch?.phaply.map((row, index) => (
                          <div
                            className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                            key={index}
                            onClick={() => {
                              if (!checkText(objBoLoc.phaply, row?.name)) {
                                setObjBoLoc({
                                  ...objBoLoc,
                                  phaply: `${objBoLoc['phaply'] ? objBoLoc['phaply'] + ',' : ''}${row?.name}`,
                                });
                                handleOpen(0);
                              }
                            }}
                          >
                            <b className={`${checkText(objBoLoc.phaply, row?.name) ? 'text-gray-500' : ''}`}>
                              {row?.name}
                            </b>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Quy hoạch</b>
              </label>

              <div className="z-[99999]">
                <div className="relative">
                  <button
                    className="inline-flex w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                    onClick={() => {
                      handleOpen(8);
                      getQH();
                    }}
                  >
                    {' '}
                    {objBoLoc.quyhoach ? (
                      <span className="mr-2 grid grid-cols-2 gap-2">
                        {objBoLoc.quyhoach?.split(',')?.map((row, i) => (
                          <Chip
                            value={row}
                            variant="ghost"
                            icon={<XCircleIcon />}
                            key={i}
                            onClick={() => removeDataBds(objBoLoc.quyhoach?.split(','), 'quyhoach', i)}
                          />
                        ))}
                      </span>
                    ) : (
                      <span className="mr-2">Lọc Quy hoạch</span>
                    )}
                  </button>
                  {open === 8 && (
                    <div
                      className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                    >
                      <Input
                        // value={''}
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => handleSearchOptionSelect('quyhoach', e.target.value, 'name')}
                      />

                      <div className="pl-1">
                        <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                      </div>
                      {arrOptionSelectSearch?.quyhoach &&
                        arrOptionSelectSearch?.quyhoach.map((row, index) => (
                          <div
                            className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                            key={index}
                            onClick={() => {
                              if (!checkText(objBoLoc.quyhoach, row?.name)) {
                                setObjBoLoc({
                                  ...objBoLoc,
                                  quyhoach: `${objBoLoc['quyhoach'] ? objBoLoc['quyhoach'] + ',' : ''}${row?.name}`,
                                });
                                handleOpen(0);
                              }
                            }}
                          >
                            <b className={`${checkText(objBoLoc.quyhoach, row?.name) ? 'text-gray-500' : ''}`}>
                              {row?.name}
                            </b>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <label className=" text-sm mb-1">
                <b>Lọc ngày đăng</b>
              </label>
              <div className="grid grid-cols-2 gap-2">
                <Input
                  value={objBoLoc?.ngaydangstart}
                  placeholder="Từ ngày (nếu có)"
                  className="rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  type="date"
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, ngaydangstart: e.target.value });
                  }}
                />
                <Input
                  value={objBoLoc?.ngaydangend}
                  placeholder="Đến ngày (nếu có)"
                  className=" rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  type="date"
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, ngaydangend: e.target.value });
                  }}
                />
              </div>
            </div>

            <div className="mb-10">
              <label className=" text-sm mb-1">
                <b>Lọc ngày cập nhật</b>
              </label>
              <div className="grid grid-cols-2 gap-2">
                <Input
                  value={objBoLoc?.ngaycapnhatstart}
                  placeholder="Từ ngày (nếu có)"
                  className="rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  type="date"
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, ngaycapnhatstart: e.target.value });
                  }}
                />
                <Input
                  value={objBoLoc?.ngaycapnhatend}
                  placeholder="Đến ngày (nếu có)"
                  className=" rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  containerProps={{
                    className: 'min-w-[0px!important]',
                  }}
                  type="date"
                  onChange={(e) => {
                    setObjBoLoc({ ...objBoLoc, ngaycapnhatend: e.target.value });
                  }}
                />
              </div>
            </div>

            <div className="fixed bottom-[90px] right-[20px] z-10 flex gap-8 p-2 bg-orange-100 rounded-xl drop-shadow-2xl border-orange-400 ">
              <Button color="orange" size="sm" onClick={handleFilter}>
                Lọc tìm
              </Button>

              <Button
                color="pink"
                size="sm"
                className=" bg-pink-200 text-black"
                onClick={() => setOpenFilter(!openFilter)}
              >
                Đóng
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className="fixed bottom-[30px] right-[10px] z-10 ">
        <Button
          color="orange"
          size="sm"
          className=" flex items-center border-2 border-green-500 drop-shadow-md"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <ArrowsPointingOutIcon className="w-5 h-5 mr-2" />
          Lọc - Đang bán
        </Button>
      </div>
    </div>
  );
}
