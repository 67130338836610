import { countThongKeViewSo, historyBds, historyUser } from '../services/apiBds';
import { getCookie } from './helper';
const auth = getCookie('token');
export const historysBds = async (data) => {
  const result = await historyBds({ data: data });
};

export const historysUser = async (data) => {
  let arrData = { data: { ...data, id_user: auth?.id, username: auth?.username } };
  const result = await historyUser(arrData);
};
