import { getSV, postSV } from '../utils/https';

export const addStreets = async (data) => {
  try {
    const res = await postSV(`/addstreets`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateStreets = async (data) => {
  try {
    const res = await postSV(`/updatestreets`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeStreets = async (data) => {
  try {
    const res = await postSV(`/deletestreetsid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllStreets = async (page = 1, size = 50) => {
  try {
    const res = await getSV(`/liststreetsall?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdStreets = async (id) => {
  try {
    const res = await getSV(`/liststreetsid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllStreets = async () => {
  try {
    const res = await getSV(`/liststreetsallcount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// KHU QUY HOẠCH
export const addKqhs = async (data) => {
  try {
    const res = await postSV(`/addkqh`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateKqhs = async (data) => {
  try {
    const res = await postSV(`/updatekqh`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeKqhs = async (data) => {
  try {
    const res = await postSV(`/deletekqhid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllKqhs = async (page = 1, size = 50) => {
  try {
    const res = await getSV(`/listkqhall?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdKqhs = async (id) => {
  try {
    const res = await getSV(`/listkqhid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllKqhs = async () => {
  try {
    const res = await getSV(`/listkqhallcount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// QUY HOẠCH
export const addQhs = async (data) => {
  try {
    const res = await postSV(`/addqh`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateQhs = async (data) => {
  try {
    const res = await postSV(`/updateqh`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeQhs = async (data) => {
  try {
    const res = await postSV(`/deleteqhid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllQhs = async (page = 1, size = 50) => {
  try {
    const res = await getSV(`/listqhall?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdQhs = async (id) => {
  try {
    const res = await getSV(`/listqhid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllQhs = async () => {
  try {
    const res = await getSV(`/listqhallcount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// PHÁP LÝ
export const addPhaply = async (data) => {
  try {
    const res = await postSV(`/addphaply`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updatePhaply = async (data) => {
  try {
    const res = await postSV(`/updatephaply`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removePhaply = async (data) => {
  try {
    const res = await postSV(`/deletephaplyid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllPhaply = async (page = 1, size = 50) => {
  try {
    const res = await getSV(`/listphaplyall?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdPhaply = async (id) => {
  try {
    const res = await getSV(`/listphaplyid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllPhaply = async () => {
  try {
    const res = await getSV(`/listphaplyallcount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// HƯỚNG BDS
export const addHuongBds = async (data) => {
  try {
    const res = await postSV(`/addhuongbds`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateHuongBds = async (data) => {
  try {
    const res = await postSV(`/updatehuongbds`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeHuongBds = async (data) => {
  try {
    const res = await postSV(`/deletehuongbdsid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllHuongBds = async (page = 1, size = 50) => {
  try {
    const res = await getSV(`/listhuongbdsall?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdHuongBds = async (id) => {
  try {
    const res = await getSV(`/listhuongbdsid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllHuongBds = async () => {
  try {
    const res = await getSV(`/listhuongbdsallcount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// VỊ TRÍ BDS
export const addVitri = async (data) => {
  try {
    const res = await postSV(`/addvitri`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateVitri = async (data) => {
  try {
    const res = await postSV(`/updatevitri`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeVitri = async (data) => {
  try {
    const res = await postSV(`/deletevitriid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllVitri = async (page = 1, size = 50) => {
  try {
    const res = await getSV(`/listvitriall?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdVitri = async (id) => {
  try {
    const res = await getSV(`/listvitriid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllVitri = async () => {
  try {
    const res = await getSV(`/listvitriallcount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// LOẠI BĐS
export const addLoaibds = async (data) => {
  try {
    const res = await postSV(`/addloaibds`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateLoaibds = async (data) => {
  try {
    const res = await postSV(`/updateloaibds`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeLoaibds = async (data) => {
  try {
    const res = await postSV(`/deleteloaibdsid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllLoaibds = async (page = 1, size = 50) => {
  try {
    const res = await getSV(`/listloaibdsall?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdLoaibds = async (id) => {
  try {
    const res = await getSV(`/listloaibdsid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllLoaibds = async () => {
  try {
    const res = await getSV(`/listloaibdsallcount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// TIỆN NGHI
export const addTiennghi = async (data) => {
  try {
    const res = await postSV(`/addtiennghi`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateTiennghi = async (data) => {
  try {
    const res = await postSV(`/updatetiennghi`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeTiennghi = async (data) => {
  try {
    const res = await postSV(`/deletetiennghiid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllTiennghi = async (page = 1, size = 50) => {
  try {
    const res = await getSV(`/listtiennghiall?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdTiennghi = async (id) => {
  try {
    const res = await getSV(`/listtiennghiid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllTiennghi = async () => {
  try {
    const res = await getSV(`/listtiennghiallcount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// ĐẶC TRƯNG
export const addDactrung = async (data) => {
  try {
    const res = await postSV(`/adddactrung`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateDactrung = async (data) => {
  try {
    const res = await postSV(`/updatedactrung`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeDactrung = async (data) => {
  try {
    const res = await postSV(`/deletedactrungid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllDactrung = async (page = 1, size = 50) => {
  try {
    const res = await getSV(`/listdactrungall?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdDactrung = async (id) => {
  try {
    const res = await getSV(`/listdactrungid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllDactrung = async () => {
  try {
    const res = await getSV(`/listdactrungallcount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
