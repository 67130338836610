const images = {
  banner_login: require('./banner_login.jpg'),
  bg_login: require('./bg_login.png'),
  avt: require('./avt.png'),
  hdgooglemap: require('./hdgooglemap.png'),
  logokland: require('./logokland.jpg'),
  logo: require('./logo.png'),
  zalo: require('./iconzalo.png'),
};

export default images;
