import { useEffect, useState } from 'react';
import { countThongKe } from '../../../services/apiBds';
import { Button, Card, CardBody, Chip, Input, Typography } from '@material-tailwind/react';
import { EyeIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import ExportExcel from './exportExcel';
import { encode } from '../../../utils/helper';

function Thongke() {
  const [arrTk, setArrTk] = useState({});
  const [objBoLoc, setObjBoLoc] = useState({ ngaycapnhatstart: '', ngaycapnhatend: '', username: '' });
  const listAllThongKe = async (date = '', username = '') => {
    const resultAPI = await countThongKe(date, username);
    setArrTk(resultAPI);
  };
  useEffect(() => {
    listAllThongKe();
  }, []);

  const handleSub = async () => {
    listAllThongKe(
      objBoLoc.ngaycapnhatstart ? `${objBoLoc.ngaycapnhatstart},${objBoLoc.ngaycapnhatend}` : '',
      objBoLoc.username,
    );
  };

  return (
    <div className="grid grid-cols-1 gap-2">
      <div className="mb-5">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-2">
          <div>
            <label className=" text-sm mb-1">
              <b>Tìm theo username</b>
            </label>
            <Input
              value={objBoLoc?.username}
              placeholder="Nhập username cần tìm"
              className="rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
              labelProps={{
                className: 'hidden',
              }}
              containerProps={{
                className: 'min-w-[0px!important]',
              }}
              onChange={(e) => {
                setObjBoLoc({ ...objBoLoc, username: e.target.value });
              }}
            />
          </div>
          <div>
            <label className=" text-sm mb-1">
              <b>Tìm theo khoảng thời gian. từ ngày..</b>
            </label>
            <Input
              value={objBoLoc?.ngaycapnhatstart}
              placeholder="Từ ngày (nếu có)"
              className="rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
              labelProps={{
                className: 'hidden',
              }}
              containerProps={{
                className: 'min-w-[0px!important]',
              }}
              type="date"
              onChange={(e) => {
                setObjBoLoc({ ...objBoLoc, ngaycapnhatstart: e.target.value });
              }}
            />
          </div>
          <div>
            <label className=" text-sm mb-1">
              <b>Tìm theo khoảng thời gian. đến ngày..</b>
            </label>
            <Input
              value={objBoLoc?.ngaycapnhatend}
              placeholder="Đến ngày (nếu có)"
              className=" rounded-lg !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
              labelProps={{
                className: 'hidden',
              }}
              containerProps={{
                className: 'min-w-[0px!important]',
              }}
              type="date"
              onChange={(e) => {
                setObjBoLoc({ ...objBoLoc, ngaycapnhatend: e.target.value });
              }}
            />
          </div>
          <div className="mt-6">
            <Button color="orange" onClick={handleSub}>
              Tìm kiếm
            </Button>
          </div>
        </div>
      </div>
      <div>
        <Card className="overflow-hidden border border-gray-300 shadow-sm">
          <CardBody className="p-4">
            <div className="flex justify-between">
              <label className="font-bold text-xl text-black">THỐNG KÊ TỔNG QUÁT</label>
              <ExportExcel
                data={arrTk}
                date={
                  objBoLoc.ngaycapnhatstart
                    ? `${new Date(objBoLoc.ngaycapnhatstart).getDate()}-${
                        new Date(objBoLoc.ngaycapnhatstart).getMonth() + 1
                      } _ ${new Date(objBoLoc.ngaycapnhatend).getDate()}-${
                        new Date(objBoLoc.ngaycapnhatend).getMonth() + 1
                      }`
                    : ''
                }
              />
            </div>
            <hr className="my-2 border-blue-gray-50" />
            <div className="my-4 grid grid-cols-2 lg:grid-cols-3 gap-2">
              <div className="p-2 bg-blue-50 rounded-md overflow-auto h-[20rem]">
                <label className="flex items-center gap-2 text-blue-500 text-lg font-bold">
                  #1 Xem Sổ{' '}
                  <Chip
                    variant="ghost"
                    color="blue"
                    value={arrTk?.tkso?.sum}
                    icon={<EyeIcon className="w-4 h-4" />}
                    size="sm"
                    className="rounded-full"
                  />
                </label>
                <div>
                  {arrTk?.tkso?.total?.length > 0 &&
                    arrTk?.tkso?.total?.map((row, i) => (
                      <span key={i} className="text-sm flex items-center text-blue-500">
                        <span className="text-black">Mã #{row?.id_bds}</span> | <b>{row?.view} lượt xem</b>
                      </span>
                    ))}
                </div>
              </div>

              <div className="p-2 bg-red-50 rounded-md overflow-auto h-[20rem]">
                <label className="flex items-center gap-2 text-red-500 text-base font-bold">
                  #2 Xem SĐT{' '}
                  <Chip
                    variant="ghost"
                    color="red"
                    value={arrTk?.tksdt?.sum}
                    className="rounded-full"
                    icon={<EyeIcon className="w-4 h-4" />}
                    size="sm"
                  />
                </label>
                {arrTk?.tksdt?.total?.length > 0 &&
                  arrTk?.tksdt?.total?.map((row, i) => (
                    <span key={i} className="text-sm flex items-center text-red-500">
                      <span className="text-black">Mã #{row?.id_bds}</span> | <b>{row?.view} lượt xem</b>
                    </span>
                  ))}
              </div>

              <div className="p-2 bg-green-50 rounded-md overflow-auto h-[20rem]">
                <label className="flex items-center gap-2 text-green-500 text-base font-bold">
                  #3 Tải Hình{' '}
                  <Chip
                    variant="ghost"
                    color="green"
                    value={arrTk?.tkhinh?.sum}
                    className="rounded-full"
                    icon={<EyeIcon className="w-4 h-4" />}
                    size="sm"
                  />
                </label>
                {arrTk?.tkhinh?.total?.length > 0 &&
                  arrTk?.tkhinh?.total?.map((row, i) => (
                    <span key={i} className="text-sm flex items-center text-green-500">
                      <span className="text-black">Mã #{row?.id_bds}</span> | <b>{row?.view} lượt tải</b>
                    </span>
                  ))}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
        <Card className="overflow-hidden border border-blue-300 shadow-sm">
          <CardBody className="p-4">
            <label className="font-bold text-lg text-blue-800">THỐNG KÊ LƯỢT XEM SỔ - THEO USER</label>
            <hr className="my-2 border-blue-100" />
            <div className="mt-4 overflow-auto h-[20rem]">
              <div className="p-2 ">
                {/* <label className="flex items-center gap-2 text-blue-500 text-lg font-bold">
                  #1 Xem Sổ{' '}
                  <Chip
                    variant="ghost"
                    color="blue"
                    value={arrTk?.tkso?.sum}
                    icon={<EyeIcon className="w-4 h-4" />}
                    size="sm"
                    className="rounded-full"
                  />
                </label> */}
                {arrTk?.tkso?.arr?.length > 0 &&
                  arrTk?.tkso?.arr?.map((row, i) => (
                    <span key={i} className="text-sm flex items-center text-blue-500 gap-1">
                      <span className="text-black">Mã #{row?.id_bds}</span> |{' '}
                      <b className="text-teal-500">User: {row?.username}</b> | <b>{row?.view} lượt xem</b> |
                      <Link to={`/bds/${encode(row.id_bds)}`} target="_blank">
                        <b className="text-xs text-gray-500 cursor-pointer">xem tin</b>
                      </Link>
                    </span>
                  ))}
              </div>
            </div>
          </CardBody>
        </Card>

        <Card className="overflow-hidden border border-red-300 shadow-sm">
          <CardBody className="p-4">
            <label className="font-bold text-lg text-red-800">THỐNG KÊ LƯỢT XEM SĐT - THEO USER</label>
            <hr className="my-2 border-red-100" />
            <div className="mt-4 overflow-auto h-[20rem]">
              <div className="p-2 ">
                {/* <label className="flex items-center gap-2 text-red-500 text-lg font-bold">
                  #2 Xem SĐT{' '}
                  <Chip
                    variant="ghost"
                    color="red"
                    value={arrTk?.tksdt?.sum}
                    icon={<EyeIcon className="w-4 h-4" />}
                    size="sm"
                    className="rounded-full"
                  />
                </label> */}
                {arrTk?.tksdt?.arr?.length > 0 &&
                  arrTk?.tksdt?.arr?.map((row, i) => (
                    <span key={i} className="text-sm flex items-center text-red-500 gap-1">
                      <span className="text-black">Mã #{row?.id_bds}</span> |{' '}
                      <b className="text-teal-500">User: {row?.username}</b> | <b>{row?.view} lượt xem</b> |
                      <Link to={`/bds/${encode(row.id_bds)}`} target="_blank">
                        <b className="text-xs text-gray-500 cursor-pointer">xem tin</b>
                      </Link>
                    </span>
                  ))}
              </div>
            </div>
          </CardBody>
        </Card>

        <Card className="overflow-hidden border border-green-300 shadow-sm">
          <CardBody className="p-4">
            <label className="font-bold text-lg text-green-800">THỐNG KÊ LƯỢT TẢI HÌNH - THEO USER</label>
            <hr className="my-2 border-green-100" />
            <div className="mt-4 overflow-auto h-[20rem]">
              <div className="p-2 ">
                {/* <label className="flex items-center gap-2 text-green-500 text-lg font-bold">
                  #3 Tải Hình{' '}
                  <Chip
                    variant="ghost"
                    color="green"
                    value={arrTk?.tkhinh?.sum}
                    icon={<EyeIcon className="w-4 h-4" />}
                    size="sm"
                    className="rounded-full"
                  />
                </label> */}
                {arrTk?.tkhinh?.arr?.length > 0 &&
                  arrTk?.tkhinh?.arr?.map((row, i) => (
                    <span key={i} className="text-sm flex items-center text-green-500 gap-1">
                      <span className="text-black">Mã #{row?.id_bds}</span> |{' '}
                      <b className="text-teal-500">User: {row?.username}</b> | <b>{row?.view} lượt tải</b> |{' '}
                      <Link to={`/bds/${encode(row.id_bds)}`} target="_blank">
                        <b className="text-xs text-gray-500 cursor-pointer">xem tin</b>
                      </Link>
                    </span>
                  ))}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default Thongke;
