import { getSV, postSV } from '../utils/https';

//YÊU THÍCH
export const addYeuthich = async (data) => {
  try {
    const res = await postSV(`/addyeuthich`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateYeuthich = async (data) => {
  try {
    const res = await postSV(`/updateyeuthich`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeYeuthich = async (data) => {
  try {
    const res = await postSV(`/deleteyeuthichid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllYeuthich = async (page = 1, size = 50, id_user = '') => {
  const idUser = id_user ? `&id_user=${id_user}` : '';
  const sizeSP = size ? `&size=${size}` : '';
  try {
    const res = await getSV(`/listyeuthichall?page=${page}${sizeSP}${idUser}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdYeuthich = async (id, id_user = '') => {
  const idUser = id_user ? `&id_user=${id_user}` : '';
  try {
    const res = await getSV(`/listyeuthichid?id=${id} ${idUser}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllYeuthich = async (id_user) => {
  try {
    const res = await getSV(`/listyeuthichallcount?id_user=${id_user}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
