import { refreshToken } from '../services/apiUsers';
import Cookies from 'js-cookie';

export const getMultipleRandom = (arr) => {
  const randomObject = [...arr].sort(() => 0.5 - Math.random());
  return randomObject;
};
export const totalDiscount = (price, priceOld) => {
  let calcNumber;
  let priceNumber = price.replace(/[^a-zA-Z0-9 ]/g, '');
  let priceOldNumber = priceOld.replace(/[^a-zA-Z0-9 ]/g, '');
  let disCount = priceOldNumber - priceNumber;
  calcNumber = Math.round((disCount / priceOldNumber) * 100) + '%';
  calcNumber = calcNumber === 'NaN%' ? '' : calcNumber;
  return calcNumber;
};
function roundUp(money) {
  const lastDigit = money % 100000;
  let roundedMoney = money;
  if (lastDigit > 50000) {
    roundedMoney += 100000 - lastDigit;
  } else if (lastDigit === 50000) {
    roundedMoney = money;
  } else {
    roundedMoney -= lastDigit;
  }
  return roundedMoney;
}
export const totalPriceDiscount = (sim, simDiscount) => {
  let dis = [];
  simDiscount.map((priceDF) => {
    if (parseInt(sim.price) >= parseInt(priceDF.price_start) && parseInt(sim.price) <= parseInt(priceDF.price_end)) {
      const price = numberFormat(roundUp(sim.price - sim.price * (priceDF.discount / 100)).toString());
      const pricerose = numberFormat(
        (sim.price - roundUp(sim.price - sim.price * (priceDF.discount / 100))).toString(),
      );
      dis = [price, pricerose, priceDF.discount];
    }
  });
  return dis;
};
export const totalPriceRose = (sim, simDiscount) => {
  let dis;
  simDiscount.map((priceDF) => {
    if (sim.price >= priceDF.price_start && sim.price <= priceDF.price_end) {
      dis = numberFormat((sim.price - (sim.price - sim.price * (priceDF.discount / 100))).toString());
    }
  });
  return dis;
};
export const numberFormat = (number) => {
  let calcNumber;
  let numberPrice = number?.toString()?.replace(/[^a-zA-Z0-9 ]/g, '');
  if (isNaN(numberPrice)) {
    calcNumber = number;
  } else {
    calcNumber = new Intl.NumberFormat('vi-VN', { minimumFractionDigits: 0 }).format(numberPrice);
  }
  return calcNumber;
};

export const numberFormatText = (number) => {
  let num = number?.toString().replace(/[^a-zA-Z0-9 ]/g, '');
  if (isNaN(num)) {
    num = number;
  } else {
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: 'K' },
      { v: 1e6, s: ' Triệu' },
      { v: 1e9, s: ' Tỷ' },
      { v: 1e12, s: ' T' },
      { v: 1e15, s: ' P' },
      { v: 1e18, s: ' E' },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    num = (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
  }
  return num;
};
export const numberFormatTextShorten = (number) => {
  let num = number?.toString()?.replace(/[^a-zA-Z0-9 ]/g, '');
  if (isNaN(num)) {
    num = number;
  } else {
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: 'K' },
      { v: 1e6, s: 'Triệu' },
      { v: 1e9, s: 'Tỷ' },
      { v: 1e12, s: 'T' },
      { v: 1e15, s: 'P' },
      { v: 1e18, s: 'E' },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    num = (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
  }
  return num;
};
export const numberFormatTextCanvas = (number) => {
  let num = number.replace(/[^a-zA-Z0-9 ]/g, '');
  if (num < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: ' Tr' },
    { v: 1e9, s: ' Tỷ' },
    { v: 1e12, s: ' T' },
    { v: 1e15, s: ' P' },
    { v: 1e18, s: ' E' },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
};

export const numberFormatDeleteDots = (number) => {
  let numberPrice = number?.toString().replace(/[^a-zA-Z0-9 ]/g, '');
  // console.log(parseInt(numberPrice));
  // console.log(number.toString().replace(/\./g, ''));
  return parseInt(numberPrice);
};
export const mapRecords = (records, headerData) => {
  return records
    .filter((record) => record.length > 0)
    .map((record) =>
      record.reduce((obj, item, index) => {
        obj[headerData[index]] = item;
        return obj;
      }, {}),
    )
    .slice(1);
};

export const getCookie = (name) => {
  const token = Cookies.get(name);
  if (token) {
    return JSON.parse(decodeURIComponent(atob(token)));
  }
  return null;
};

export const setCookie = (name, item, option) => {
  const encodeData = btoa(encodeURIComponent(JSON.stringify(item)));
  Cookies.set(name, encodeData, option);
};

export const removeCookie = (name) => {
  return Cookies.remove(name);
};

export const getLocalStorage = (name) => {
  const local = localStorage.getItem(name);
  if (local != null) {
    return JSON.parse(decodeURIComponent(atob(local)));
  }
  return null;
};
export const setLocalStorage = (name, item) => {
  const encodeData = btoa(encodeURIComponent(JSON.stringify(item)));
  localStorage.setItem(name, encodeData);
};
export const removeLocalStorage = (name) => {
  return localStorage.removeItem(name);
};

export const refreshTokenNew = () => {
  const getToken = getCookie('token');
  const hostname = window.location.hostname;
  const exp = new Date(getCookie('exp_sv'));
  let nowDate = new Date(new Date().getTime());
  let inFifteenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);

  let expInSeconds = Math.floor(exp.getTime() / 1000);
  let nowDateInSeconds = Math.floor(nowDate.getTime() / 1000);
  let inFifteenMinutesInSeconds = Math.floor(inFifteenMinutes.getTime() / 1000);
  if (getToken) {
    if (expInSeconds <= inFifteenMinutesInSeconds && expInSeconds >= nowDateInSeconds) {
      refresh(getToken.username, hostname);
    }
  } else {
    window.location.href = '/login';
  }
};

const refresh = async (username, hostname) => {
  let inFifteenMinutes = new Date(new Date().getTime() + 55 * 60 * 1000);
  const refresh = await refreshToken({ user: username, domain: hostname });
  Cookies.set('token_sv', refresh['token'], { expires: inFifteenMinutes });
  setCookie('token', refresh['data'], { expires: inFifteenMinutes });
  setCookie('exp_sv', inFifteenMinutes, { expires: inFifteenMinutes });
};

export const removeSpecialCharacters = (str) => {
  // Sử dụng biểu thức chính quy để tìm và thay thế các ký tự đặc biệt
  const regex = /[^0-9]/g;
  const result = str.replace(regex, '');

  return result;
};

// chuyển text -> link
export const convertToSlug = (str) => {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  str = str.replace(/([^\w]+|\s+)/g, '-');
  str = str.replace(/\-\-+/g, '-');
  return str;
};

// lấy dữ liệu cuối cùng
export const getLastValue = (str) => {
  if (str) {
    const parts = str?.split('-');
    const lastPart = parts[parts.length - 1];
    return parseInt(lastPart);
  }
};
export const handleConvertMonths = (number) => {
  const num = parseInt(number);

  if (isNaN(num)) {
    return 'Invalid number';
  }

  if (num < 0) {
    return 'Number must be positive';
  }

  if (num < 30) {
    return `${num} ngày`;
  } else if (num < 360) {
    const months = Math.floor(num / 30);
    return `${months} tháng`;
  } else {
    const years = Math.floor(num / 360);
    return `${years} năm`;
  }
};

export const convertNumberToWords = (num) => {
  const units = ['', 'triệu', 'tỷ', 'ngàn tỷ', 'triệu tỷ', 'tỷ tỷ'];
  let number = parseInt(num?.toString());
  if (isNaN(number)) {
    number = num;
    return number;
  } else {
    let index = 0;
    while (number >= 1000 && index < units.length) {
      number /= 1000;
      index++;
    }
    return number.toLocaleString('vi-VN') + ' ' + (units[index - 1] ? units[index - 1] : '');
  }
};

export function laySoTuDoanText(text) {
  var match = text?.match(/\d+/); // Sử dụng regular expression để tìm các số trong text
  return match ? match[0] : null;
}
export function layTexTuDoanSo(text) {
  var match = text?.match(/[a-zA-Z]+/g); // Sử dụng regular expression để tìm các số trong text
  return match ? match[0] : null;
}

export function encode(num) {
  const charMap = {
    0: 'C',
    1: 'D',
    2: 'H',
    3: 'L',
    4: 'I',
    5: 'A',
    6: 'B',
    7: 'E',
    8: 'F',
    9: 'Q',
  };
  const charMapNumber = {
    0: '6',
    1: '',
    2: '$',
    3: '5',
    4: '9',
    5: '0',
    6: '8',
    7: '!',
    8: '&',
    9: '3',
  };

  let str = num.toString();
  let encoded = '';

  for (let i = 0; i < str.length; i++) {
    encoded += charMap[str[i]];
  }
  for (let i = 0; i < str.length; i++) {
    encoded += charMapNumber[str[i]];
  }

  return encoded;
}

export function decode(str) {
  let decoded = '';
  if (str) {
    const strx = layTexTuDoanSo(str);
    const charMap = {
      C: 0,
      D: 1,
      H: 2,
      L: 3,
      I: 4,
      A: 5,
      B: 6,
      E: 7,
      F: 8,
      Q: 9,
    };

    for (let char of strx) {
      decoded += charMap[char];
    }
  }

  return parseInt(decoded);
}
