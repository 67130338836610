import { ChevronUpDownIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Button, Chip, IconButton, Input, Option, Select } from '@material-tailwind/react';
import FrameShowBds from '../../../components/FrameShowBds';
import { countCmt, listProductIDAllShowCount, listProductIDShow } from '../../../services/apiBds';
import { useEffect, useState } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { getCookie } from '../../../utils/helper';
import LoadingFrame from '../../../components/loadingFrame';
import { listAllStreets } from '../../../services/apiCategory';
import { listAllYeuthich } from '../../../services/apiYeuthich';

export default function Cogihot() {
  const auth = getCookie('token');
  const [resultListBds, setResultListBds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [countBds, setCountBds] = useState(0);
  const [page, setPage] = useState(1);
  const [objBoLoc, setObjBoLoc] = useState({
    code: '',
    status: '2',
    xa: '',
    huyen: '',
  });
  const [arrOptionSelect, setArrOptionSelect] = useState({ streets: [], loaibds: [] });
  const [isYeuThich, setIsYeuThich] = useState([]);
  const [countCmts, setCountCmts] = useState(0);

  const listAllYeuthichs = async (pages = 1) => {
    const resultAPI = await listAllYeuthich(pages, '', auth?.id);
    if (resultAPI.length > 0) {
      setIsYeuThich(resultAPI);
    }
  };

  const listBdsAll = async (pages = 1, status = '') => {
    setLoading(true);
    const resultBDSAll = await listProductIDShow(pages, status);
    // console.log(resultBDSAll);
    if (resultBDSAll.length > 0) {
      setResultListBds(resultBDSAll);
      const countBds = await listProductIDAllShowCount(status);
      setCountBds(countBds.totalRows);
      setLoading(false);
    } else {
      setLoading(false);
      setResultListBds([]);
      setCountBds(0);
    }
  };

  const listBdsAllSearch = async (pages = 1, status = '2', xa = '', huyen = '') => {
    // console.log(ngaydang);
    const resultBDSAll = await listProductIDShow(pages, status, '', '', '', xa, huyen);
    setLoading(true);
    if (resultBDSAll.length > 0) {
      setResultListBds(resultBDSAll);
      setLoading(false);
      const countBds = await listProductIDAllShowCount(status, '', '', '', xa, huyen);

      setCountBds(countBds.totalRows);
    } else {
      setResultListBds([]);
      setLoading(false);
      setCountBds(0);
    }
  };

  const getItemProps = (index) => ({
    variant: page === index ? 'filled' : 'text',
    color: 'gray',
    onClick: () => {
      setPage(index);
      listBdsAllSearch(index, objBoLoc.status, objBoLoc.xa, objBoLoc.huyen);
      scrollToTop();
    },
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const next = () => {
    if (page === countBds) return;

    const total = Math.ceil(countBds / 50);
    if (total > page) {
      setPage(page + 1);
      listBdsAllSearch(page + 1, objBoLoc.status, objBoLoc.xa, objBoLoc.huyen);
      scrollToTop();
    }
  };

  const prev = () => {
    if (page === countBds) return;

    const total = Math.ceil(countBds / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listBdsAllSearch(page - 1, objBoLoc.status, objBoLoc.xa, objBoLoc.huyen);
      scrollToTop();
    }
  };

  const getStreets = async () => {
    const resultAPIXA = await listAllStreets(1, 1000);
    if (resultAPIXA.length > 0) {
      setArrOptionSelect({ streets: resultAPIXA });
    }
  };
  const getCountBL = async () => {
    const resultAPI = await countCmt();
    if (resultAPI.totalRows > 0) {
      setCountCmts(resultAPI.totalRows);
    }
  };

  useEffect(() => {
    listBdsAll(1, '2');
    listAllYeuthichs();
    getStreets();
    getCountBL();
  }, []);

  const pagesToShow = 5;
  const totalNumbers = Array.from(new Array(Math.ceil(countBds / 50)), (val, index) => index + 1);

  const firstPage = Math.max(1, page - Math.floor(pagesToShow / 2));
  const lastPage = Math.min(Math.ceil(countBds / 50), firstPage + pagesToShow - 1);

  const prevPages = firstPage > 1 ? [1, '...'] : [];
  const nextPages = lastPage < Math.ceil(countBds / 50) ? ['...', Math.ceil(countBds / 50)] : [];

  const pageNumbers = [...prevPages, ...totalNumbers.slice(firstPage - 1, lastPage), ...nextPages];

  const uniqueData = (data, key) => {
    return data.filter((item, index, self) => index === self.findIndex((t) => t[key] === item[key]));
  };
  return (
    <div className="container m-auto">
      {loading && (
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
          <div className="lg:col-span-3">
            <LoadingFrame />
          </div>
          <div className="hidden lg:block lg:col-span-1">
            <LoadingFrame />
          </div>
        </div>
      )}
      {!loading && (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
          <div className="lg:col-span-4">
            <div className="flex items-center justify-center mb-2 gap-4">
              <label className="font-bold text-lg">Hôm nay có {countBds} Tin mới & Bình luận mới</label>
            </div>
            <div className="lg:hidden text-center">
              <span className="text-lg font-bold ">Tổng cộng: {countCmts} Lượt bình luận</span>
              <div className="px-4 py-1">
                <div className="relative mb-2">
                  <select
                    value={objBoLoc.xa}
                    onChange={(e) => {
                      listBdsAllSearch(1, objBoLoc.status, e.target.value, objBoLoc.huyen);
                      setObjBoLoc({ ...objBoLoc, xa: e.target.value });
                    }}
                    className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                  >
                    <option value="">Chọn Phường, xã</option>
                    {arrOptionSelect?.streets.length > 0 &&
                      uniqueData(arrOptionSelect?.streets, 'xa')?.map((row, index) => (
                        <option key={index} value={row?.xa}>
                          {row?.xa},{row?.huyen}
                        </option>
                      ))}
                  </select>
                  <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                </div>
              </div>
            </div>
            {resultListBds.length > 0 &&
              resultListBds?.map((row, i) => (
                <div key={i}>
                  {' '}
                  <FrameShowBds data={row} yeuthich={isYeuThich} />
                </div>
              ))}
            <hr className="my-4 border-blue-gray-50" />
            <div className="flex items-center justify-center">
              <div>
                <Button
                  variant="text"
                  size="sm"
                  className="flex items-center gap-1"
                  onClick={prev}
                  disabled={page === 1}
                >
                  <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Trước
                </Button>
              </div>
              <div className="flex">
                {pageNumbers.map((number, index) =>
                  number !== '...' ? (
                    <IconButton key={index} {...getItemProps(number)} size="sm">
                      {number}
                    </IconButton>
                  ) : (
                    <IconButton key={index} variant="text" size="sm">
                      {number}
                    </IconButton>
                  ),
                )}
              </div>

              <div>
                <Button
                  variant="text"
                  size="sm"
                  className="flex items-center gap-1"
                  onClick={next}
                  disabled={page === Math.ceil(countBds / 50)}
                >
                  Sau
                  <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>

          <div className="lg:col-span-1 gap-4">
            <div className="hidden lg:block">
              <div className="grid gap-2 ">
                <div>
                  <span className="text-lg font-bold ">Tổng cộng: {countCmts} Lượt bình luận</span>
                  <label className="flex items-end gap-1 text-sm mb-1 mt-4">
                    <b>Phường, xã</b>
                  </label>
                  <div className="relative mb-2">
                    <select
                      value={objBoLoc.xa}
                      onChange={(e) => {
                        listBdsAllSearch(1, objBoLoc.status, e.target.value, objBoLoc.huyen);
                        setObjBoLoc({ ...objBoLoc, xa: e.target.value });
                      }}
                      className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                    >
                      <option value="">Chọn Phường, xã</option>
                      {arrOptionSelect?.streets.length > 0 &&
                        uniqueData(arrOptionSelect?.streets, 'xa')?.map((row, index) => (
                          <option key={index} value={row?.xa}>
                            {row?.xa},{row?.huyen}
                          </option>
                        ))}
                    </select>
                    <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
