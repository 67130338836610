import {
  ArrowRightCircleIcon,
  CheckIcon,
  HomeModernIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { CheckBadgeIcon, EyeIcon, PencilIcon, TrashIcon, UserMinusIcon, UserPlusIcon } from '@heroicons/react/24/solid';
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
  Checkbox,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react';

import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
  listProductIDAll,
  listProductIDAllCount,
  listProductIDAllCountUser,
  listProductIDAllUser,
  updateBds,
} from '../../../services/apiBds';
import { decode, encode, getCookie, numberFormatTextShorten } from '../../../utils/helper';
import { API_URL } from '../../../utils/urls';

const TABLE_HEAD = ['Mã SP', 'Tiêu đề', 'Trạng thái', 'Người đăng', 'Loại tin', 'Hạn chế', 'Ngày tạo', 'Tác vụ'];

const TABS = [
  {
    label: 'Tin Đã Duyệt',
    value: '',
  },
  {
    label: 'Tin Chờ Duyệt',
    value: 3,
  },
];

export default function DsDuyetbds() {
  const [resultListBds, setResultListBds] = useState([]);
  const [page, setPage] = useState(1);
  const [countChoDuyet, setCountChoDuyet] = useState('');
  const [countBds, setCountBds] = useState(0);
  const [objBoLoc, setObjBoLoc] = useState({ stt: '3', code: '' });
  const [openDetail, setOpenDetail] = useState(false);
  const [objDetailBds, setObjDetailBds] = useState(false);

  const handleOpen = () => setOpenDetail(!openDetail);

  const listUserAll = async (pages = 1, stt = '', code = '') => {
    const resultBDSAll = await listProductIDAll(pages, stt, code);
    if (resultBDSAll.length > 0) {
      setResultListBds(resultBDSAll);
      const countBds = await listProductIDAllCount(stt, code);
      setCountBds(countBds.totalRows);
    } else {
      setResultListBds([]);
    }
  };

  const listTinChoDuyet = async () => {
    const countBds = await listProductIDAllCount(3);
    setCountChoDuyet(countBds.totalRows);
  };

  useEffect(() => {
    listUserAll(1, 3);
    listTinChoDuyet();
    document.title = 'Danh sách nhân viên';
  }, []);

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      listUserAll(1, objBoLoc.stt, decode(objBoLoc.code));
    }
  };

  const nextPage = () => {
    const total = Math.ceil(countBds / 50);
    if (total > page) {
      setPage(page + 1);
      listUserAll(page + 1, objBoLoc.stt, decode(objBoLoc.code));
    }
  };

  const prevPage = () => {
    const total = Math.ceil(countBds / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listUserAll(page - 1, objBoLoc.stt, decode(objBoLoc.code));
    }
  };

  const alertRemoveUser = async (id, status) => {
    if (status === '1') {
      const formData = new FormData();
      const body = { id: id, status: status };
      formData.append('data', JSON.stringify(body));
      //   formData.append('imgremove', JSON.stringify([]));
      const resultAPISIM = await updateBds(formData);
      if (resultAPISIM?.message === 'OK') {
        listUserAll(page, '3', decode(objBoLoc.code));
        listTinChoDuyet();
      }
    } else {
      if (window.confirm(status === '3' ? `Bạn đã chắc chắn chuyển sang Chờ Duyệt` : ``)) {
        // Save it!
        const formData = new FormData();
        const body = { id: id, status: status };
        formData.append('data', JSON.stringify(body));
        //   formData.append('imgremove', JSON.stringify([]));
        const resultAPISIM = await updateBds(formData);
        if (resultAPISIM?.message === 'OK') {
          listUserAll(page, '1', decode(objBoLoc.code));
          listTinChoDuyet();
        }
      } else {
        // Do nothing!
        console.log('Hủy yêu cầu');
      }
    }
  };

  // function decode(text) {
  //   var match = text?.match(/\d+/); // Sử dụng regular expression để tìm các số trong text
  //   return match ? match[0] : null;
  // }

  const typeImg = (str) => {
    const lastIndex = str.lastIndexOf('.');
    const result = str.substring(lastIndex + 1);
    if (result === 'png' || result === 'jpg' || result === 'jpeg') {
      return true;
    } else {
      return false;
    }
  };

  const subSttDocquyen = async (id, jsonVL) => {
    const formData = new FormData();
    const body = { id: id, ...jsonVL };
    formData.append('data', JSON.stringify(body));
    //   formData.append('imgremove', JSON.stringify([]));
    const resultAPISIM = await updateBds(formData);
    if (resultAPISIM?.message === 'OK') {
      listUserAll(page, objBoLoc.stt, objBoLoc.username, decode(objBoLoc.code));
    }
  };
  return (
    <div>
      <Dialog open={openDetail} handler={handleOpen}>
        <DialogHeader className="font-[UTM-Avo]">{objDetailBds?.tieude}</DialogHeader>
        <DialogBody className="h-[40rem] overflow-scroll leading-8 font-[500]">
          <div className="text-gray-900 font-[UTM-Avo] ">
            <p>
              <b>👉 Số nhà</b>: {objDetailBds?.sonha}
            </p>
            <p>
              <b>👉 Đường</b>: {objDetailBds?.duong}
              <b className="ml-2">Phường/xã</b>: {objDetailBds?.xa}
              <b className="ml-2">Quận/huyện</b>: {objDetailBds?.huyen}
              <b className="ml-2">Tỉnh</b>: {objDetailBds?.tinh}
            </p>
            <p>
              <b>👉 Khu quy hoạch</b>: {objDetailBds?.khuqh}
            </p>
            <p>
              <b>👉 Số tờ/Số thửa</b>:{' '}
              {objDetailBds?.soto?.split(',').length > 0 &&
                objDetailBds?.soto
                  ?.split(',')
                  .map((row, i) => (
                    <p key={i}>{`- ${row}/${
                      objDetailBds?.sothua?.split(',').length > 0 ? objDetailBds?.sothua?.split(',')[i] : ''
                    }`}</p>
                  ))}
            </p>
            <p>
              <b>👉 Số chủ đất</b>:{' '}
              {objDetailBds?.sdtchudat?.split(',').length > 0 &&
                objDetailBds?.sdtchudat?.split(',').map((row, i) => <p key={i}>{`- ${row}`}</p>)}
            </p>
            <p>
              <b>👉 Bán - Cho Thuê: </b>
              <p>{objDetailBds?.isbandat === 1 && '- Bán'}</p>
              <p>{objDetailBds?.ischothue === 1 && ' - Cho thuê'}</p>
            </p>
            <p>
              <b>👉 Rộng đường trước nhà: </b> {objDetailBds?.rongduong} m
            </p>
            <p>
              <b>👉 Quy hoạch: </b> {objDetailBds?.quyhoach}
            </p>
            <p>
              <b>👉 Pháp lý: </b> {objDetailBds?.phaply}
            </p>
            <p>
              <b>👉 Hướng: </b> {objDetailBds?.huongbds}
            </p>
            <p>
              <b>👉 Loại vị trí: </b> {objDetailBds?.loaivitri}
            </p>
            <p>
              <b>👉 Loại bđs: </b> {objDetailBds?.loaibds}
            </p>
            <p>
              <b>👉 Tiện Nghi & Kết Cấu: </b> {objDetailBds?.tiennghi}
            </p>
            <p>
              <b>👉 Đặc trưng: </b> {objDetailBds?.dactrung}
            </p>
            <p>
              <b>👉 Ghi chú khách: </b> {objDetailBds?.notekhach}
            </p>
            <p>
              <b>👉 Ghi chú nội bộ: </b> {objDetailBds?.notenoibo}
            </p>
            <p>
              <b>👉 Diện tích đất ở: </b> {objDetailBds?.dientich} m2
            </p>
            <p>
              <b>👉 DT đất nông nghiệp: </b> {objDetailBds?.dientichdatnn} m2
            </p>
            <p>
              <b>👉 Tổng DT sàn (các tầng): </b> {objDetailBds?.dientichsan} m2
            </p>
            <p>
              <b>👉 Ngang: </b> {objDetailBds?.ngang} m
            </p>
            <p>
              <b>👉 Ngang hậu: </b> {objDetailBds?.nganghau} m
            </p>
            <p>
              <b>👉 Dài: </b> {objDetailBds?.dai} m
            </p>
            <p>
              <b>👉 Số tầng: </b> {objDetailBds?.sotang} tầng
            </p>
            <p>
              <b>👉 Số phòng: </b> {objDetailBds?.sophong} phòng
            </p>
            <p>
              <b>👉 Số wc: </b> {objDetailBds?.sowc} wc
            </p>
            <p>
              <b>👉 Thu nhập từ cho thuê (số tiền): </b>{' '}
              <b className="text-red-500"> {numberFormatTextShorten(objDetailBds?.giathue)}</b>
            </p>
            <p>
              <b>👉 Giá bán (số tiền): </b>{' '}
              <b className="text-red-500"> {numberFormatTextShorten(objDetailBds?.gia)}</b>
            </p>
            <p>
              <b>Ảnh BDS: </b>
            </p>
            <div className="grid grid-cols-5 gap-2">
              {objDetailBds.anhto?.split(',').length > 0 &&
                objDetailBds.anhto?.split(',').map((imageSrc, i) => (
                  <figure className="relative w-full border border-amber-500 border-dashed p-1" key={i}>
                    <img src={`${API_URL}/imgshop?name=${imageSrc}`} alt="not fount" width={'100%'} />
                  </figure>
                ))}
            </div>

            <p>
              <b>Sổ, giấy tờ: </b>
            </p>
            <div className="grid grid-cols-5 gap-2">
              {objDetailBds.anhthua?.split(',').length > 0 &&
                objDetailBds.anhthua?.split(',').map((imageSrc, i) => (
                  <figure className="relative w-full border border-amber-500 border-dashed p-1" key={i}>
                    {typeImg(imageSrc) ? (
                      <LazyLoadImage
                        alt={'không có ảnh'}
                        width={'100%'}
                        effect="blur"
                        src={`${API_URL}/imgshop?name=${imageSrc}`}
                      />
                    ) : (
                      <a href={`${API_URL}/imgshop?name=${imageSrc}`} download={imageSrc} width={'100%'}>
                        {'file_' + i + 1}
                      </a>
                    )}
                  </figure>
                ))}
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
            <span>Đóng</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <Card className="w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <div className="text-xl font-bold">DANH SÁCH DUYỆT BÀI VIẾT BĐS</div>
              {/* <Typography color="gray" className="mt-1 font-normal">
                Tất cả nhân viên đang hoạt động và đã nghỉ
              </Typography> */}
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              {/* <Button variant="outlined" size="sm">
                view all
              </Button> */}
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <Tabs value={3} className="w-full md:w-96">
              <TabsHeader>
                {TABS.map(({ label, value }) => (
                  <Tab
                    key={value}
                    value={value}
                    onClick={() => {
                      listUserAll(1, value, objBoLoc.code);
                      setObjBoLoc({ ...objBoLoc, stt: value });
                    }}
                  >
                    &nbsp;&nbsp;{`${label}${value === 3 ? `${countChoDuyet ? `(${countChoDuyet})` : ''}` : ''}`}
                    &nbsp;&nbsp;
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>
            <div className="w-full md:w-96">
              <Input
                label="Tìm kiếm theo mã sản phẩm"
                value={objBoLoc?.code}
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                onChange={(e) => setObjBoLoc({ ...objBoLoc, code: e.target.value })}
                onKeyPress={handleEnterKey}
              />
              <Typography variant="small" color="gray" className="mt-2 flex items-center gap-1 font-normal">
                <InformationCircleIcon className="h-4 w-4" />
                Nhập Mã Sản Phẩm tìm kiếm và sau đó ấn enter
              </Typography>
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-auto px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {resultListBds.map((row, index) => {
                const isLast = index === resultListBds.length - 1;
                const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50';

                return (
                  <tr key={index}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <label className="font-normal text-base">
                            <b>#{`${encode(row.id)}`}</b>
                          </label>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col w-80">
                        <label className="font-normal text-base">{row.tieude}</label>
                        {/* <label className="font-normal text-sm opacity-70">
                          {row.permission === 1
                            ? '(Quản Trị)'
                            : row.permission === 2
                            ? '(Kiểm Duyệt Viên)'
                            : row.permission === 3
                            ? '(Quản Lý)'
                            : '(Sale)'}
                        </label> */}
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          variant="ghost"
                          size="sm"
                          value={row.status === '1' ? 'Đang bán' : row.status === '3' ? 'Chờ duyệt' : 'Đã bán'}
                          color={row.status === '1' ? 'green' : row.status === '3' ? 'orange' : 'blue-gray'}
                        />
                      </div>
                    </td>

                    <td className={classes}>
                      <div className="flex flex-col">
                        <label className="font-normal text-sm ">{row?.username}</label>
                      </div>
                    </td>

                    <td className={classes}>
                      <div className="grid">
                        <Tooltip content={`Tích chọn tin độc quyền`}>
                          <Checkbox
                            label="Độc quyền"
                            onChange={(e) => {
                              subSttDocquyen(row.id, { isdocquyen: row.isdocquyen === 1 ? 0 : 1 });
                            }}
                            checked={row.isdocquyen === 1}
                          />
                        </Tooltip>
                        <Tooltip content={`Tích chọn tin hàng hot`}>
                          <Checkbox
                            label="Hàng hot"
                            onChange={(e) => {
                              subSttDocquyen(row.id, { isdocquyen: row.isdocquyen === 2 ? 0 : 2 });
                            }}
                            checked={row.isdocquyen === 2}
                          />
                        </Tooltip>
                      </div>
                    </td>

                    <td className={classes}>
                      <div className="grid">
                        <Tooltip content={`Tích chọn hạn chế gọi`}>
                          <Checkbox
                            label="Hạn chế gọi"
                            onChange={(e) => {
                              subSttDocquyen(row.id, { ishanche: row.ishanche === 1 ? 0 : 1 });
                            }}
                            checked={row.ishanche === 1}
                          />
                        </Tooltip>
                        <Tooltip content={`Tích chọn tin không được gọi`}>
                          <Checkbox
                            label="Không được gọi"
                            onChange={(e) => {
                              subSttDocquyen(row.id, { ishanche: row.ishanche === 2 ? 0 : 2 });
                            }}
                            checked={row.ishanche === 2}
                          />
                        </Tooltip>
                      </div>
                    </td>

                    <td className={classes}>
                      <div className="flex flex-col">
                        <label className="font-normal text-sm ">{row?.update_date}</label>
                        <label className="font-normal text-sm opacity-70">{row?.create_date}</label>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex gap-2">
                        <Tooltip content={`Xem chi tiết`}>
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              handleOpen();
                              setObjDetailBds(row);
                            }}
                            size="sm"
                            color="blue"
                          >
                            <EyeIcon className={`h-4 w-4`} />
                          </IconButton>
                        </Tooltip>
                        {row.status === '3' ? (
                          <Tooltip content="Duyệt tin bài viết">
                            <Button color="deep-orange" size="sm" onClick={() => alertRemoveUser(row.id, '1')}>
                              {' '}
                              Duyệt bài viết
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip content="Chuyển sang chế độ chờ duyệt">
                            <Button color="gray" size="sm" onClick={() => alertRemoveUser(row.id, '3')}>
                              {' '}
                              Hủy Duyệt
                            </Button>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        {Math.ceil(countBds / 50) > page && (
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography variant="small" color="blue-gray" className="font-normal">
              Page {page} of {Math.ceil(countBds / 50)}
            </Typography>
            <div className="flex gap-2">
              <Button variant="outlined" size="sm" onClick={prevPage}>
                Trang Trước
              </Button>
              <Button variant="outlined" size="sm" onClick={nextPage}>
                Trang Tiếp
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
    </div>
  );
}
