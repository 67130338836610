import {
  ArrowTrendingUpIcon,
  BuildingOffice2Icon,
  CheckBadgeIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import { Button, Card, CardBody, CardFooter, CardHeader, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { listProductIDAllCount } from '../../../services/apiBds';
import { useEffect, useState } from 'react';
import { getCookie } from '../../../utils/helper';

export default function Dashboard() {
  const [countChoDuyet, setCountChoDuyet] = useState(0);
  const auth = getCookie('token');
  useEffect(() => {
    document.title = 'Hệ Thống Bất Động Sản KLAND';
    listTinChoDuyet();
  }, []);

  const listTinChoDuyet = async () => {
    const countBds = await listProductIDAllCount(3);
    setCountChoDuyet(countBds.totalRows);
  };
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
      <Card className="mt-6 border">
        <CardBody>
          <div className="grid justify-items-center">
            <UserIcon className="w-24 h-24 text-gray-400" />
            <Typography variant="h5" color="blue-gray" className="mb-2 font-[UTM-Avo]">
              Quản Lý Thông Tin
            </Typography>
            <Typography>Quản lý thông tin cá nhân. Cập nhật ảnh đại diện, thông tin ...</Typography>
          </div>
        </CardBody>
        <CardFooter className="pt-0 m-auto">
          <Link to="/user">
            <Button color="cyan">Chi tiết</Button>
          </Link>
        </CardFooter>
      </Card>
      {auth?.permission === 1 && (
        <Card className="mt-6 border">
          <CardBody>
            <div className="grid justify-items-center">
              <UserGroupIcon className="w-24 h-24 text-gray-400" />
              <Typography variant="h5" color="blue-gray" className="mb-2 font-[UTM-Avo]">
                Quản Lý Nhân Viên
              </Typography>
              <Typography>
                Quản lý nhân viên theo cấp bậc
                <br /> Thêm, Sửa, Xóa nhân viên
              </Typography>
            </div>
          </CardBody>
          <CardFooter className="pt-0 m-auto">
            <Link to="/dsusers">
              <Button color="green">Chi tiết</Button>
            </Link>
          </CardFooter>
        </Card>
      )}
      <Card className="mt-6 border">
        <CardBody>
          <div className="grid justify-items-center">
            <BuildingOffice2Icon className="w-24 h-24 text-gray-400" />
            <Typography variant="h5" color="blue-gray" className="mb-2 font-[UTM-Avo]">
              Quản Lý Bài BĐS
            </Typography>
            <Typography>
              Quản lý tin bài viết <br />
              Thêm - Sửa - Xóa BĐS của bạn
            </Typography>
          </div>
        </CardBody>
        <CardFooter className="pt-0 m-auto">
          <Link to="/dsbds">
            <Button color="orange">Chi tiết</Button>
          </Link>
        </CardFooter>
      </Card>
      {(auth?.permission === 1 || auth?.permission === 2) && (
        <Card className="mt-6 border">
          <CardBody>
            <div className="grid justify-items-center">
              <CheckBadgeIcon className="w-24 h-24 text-gray-400" />
              <Typography variant="h5" color="blue-gray" className="mb-2 font-[UTM-Avo]">
                Kiểm Duyệt Bài Viết
              </Typography>
              <Typography>Quản lý - kiểm duyệt bài viết</Typography>
              <Typography className="font-bold text-orange-700">
                {countChoDuyet > 0 && `Đang có ${countChoDuyet} tin chưa duyệt`}
              </Typography>
            </div>
          </CardBody>
          <CardFooter className="pt-0 m-auto">
            <Link to="/dsduyetbds">
              <Button color="blue">Chi tiết</Button>
            </Link>
          </CardFooter>
        </Card>
      )}

      {auth?.permission === 1 && (
        <Card className="mt-6 border">
          <CardBody>
            <div className="grid justify-items-center">
              <ArrowTrendingUpIcon className="w-24 h-24 text-gray-400" />
              <Typography variant="h5" color="blue-gray" className="mb-2 font-[UTM-Avo]">
                Thống Kê Lượt Xem
              </Typography>
              <Typography>Quản lý - thống kê lượt xem, tải file</Typography>
            </div>
          </CardBody>
          <CardFooter className="pt-0 m-auto">
            <Link to="/thongke">
              <Button color="blue">Chi tiết</Button>
            </Link>
          </CardFooter>
        </Card>
      )}
    </div>
  );
}
