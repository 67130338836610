import { get, getSV, postSV, postUpload } from '../utils/https';

export const addBds = async (formData) => {
  try {
    const res = await postUpload(`/addbds`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const historyBds = async (data) => {
  try {
    const res = await postSV(`/hisbds`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const historyUser = async (data) => {
  try {
    const res = await postSV(`/hisuser`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getHistoryBDS = async (truong, idbds) => {
  try {
    const res = await getSV(`/gethisbds?tr=${truong}&id=${idbds}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const uploadFileSim = async (data) => {
  try {
    const res = await postUpload(`/uploadFileBds`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateBds = async (formData) => {
  try {
    const res = await postUpload(`/updatebds`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addCmt = async (formData) => {
  try {
    const res = await postUpload(`/addcmt`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listCmt = async (id) => {
  try {
    const res = await get(`/listcmt?code=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const countCmt = async () => {
  try {
    const res = await getSV(`/countcmt`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getIdBds = async (id) => {
  try {
    const res = await get(`/getidbds?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductIDAllUser = async (page, stt, username, masp) => {
  let userName = username ? `&username=${username}` : '';
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let size = 50;
  try {
    const res = await getSV(`/listbdsuser?page=${page}${isStatus}${userName}${maSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listProductIDAllCountUser = async (stt, username, masp) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  try {
    const res = await getSV(`/listbdsusercount?username=${username}${isStatus}${maSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductIDAll = async (page, stt, masp) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let size = 50;
  try {
    const res = await getSV(`/listbdsall?page=${page}${isStatus}${maSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listProductIDShow = async (
  page,
  stt,
  masp,
  text,
  loaibds,
  xa,
  huyen,
  sdt,
  soto,
  sothua,
  loaivitri,
  dientich,
  giaban,
  huongbds,
  sotang,
  sophong,
  ngang,
  trangthai,
  username,
  loaidangtin,
  tiennghi,
  dactrung,
  phaply,
  quyhoach,
  ngaydang,
  capnhat,
  duong,
) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let textSP = text ? `&q=${text}` : '';
  let loaibdsSP = loaibds ? `&loaibds=${loaibds}` : '';
  let xaSP = xa ? `&xa=${xa}` : '';
  let huyenSP = huyen ? `&huyen=${huyen}` : '';
  let sdtSP = sdt ? `&sdt=${sdt}` : '';
  let sotoSP = soto ? `&soto=${soto}` : '';
  let sothuaSP = sothua ? `&sothua=${sothua}` : '';
  let loaivitriSP = loaivitri ? `&loaivitri=${loaivitri}` : '';
  let dientichSP = dientich?.split(',')[1] ? `&dientich=${dientich}` : '';
  let giabanSP = giaban?.split(',')[1] ? `&giaban=${giaban}` : '';
  let huongbdsSP = huongbds ? `&huongbds=${huongbds}` : '';
  let sotangSP = sotang?.split(',')[1] ? `&sotang=${sotang}` : '';
  let sophongSP = sophong?.split(',')[1] ? `&sophong=${sophong}` : '';
  let ngangSP = ngang?.split(',')[1] ? `&ngang=${ngang}` : '';
  let trangthaiSP = trangthai ? `&trangthai=${trangthai}` : '';
  let usernameSP = username ? `&username=${username}` : '';
  let loaidangtinSP = loaidangtin ? `&loaidangtin=${loaidangtin}` : '';
  let tiennghiSP = tiennghi ? `&tiennghi=${tiennghi}` : '';
  let dactrungSP = dactrung ? `&dactrung=${dactrung}` : '';
  let phaplySP = phaply ? `&phaply=${phaply}` : '';
  let quyhoachSP = quyhoach ? `&quyhoach=${quyhoach}` : '';
  let ngaydangSP = ngaydang?.split(',')[1] ? `&ngaydang=${ngaydang}` : '';
  let capnhatSP = capnhat?.split(',')[1] ? `&capnhat=${capnhat}` : '';
  let duongSP = duong ? `&duong=${duong}` : '';
  let size = '&size=2';
  try {
    const res = await getSV(
      `/listbdsshow?page=${page}${isStatus}${maSP}${textSP}${loaibdsSP}${xaSP}${huyenSP}${sdtSP}${sotoSP}${sothuaSP}${loaivitriSP}${dientichSP}${giabanSP}${huongbdsSP}${sotangSP}${sophongSP}${ngangSP}${trangthaiSP}${usernameSP}${loaidangtinSP}${tiennghiSP}${dactrungSP}${phaplySP}${quyhoachSP}${ngaydangSP}${capnhatSP}${duongSP}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listProductIDAllShowCount = async (
  stt,
  masp,
  text,
  loaibds,
  xa,
  huyen,
  sdt,
  soto,
  sothua,
  loaivitri,
  dientich,
  giaban,
  huongbds,
  sotang,
  sophong,
  ngang,
  trangthai,
  username,
  loaidangtin,
  tiennghi,
  dactrung,
  phaply,
  quyhoach,
  ngaydang,
  capnhat,
  duong,
) => {
  let maSP = masp ? `&code=${masp}` : '';
  let textSP = text ? `&q=${text}` : '';
  let loaibdsSP = loaibds ? `&loaibds=${loaibds}` : '';
  let xaSP = xa ? `&xa=${xa}` : '';
  let huyenSP = huyen ? `&huyen=${huyen}` : '';
  let sdtSP = sdt ? `&sdt=${sdt}` : '';
  let sotoSP = soto ? `&soto=${soto}` : '';
  let sothuaSP = sothua ? `&sothua=${sothua}` : '';
  let loaivitriSP = loaivitri ? `&loaivitri=${loaivitri}` : '';
  let dientichSP = dientich?.split(',')[1] ? `&dientich=${dientich}` : '';
  let giabanSP = giaban?.split(',')[1] ? `&giaban=${giaban}` : '';
  let huongbdsSP = huongbds ? `&huongbds=${huongbds}` : '';
  let sotangSP = sotang?.split(',')[1] ? `&sotang=${sotang}` : '';
  let sophongSP = sophong?.split(',')[1] ? `&sophong=${sophong}` : '';
  let ngangSP = ngang?.split(',')[1] ? `&ngang=${ngang}` : '';
  let trangthaiSP = trangthai ? `&trangthai=${trangthai}` : '';
  let usernameSP = username ? `&username=${username}` : '';
  let loaidangtinSP = loaidangtin ? `&loaidangtin=${loaidangtin}` : '';
  let tiennghiSP = tiennghi ? `&tiennghi=${tiennghi}` : '';
  let dactrungSP = dactrung ? `&dactrung=${dactrung}` : '';
  let phaplySP = phaply ? `&phaply=${phaply}` : '';
  let quyhoachSP = quyhoach ? `&quyhoach=${quyhoach}` : '';
  let ngaydangSP = ngaydang?.split(',')[1] ? `&ngaydang=${ngaydang}` : '';
  let capnhatSP = capnhat?.split(',')[1] ? `&capnhat=${capnhat}` : '';
  let duongSP = duong ? `&duong=${duong}` : '';
  try {
    const res = await getSV(
      `/listbdsallshowcount?is=${stt}${maSP}${textSP}${loaibdsSP}${xaSP}${huyenSP}${sdtSP}${sotoSP}${sothuaSP}${loaivitriSP}${dientichSP}${giabanSP}${huongbdsSP}${sotangSP}${sophongSP}${ngangSP}${trangthaiSP}${usernameSP}${loaidangtinSP}${tiennghiSP}${dactrungSP}${phaplySP}${quyhoachSP}${ngaydangSP}${capnhatSP}${duongSP}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listProductIDAllCount = async (stt, masp) => {
  let maSP = masp ? `&code=${masp}` : '';
  try {
    const res = await getSV(`/listbdsallcount?is=${stt}${maSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductIDShowDaxemDetail = async (
  page,
  stt,
  username,
  masp,
  text,
  loaibds,
  xa,
  huyen,
  sdt,
  soto,
  sothua,
  loaivitri,
  dientich,
  giaban,
  huongbds,
  sotang,
  sophong,
  ngang,
  trangthai,
  loaidangtin,
  tiennghi,
  dactrung,
  phaply,
  quyhoach,
  ngaydang,
  capnhat,
  duong,
) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let userName = username ? `&username=${username}` : '';
  let textSP = text ? `&q=${text}` : '';
  let loaibdsSP = loaibds ? `&loaibds=${loaibds}` : '';
  let xaSP = xa ? `&xa=${xa}` : '';
  let huyenSP = huyen ? `&huyen=${huyen}` : '';
  let sdtSP = sdt ? `&sdt=${sdt}` : '';
  let sotoSP = soto ? `&soto=${soto}` : '';
  let sothuaSP = sothua ? `&sothua=${sothua}` : '';
  let loaivitriSP = loaivitri ? `&loaivitri=${loaivitri}` : '';
  let dientichSP = dientich?.split(',')[1] ? `&dientich=${dientich}` : '';
  let giabanSP = giaban?.split(',')[1] ? `&giaban=${giaban}` : '';
  let huongbdsSP = huongbds ? `&huongbds=${huongbds}` : '';
  let sotangSP = sotang?.split(',')[1] ? `&sotang=${sotang}` : '';
  let sophongSP = sophong?.split(',')[1] ? `&sophong=${sophong}` : '';
  let ngangSP = ngang?.split(',')[1] ? `&ngang=${ngang}` : '';
  let trangthaiSP = trangthai ? `&trangthai=${trangthai}` : '';
  let loaidangtinSP = loaidangtin ? `&loaidangtin=${loaidangtin}` : '';
  let tiennghiSP = tiennghi ? `&tiennghi=${tiennghi}` : '';
  let dactrungSP = dactrung ? `&dactrung=${dactrung}` : '';
  let phaplySP = phaply ? `&phaply=${phaply}` : '';
  let quyhoachSP = quyhoach ? `&quyhoach=${quyhoach}` : '';
  let ngaydangSP = ngaydang?.split(',')[1] ? `&ngaydang=${ngaydang}` : '';
  let capnhatSP = capnhat?.split(',')[1] ? `&capnhat=${capnhat}` : '';
  let duongSP = duong ? `&duong=${duong}` : '';
  let size = '&size=2';
  try {
    const res = await getSV(
      `/listbdssdaxem?page=${page}${isStatus}${userName}${maSP}${textSP}${loaibdsSP}${xaSP}${huyenSP}${sdtSP}${sotoSP}${sothuaSP}${loaivitriSP}${dientichSP}${giabanSP}${huongbdsSP}${sotangSP}${sotangSP}${sophongSP}${ngangSP}${trangthaiSP}${loaidangtinSP}${tiennghiSP}${dactrungSP}${phaplySP}${quyhoachSP}${ngaydangSP}${capnhatSP}${duongSP}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductIDShowDaxem = async (
  page,
  stt,
  username,
  masp,
  text,
  loaibds,
  xa,
  huyen,
  sdt,
  soto,
  sothua,
  loaivitri,
  dientich,
  giaban,
  huongbds,
  sotang,
  sophong,
  ngang,
  trangthai,
  loaidangtin,
  tiennghi,
  dactrung,
  phaply,
  quyhoach,
  ngaydang,
  capnhat,
  duong,
) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let userName = username ? `&username=${username}` : '';
  let textSP = text ? `&q=${text}` : '';
  let loaibdsSP = loaibds ? `&loaibds=${loaibds}` : '';
  let xaSP = xa ? `&xa=${xa}` : '';
  let huyenSP = huyen ? `&huyen=${huyen}` : '';
  let sdtSP = sdt ? `&sdt=${sdt}` : '';
  let sotoSP = soto ? `&soto=${soto}` : '';
  let sothuaSP = sothua ? `&sothua=${sothua}` : '';
  let loaivitriSP = loaivitri ? `&loaivitri=${loaivitri}` : '';
  let dientichSP = dientich?.split(',')[1] ? `&dientich=${dientich}` : '';
  let giabanSP = giaban?.split(',')[1] ? `&giaban=${giaban}` : '';
  let huongbdsSP = huongbds ? `&huongbds=${huongbds}` : '';
  let sotangSP = sotang?.split(',')[1] ? `&sotang=${sotang}` : '';
  let sophongSP = sophong?.split(',')[1] ? `&sophong=${sophong}` : '';
  let ngangSP = ngang?.split(',')[1] ? `&ngang=${ngang}` : '';
  let trangthaiSP = trangthai ? `&trangthai=${trangthai}` : '';
  let loaidangtinSP = loaidangtin ? `&loaidangtin=${loaidangtin}` : '';
  let tiennghiSP = tiennghi ? `&tiennghi=${tiennghi}` : '';
  let dactrungSP = dactrung ? `&dactrung=${dactrung}` : '';
  let phaplySP = phaply ? `&phaply=${phaply}` : '';
  let quyhoachSP = quyhoach ? `&quyhoach=${quyhoach}` : '';
  let ngaydangSP = ngaydang?.split(',')[1] ? `&ngaydang=${ngaydang}` : '';
  let capnhatSP = capnhat?.split(',')[1] ? `&capnhat=${capnhat}` : '';
  let duongSP = duong ? `&duong=${duong}` : '';
  let size = '&size=20';
  try {
    const res = await getSV(
      `/listbdssdaxem?page=${page}${size}${isStatus}${userName}${maSP}${textSP}${loaibdsSP}${xaSP}${huyenSP}${sdtSP}${sotoSP}${sothuaSP}${loaivitriSP}${dientichSP}${giabanSP}${huongbdsSP}${sotangSP}${sotangSP}${sophongSP}${ngangSP}${trangthaiSP}${loaidangtinSP}${tiennghiSP}${dactrungSP}${phaplySP}${quyhoachSP}${ngaydangSP}${capnhatSP}${duongSP}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listProductIDAllDaxemCount = async (
  stt,
  username,
  masp,
  text,
  loaibds,
  xa,
  huyen,
  sdt,
  soto,
  sothua,
  loaivitri,
  dientich,
  giaban,
  huongbds,
  sotang,
  sophong,
  ngang,
  trangthai,
  loaidangtin,
  tiennghi,
  dactrung,
  phaply,
  quyhoach,
  ngaydang,
  capnhat,
  duong,
) => {
  let maSP = masp ? `&code=${masp}` : '';
  let userName = username ? `&username=${username}` : '';
  let textSP = text ? `&q=${text}` : '';
  let loaibdsSP = loaibds ? `&loaibds=${loaibds}` : '';
  let xaSP = xa ? `&xa=${xa}` : '';
  let huyenSP = huyen ? `&huyen=${huyen}` : '';
  let sdtSP = sdt ? `&sdt=${sdt}` : '';
  let sotoSP = soto ? `&soto=${soto}` : '';
  let sothuaSP = sothua ? `&sothua=${sothua}` : '';
  let loaivitriSP = loaivitri ? `&loaivitri=${loaivitri}` : '';
  let dientichSP = dientich?.split(',')[1] ? `&dientich=${dientich}` : '';
  let giabanSP = giaban?.split(',')[1] ? `&giaban=${giaban}` : '';
  let huongbdsSP = huongbds ? `&huongbds=${huongbds}` : '';
  let sotangSP = sotang?.split(',')[1] ? `&sotang=${sotang}` : '';
  let sophongSP = sophong?.split(',')[1] ? `&sophong=${sophong}` : '';
  let ngangSP = ngang?.split(',')[1] ? `&ngang=${ngang}` : '';
  let trangthaiSP = trangthai ? `&trangthai=${trangthai}` : '';
  let loaidangtinSP = loaidangtin ? `&loaidangtin=${loaidangtin}` : '';
  let tiennghiSP = tiennghi ? `&tiennghi=${tiennghi}` : '';
  let dactrungSP = dactrung ? `&dactrung=${dactrung}` : '';
  let phaplySP = phaply ? `&phaply=${phaply}` : '';
  let quyhoachSP = quyhoach ? `&quyhoach=${quyhoach}` : '';
  let ngaydangSP = ngaydang?.split(',')[1] ? `&ngaydang=${ngaydang}` : '';
  let capnhatSP = capnhat?.split(',')[1] ? `&capnhat=${capnhat}` : '';
  let duongSP = duong ? `&duong=${duong}` : '';
  try {
    const res = await getSV(
      `/listbdsdaxemcount?is=${stt}${userName}${maSP}${textSP}${loaibdsSP}${xaSP}${huyenSP}${sdtSP}${sotoSP}${sothuaSP}${loaivitriSP}${dientichSP}${giabanSP}${huongbdsSP}${sotangSP}${sotangSP}${sophongSP}${ngangSP}${trangthaiSP}${loaidangtinSP}${tiennghiSP}${dactrungSP}${phaplySP}${quyhoachSP}${ngaydangSP}${capnhatSP}${duongSP}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductTodayCount = async () => {
  try {
    const res = await getSV(`/listbdstodaycount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const countTinLienQuan = async (
  duong,
  huyen,
  giastart,
  giaend,
  dientichstart,
  dientichend,
  loaibds,
  loaivitri,
) => {
  try {
    const res = await getSV(
      `/banggia?duong=${duong}&huyen=${huyen}&giastart=${giastart}&giaend=${giaend}&dientichstart=${dientichstart}&dientichend=${dientichend}&loaibds=${loaibds}&loaivitri=${loaivitri}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const checkPhoneChuDat = async (data) => {
  try {
    const res = await postSV(`/checkphonedat`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const countThongKeViewSo = async (data) => {
  try {
    const res = await postSV(`/totaltkview`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const countThongKeNewFee = async () => {
  try {
    const res = await getSV(`/totaltkviews`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const countThongKe = async (date, username) => {
  try {
    const res = await getSV(`/totaltk?username=${username}&date=${date}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
