import { useEffect, useState } from 'react';
import { getCookie } from '../../../utils/helper';
import { listAllYeuthich, listCountAllYeuthich } from '../../../services/apiYeuthich';
import FrameShowBds from '../../../components/FrameShowBds';
import { Button, IconButton } from '@material-tailwind/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

function Yeuthich() {
  const auth = getCookie('token');
  const [resultYT, setResultYT] = useState([]);
  const [countDefault, setCountDefault] = useState([]);
  const [page, setPage] = useState(1);

  const listAll = async (pages = 1) => {
    const resultAPI = await listAllYeuthich(pages, '', auth?.id);
    if (resultAPI.length > 0) {
      setResultYT(resultAPI);
      const count = await listCountAllYeuthich(auth?.id);
      setCountDefault(count.totalRows);
    }
  };

  useEffect(() => {
    listAll(1);
  }, []);

  const getItemProps = (index) => ({
    variant: page === index ? 'filled' : 'text',
    color: 'gray',
    onClick: () => {
      setPage(index);
      listAll(index);
      scrollToTop();
    },
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const next = () => {
    if (page === countDefault) return;

    const total = Math.ceil(countDefault / 50);
    if (total > page) {
      setPage(page + 1);
      listAll(page + 1);
      scrollToTop();
    }
  };

  const prev = () => {
    if (page === countDefault) return;

    const total = Math.ceil(countDefault / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listAll(page - 1);
      scrollToTop();
    }
  };

  const pagesToShow = 5;
  const totalNumbers = Array.from(new Array(Math.ceil(countDefault / 50)), (val, index) => index + 1);

  const firstPage = Math.max(1, page - Math.floor(pagesToShow / 2));
  const lastPage = Math.min(Math.ceil(countDefault / 50), firstPage + pagesToShow - 1);

  const prevPages = firstPage > 1 ? [1, '...'] : [];
  const nextPages = lastPage < Math.ceil(countDefault / 50) ? ['...', Math.ceil(countDefault / 50)] : [];

  const pageNumbers = [...prevPages, ...totalNumbers.slice(firstPage - 1, lastPage), ...nextPages];
  return (
    <div className="container m-auto">
      <h1 className="text-red-800 font-bold text-xl lg:text-2xl mb-4">Danh Sách Yêu Thích của Bạn</h1>
      <div className="grid">
        {resultYT.length > 0 &&
          resultYT?.map((row, i) => (
            <div key={i}>
              {' '}
              <FrameShowBds data={row} yeuthich={resultYT} />
            </div>
          ))}
        <hr className="my-4 border-blue-gray-50" />
        <div className="flex items-center justify-center">
          <div>
            <Button variant="text" size="sm" className="flex items-center gap-1" onClick={prev} disabled={page === 1}>
              <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Trước
            </Button>
          </div>
          <div className="flex">
            {pageNumbers.map((number, index) =>
              number !== '...' ? (
                <IconButton key={index} {...getItemProps(number)} size="sm">
                  {number}
                </IconButton>
              ) : (
                <IconButton key={index} variant="text" size="sm">
                  {number}
                </IconButton>
              ),
            )}
          </div>

          <div>
            <Button
              variant="text"
              size="sm"
              className="flex items-center gap-1"
              onClick={next}
              disabled={page === Math.ceil(countDefault / 50)}
            >
              Sau
              <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Yeuthich;
