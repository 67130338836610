import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Input,
  Tooltip,
  Typography,
} from '@material-tailwind/react';
import { getCookie } from '../../../../utils/helper';
import { useEffect, useState } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  addDactrung,
  listAllDactrung,
  listCountAllDactrung,
  listIdDactrung,
  removeDactrung,
  updateDactrung,
} from '../../../../services/apiCategory';

const TABLE_HEAD = ['Đặc Trưng', 'Tác vụ'];

export default function Dactrung() {
  const auth = getCookie('token');
  const [resultList, setResultList] = useState([]);
  const [arrDefault, setArrDefault] = useState({ id_user: auth?.id, name: '' });
  const [isDisabled, setIsDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [page, setPage] = useState(1);
  const [countDefault, setCountDefault] = useState(0);
  const [checkInfo, setCheckInfo] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const idCate = urlParams.get('u');
  const navigate = useNavigate();

  const listAll = async (pages = 1) => {
    const resultAPI = await listAllDactrung(pages);
    if (resultAPI.length > 0) {
      setResultList(resultAPI);
      const count = await listCountAllDactrung();
      setCountDefault(count.totalRows);
    }
  };

  useEffect(() => {
    document.title = 'Danh mục Đặc Trưng';
    listAll(1);
  }, []);

  useEffect(() => {
    getListID(idCate);
  }, [idCate]);

  const getListID = async (id) => {
    if (id) {
      const resultID = await listIdDactrung(idCate);
      if (resultID.length > 0) {
        setCheckInfo(true);
        setArrDefault({
          id_user: auth?.id,
          name: resultID[0].name,
        });
      } else {
        setCheckInfo(false);
      }
    }
  };

  const changeValueAdmin = (jsonVL) => {
    setArrDefault({ ...arrDefault, ...jsonVL });
  };

  const subAddDefault = async () => {
    setIsDisabled(true);
    const resultAPI = await addDactrung(arrDefault);
    if (resultAPI.message === 'OK') {
      setIsDisabled(false);
      listAll(1);
      setMessage('Thành Công');
      setOpenAlert(true);
      setArrDefault({ id_user: auth?.id, name: '' });
    } else {
      setIsDisabled(false);
      setMessage('Thất Bại');
      setOpenAlert(true);
    }
  };
  const subUpdateDefault = async () => {
    setIsDisabled(true);
    const body = {
      id: idCate,
      data: arrDefault,
    };
    const resultAPI = await updateDactrung(body);
    if (resultAPI.message === 'OK') {
      setIsDisabled(false);
      listAll(1);
      navigate('/dactrung');
      setMessage('Thành Công');
      setOpenAlert(true);
      setArrDefault({ id_user: auth?.id, name: '' });
      setCheckInfo(false);
    } else {
      setIsDisabled(false);
      setMessage('Thất Bại');
      setOpenAlert(true);
    }
  };

  const alertRemove = async (id) => {
    if (window.confirm(`Bạn đã chắc chắn XÓA dữ liệu này?`)) {
      // Save it!
      const body = {
        id: id,
      };
      const resultAPI = await removeDactrung(body);
      if (resultAPI?.message === 'OK') {
        listAll(1);
      }
    } else {
      // Do nothing!
      console.log('Hủy yêu cầu');
    }
  };

  const nextPage = () => {
    const total = Math.ceil(countDefault / 50);
    if (total > page) {
      setPage(page + 1);
      listAll(page + 1);
    }
  };

  const prevPage = () => {
    const total = Math.ceil(countDefault / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listAll(page - 1);
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div>
        <Card className="p-2">
          <h3 className="font-bold mb-1">THÊM Đặc Trưng</h3>
          <Alert
            color={message === 'Thành Công' ? 'green' : 'red'}
            className="mb-2"
            open={openAlert}
            onClose={() => setOpenAlert(false)}
          >
            {message === 'Thành Công' ? 'Cập Nhật Thành Công' : 'Cập Nhật Thất Bại'}
          </Alert>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
            <div className="col-span-2">
              <Input
                value={arrDefault?.name}
                label="Tên Đặc Trưng"
                placeholder="Nhập tên Đặc Trưng"
                required
                onChange={(e) => {
                  changeValueAdmin({ name: e.target.value });
                }}
              />
            </div>
            <div>
              <Button
                className="bg-gradient-to-r from-orange-400 to-orange-900"
                onClick={checkInfo ? subUpdateDefault : subAddDefault}
                disabled={isDisabled}
              >
                {checkInfo ? 'CẬP NHẬT' : 'LƯU THÊM MỚI'}
              </Button>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="h-full w-full">
          <CardBody className="overflow-auto px-0 p-1 rounded-3xl">
            <table className="w-full min-w-max table-auto">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                      <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {resultList.map((row, index) => {
                  const isLast = index === resultList.length - 1;
                  const classes = isLast ? 'p-2 text-center' : 'p-2 border-b border-blue-gray-50 text-center';

                  return (
                    <tr key={index}>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {row.name}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Tooltip content="Sửa">
                          <Link to={`/dactrung?u=${row.id}`}>
                            <IconButton variant="text">
                              <PencilIcon className="h-4 w-4" />
                            </IconButton>
                          </Link>
                        </Tooltip>

                        <Tooltip content={`Xóa`}>
                          <IconButton variant="text" onClick={() => alertRemove(row.id)}>
                            <TrashIcon className={`h-4 w-4 text-red-500`} />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
          {Math.ceil(countDefault / 50) > page && (
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
              <Typography variant="small" color="blue-gray" className="font-normal">
                Page {page} of {Math.ceil(countDefault / 50)}
              </Typography>
              <div className="flex gap-2">
                <Button variant="outlined" size="sm" onClick={prevPage}>
                  Trang Trước
                </Button>
                <Button variant="outlined" size="sm" onClick={nextPage}>
                  Trang Tiếp
                </Button>
              </div>
            </CardFooter>
          )}
        </Card>
      </div>
    </div>
  );
}
