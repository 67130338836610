import { get, getSV, post, postSV, postUpload } from '../utils/https';

//API THÊM USER
export const addUsers = async (data) => {
  try {
    const res = await postSV(`/adduser`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listUserID = async (id) => {
  try {
    const res = await getSV(`/listuserid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const checkUserNames = async (name) => {
  try {
    const res = await getSV(`/checkuser?name=${name}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listUserIDAll = async (ishd, username, page) => {
  let userName = username ? `&name=${username}` : '';
  let isStatus = ishd ? `&ishd=${ishd}` : '';
  let size = 50;
  try {
    const res = await getSV(`/listuserall?page=${page}${isStatus}${userName}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listUserIDAllCount = async (ishd, username) => {
  let userName = username ? `&name=${username}` : '';
  let isStatus = ishd ? `ishd=${ishd}` : '';
  try {
    const res = await getSV(`/listuserallcount?${isStatus}${userName}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateImageLogo = async (formData) => {
  try {
    const res = await postUpload(`/uploadavt`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserID = async (data) => {
  try {
    const res = await postSV(`/updateuserid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateWifis = async (wifi) => {
  try {
    const res = await postSV(`/updatewf`, wifi);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// check login
export const checkLoginUsers = async (data) => {
  try {
    const res = await post(`/login`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
// refresh token
export const refreshToken = async (data) => {
  try {
    const res = await postSV(`/refresh`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
