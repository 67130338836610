const routes = {
  home: '/',
  homeBangGia: '/bang-gia-m2',
  homeDaXem: '/bds-da-xem',
  homeListYeuThich: '/yeu-thich',
  homeCogihot: '/co-gi-moi',
  checkRouters: '/:id',
  dashboard: '/dashboard',
  dashboardLogin: '/login',
  dashboardUser: '/user',
  dashboardUsers: '/users',
  dashboardDsUsers: '/dsusers',
  dashboardChangePass: '/changepass',
  dashboardThongKe: '/thongke',
  dashboardWifi: '/wifi',
  //Danh Mục
  dashboardDiadiem: '/diadiem',
  dashboardKQH: '/khuquyhoach',
  dashboardQH: '/quyhoach',
  dashboardPhaply: '/phaply',
  dashboardHuongbds: '/huongbds',
  dashboardVitri: '/vitri',
  dashboardLoaiBDS: '/loaibds',
  dashboardTiennghi: '/tiennghi',
  dashboardDactrung: '/dactrung',
  //BĐS
  dashboardAddBDS: '/addbds',
  dashboardDsBDS: '/dsbds',
  dashboardDsDuyetBDS: '/dsduyetbds',
  dashboardBDSDetail: '/bds/:id',
};

export default routes;
