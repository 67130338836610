import { Link, useLocation, useNavigate } from 'react-router-dom';
import { countTinLienQuan } from '../../../services/apiBds';
import { useEffect, useState } from 'react';
import { Button, Card, Input, Typography } from '@material-tailwind/react';
import { convertNumberToWords, encode, numberFormat, numberFormatDeleteDots } from '../../../utils/helper';
import { listAllLoaibds, listAllStreets, listAllVitri } from '../../../services/apiCategory';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';

function Banggia() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const lbs = urlParams.get('lbs') || '';
  const lvt = urlParams.get('lvt') || '';
  const duong = urlParams.get('d') || '';
  const huyen = urlParams.get('h') || '';
  const gia = urlParams.get('gia') / 1000000000 || '';
  const dt = urlParams.get('dt') || '';
  const navigate = useNavigate();

  const [resultBG, setResultBG] = useState([]);
  const [open, setOpen] = useState(0);
  const [objBoLoc, setObjBoLoc] = useState({
    duong: duong,
    huyen: huyen,
    gia: gia && !isNaN(gia) ? gia : '',
    dt: dt,
    loaibds: lbs,
    loaivitri: lvt,
  });
  const [arrOptionSelectSearch, setArrOptionSelectSearch] = useState({});
  const [arrOptionSelect, setArrOptionSelect] = useState({ streets: [], loaivitri: [], loaibds: [] });

  const countTinLienQuans = async (duong, huyen, gia, dientich, loaibds, loaivitri) => {
    let giastart = gia && !isNaN(gia) ? gia && gia * 0.8 : '';
    let giaend = gia && !isNaN(gia) ? gia && gia / 0.8 : '';
    let dientichstart = dientich && dientich * 0.8;
    let dientichend = dientich && dientich / 0.8;
    const result = await countTinLienQuan(
      duong,
      huyen,
      giastart,
      giaend,
      dientichstart,
      dientichend,
      loaibds,
      loaivitri,
    );
    if (result.length > 0) {
      setResultBG(result);
    } else {
      setResultBG([]);
    }
  };
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  useEffect(() => {
    if (duong) {
      const giaSP = gia ? gia * 1000000000 : '';
      countTinLienQuans(duong, huyen, giaSP, dt, lbs, lvt);
    }
    getStreets();
  }, []);

  const handleSearchOptionSelect = (key, val, keysearch) => {
    if (val) {
      const results = arrOptionSelect[key].filter((item) => item[keysearch].toLowerCase().includes(val.toLowerCase()));
      setArrOptionSelectSearch({ ...arrOptionSelect, [key]: results });
    } else {
      setArrOptionSelectSearch({ ...arrOptionSelect, [key]: arrOptionSelect[key] });
    }
  };

  const getStreets = async () => {
    const resultAPI = await listAllStreets(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, streets: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, streets: resultAPI });
    }
  };

  const getLoaiVitri = async () => {
    const resultAPI = await listAllVitri(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, loaivitri: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, loaivitri: resultAPI });
    }
  };

  const getLoaiBDS = async () => {
    const resultAPI = await listAllLoaibds(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, loaibds: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, loaibds: resultAPI });
    }
  };

  const handleSubSelect = (duong = '', huyen = '', gia = '', dt = '', loaibds = '', loaivitri = '') => {
    navigate(`/bang-gia-m2?lbs=${loaibds}&lvt=${loaivitri}&d=${duong}&h=${huyen}&gia=${gia ? gia * 1000000000 : ''}`, {
      replace: true,
    });
    countTinLienQuans(duong, huyen, gia * 1000000000, dt, loaibds, loaivitri);
  };

  const handleSub = (e) => {
    if (e.key === 'Enter') {
      const giaSP = objBoLoc?.gia ? objBoLoc?.gia * 1000000000 : '';
      navigate(
        `/bang-gia-m2?lbs=${objBoLoc?.loaibds}&lvt=${objBoLoc?.loaivitri}&d=${objBoLoc?.duong}&h=${objBoLoc?.huyen}&gia=${giaSP}`,
        { replace: true },
      );
      countTinLienQuans(objBoLoc?.duong, objBoLoc?.huyen, giaSP, objBoLoc?.dt, objBoLoc?.loaibds, objBoLoc?.loaivitri);
    }
  };
  return (
    <div className="container m-auto">
      <div>
        {resultBG.length > 0 && (
          <div>
            {duong && (
              <h1 className="text-blue-800 font-bold text-xl lg:text-2xl mb-2">
                #{`${duong && duong}${huyen ? `,${huyen}` : ''}${lbs ? ` - ${lbs}` : ''}${lvt ? ` - ${lvt}` : ''}`}
              </h1>
            )}
            {gia && (
              <p className="font-bold text-gray-800">
                Giá khoảng: {gia && convertNumberToWords(gia * 1000000000 * 0.8)} -{' '}
                {gia && convertNumberToWords((gia * 1000000000) / 0.8)}
              </p>
            )}
            {objBoLoc.dt && (
              <p className="font-bold text-gray-800  mb-4">
                Giá m2 khoảng: {gia && convertNumberToWords(parseInt(gia * 0.8) / parseInt(dt * 0.8))}
              </p>
            )}
          </div>
        )}
        {/* <p className="font-bold mb-4 text-gray-800">
        Diện tích khoảng: {dt && Math.ceil(dt * 0.8)}m2 - {dt && Math.ceil(dt / 0.8)}m2
      </p> */}
        <Card className="h-full w-full overflow-scroll">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2">
                  <div className="static">
                    <Input
                      value={objBoLoc.duong}
                      placeholder="Tên đường"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 "
                      labelProps={{
                        className: 'hidden',
                      }}
                      onChange={(e) => {
                        handleSearchOptionSelect('streets', e.target.value, 'duong');
                        setOpen(e.target.value ? 1 : 0);
                        setObjBoLoc({ ...objBoLoc, duong: e.target.value });
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                    />

                    {open === 1 && (
                      <div className="border rounded-lg fixed z-[99999] bg-white shadow-2xl mt-1 h-[150px] overflow-scroll">
                        {arrOptionSelectSearch?.streets &&
                          arrOptionSelectSearch?.streets.map((row, index) => (
                            <div
                              className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer"
                              key={index}
                              onClick={() => {
                                setObjBoLoc({ ...objBoLoc, duong: row?.duong, huyen: row?.huyen });
                                handleOpen(0);
                                handleSubSelect(
                                  row?.duong,
                                  row?.huyen,
                                  objBoLoc?.gia,
                                  objBoLoc?.dt,
                                  objBoLoc?.loaibds,
                                  objBoLoc?.loaivitri,
                                );
                              }}
                            >
                              {row?.duong},{row?.xa},{row?.huyen}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </th>

                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2">
                  <div className="relative">
                    <select
                      value={objBoLoc.loaibds}
                      onChange={(e) => {
                        setObjBoLoc({ ...objBoLoc, loaibds: e.target.value });
                        handleSubSelect(
                          objBoLoc?.duong,
                          objBoLoc?.huyen,
                          objBoLoc?.gia,
                          objBoLoc?.dt,
                          e.target.value,
                          objBoLoc?.loaivitri,
                        );
                      }}
                      onClick={getLoaiBDS}
                      className="w-full border-gray-400 bg-white rounded-lg bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                    >
                      <option value="">{lbs && arrOptionSelect?.loaibds?.length === 0 ? lbs : 'Chọn Loại BĐS'}</option>
                      {arrOptionSelect?.loaibds?.length > 0 &&
                        arrOptionSelect?.loaibds.map((row, i) => (
                          <option key={i} value={row.name}>
                            {row.name}
                          </option>
                        ))}
                    </select>
                    <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                  </div>
                </th>

                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2">
                  <div className="relative">
                    <select
                      value={objBoLoc.loaivitri}
                      onChange={(e) => {
                        setObjBoLoc({ ...objBoLoc, loaivitri: e.target.value });
                        handleSubSelect(
                          objBoLoc?.duong,
                          objBoLoc?.huyen,
                          objBoLoc?.gia,
                          objBoLoc?.dt,
                          objBoLoc?.loaibds,
                          e.target.value,
                        );
                      }}
                      onClick={getLoaiVitri}
                      className="w-full border-gray-400 bg-white rounded-lg bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                    >
                      <option value="">{lvt && arrOptionSelect?.loaivitri?.length === 0 ? lvt : 'Chọn Vị Trí'}</option>
                      {arrOptionSelect?.loaivitri?.length > 0 &&
                        arrOptionSelect?.loaivitri.map((row, i) => (
                          <option key={i} value={row.name}>
                            {row.name}
                          </option>
                        ))}
                    </select>
                    <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                  </div>
                </th>
                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    DT (m2)
                  </Typography>
                </th>
                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Giá M2
                  </Typography>
                </th>
                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2 flex items-center">
                  <Input
                    value={objBoLoc.gia ? objBoLoc.gia : ''}
                    placeholder="Giá bán"
                    className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                    labelProps={{
                      className: 'hidden',
                    }}
                    onChange={(e) => {
                      setObjBoLoc({ ...objBoLoc, gia: e.target.value });
                    }}
                    containerProps={{
                      className: 'min-w-[0px!important]',
                    }}
                    onKeyPress={handleSub}
                  />
                  {objBoLoc.gia > 0 && (
                    <b className="text-red-600 text-sm whitespace-nowrap">
                      {convertNumberToWords(objBoLoc.gia * 1000000000)}
                    </b>
                  )}
                </th>
                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    Ngày cập nhật
                  </Typography>
                </th>
                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2"></th>
              </tr>
            </thead>
            {resultBG.length > 0 && (
              <tbody>
                {resultBG?.map((row, index) => (
                  <tr key={index} className="even:bg-blue-gray-50/50">
                    <td className="p-4">
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {row.duong}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {row.loaibds}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {row.loaivitri}
                      </Typography>
                    </td>
                    <td className="p-4 ">
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {row.dientich}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {row.dientich && convertNumberToWords(parseInt(row.gia) / parseInt(row.dientich))}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {convertNumberToWords(row.gia ? row.gia : '')}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {row.update_date}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Link to={`/bds/${encode(row.id)}`} target="_blank">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-blue-500 cursor-pointer"
                        >
                          chi tiết
                        </Typography>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </Card>
      </div>
    </div>
  );
}

export default Banggia;
