import {
  ClipboardDocumentCheckIcon,
  PencilSquareIcon,
  PhotoIcon,
  ShieldExclamationIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { Alert, Button, Card, IconButton, Input, Option, Select, Typography } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { getCookie } from '../../../utils/helper';
import { addUsers, checkUserNames, listUserID, updateUserID } from '../../../services/apiUsers';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Users() {
  const auth = getCookie('token');
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const idUser = urlParams.get('u');
  const [resultAdminID, setResultAdminID] = useState({
    permission: 4,
    username: '',
    password: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    status: 1,
  });
  const [message, setMessage] = useState('');
  const [isCheckUserName, setIsCheckUserName] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [checkInfo, setCheckInfo] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const changeValueAdmin = (jsonVL) => {
    setResultAdminID({ ...resultAdminID, ...jsonVL });
  };

  useEffect(() => {
    document.title = 'Thông tin cá nhân';
    getUserID(idUser);
  }, [idUser]);

  const getUserID = async (idUser) => {
    if (idUser) {
      const resultUserID = await listUserID(idUser);
      if (resultUserID.length > 0) {
        setCheckInfo(true);
        setResultAdminID({
          permission: resultUserID[0].permission,
          username: resultUserID[0].username,
          name: resultUserID[0].name,
          email: resultUserID[0].email,
          phone: resultUserID[0].phone,
          address: resultUserID[0].address,
          status: resultUserID[0].status,
        });
      } else {
        setCheckInfo(false);
      }
    }
  };

  const addUser = async () => {
    setIsDisabled(true);
    const resultAPISIM = await addUsers(resultAdminID);
    if (resultAPISIM.message === 'OK') {
      setIsDisabled(false);
      setMessage('Thành Công');
      setOpenAlert(true);
      setResultAdminID({
        permission: 4,
        username: '',
        password: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        status: 1,
      });
    } else {
      setIsDisabled(false);
      setMessage('Thất Bại');
      setOpenAlert(true);
    }
  };

  const updateUser = async () => {
    const body = {
      id: idUser,
      data: resultAdminID,
    };
    const resultAPISIM = await updateUserID(body);
    if (resultAPISIM?.message === 'OK') {
      navigate('/dsusers');
    }
  };

  const checkUserName = async (user) => {
    const resultUser = await checkUserNames(user);
    if (resultUser?.totalRows > 0) {
      setIsDisabled(true);
      setIsCheckUserName(true);
    } else {
      setIsDisabled(false);
      setIsCheckUserName(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-6 lg:col-start-1 lg:col-span-5">
          <div className="flex justify-between">
            <h2 className="font-bold mb-2 text-xl text-orange-800">
              {checkInfo ? 'CẬP NHẬT THÔNG TIN' : 'THÊM NHÂN VIÊN'} /{' '}
              <Link to="/dsusers">
                <Button className="gap-2 bg-gradient-to-r from-teal-400 to-blue-500" size="sm">
                  Dah Sách Nhân Viên
                </Button>
              </Link>
            </h2>
          </div>

          <Card className="p-2">
            <Alert
              color={message === 'Thành Công' ? 'green' : 'red'}
              className="mb-2"
              open={openAlert}
              onClose={() => setOpenAlert(false)}
            >
              {message === 'Thành Công' ? 'Cập Nhật Thành Công' : 'Cập Nhật Thất Bại'}
            </Alert>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div>
                <Input
                  value={resultAdminID?.username}
                  onChange={(e) => {
                    changeValueAdmin({ username: e.target.value });
                    checkUserName(e.target.value);
                  }}
                  label="Tài khoản (viết liền không dấu)"
                  placeholder="Nhập tài khoản (vd: kland01)"
                  required
                />
                {isCheckUserName && (
                  <div className="mt-2 flex items-center gap-1 font-normal text-red-500">
                    <ShieldExclamationIcon className="h-5 w-5" />
                    <b> tài khoản này đã tồn tại</b>
                  </div>
                )}
              </div>
              <div>
                <Input
                  value={resultAdminID?.password}
                  onChange={(e) => {
                    changeValueAdmin({ password: e.target.value });
                  }}
                  label="Mật khẩu"
                  placeholder="Nhập mật khẩu"
                  required
                />
              </div>
              <div>
                <Select
                  label="Chức vụ"
                  value={`${resultAdminID?.permission}`}
                  onChange={(e) => {
                    changeValueAdmin({ permission: Number(e) });
                  }}
                >
                  <Option value={'1'}>Quản Trị</Option>
                  <Option value={'2'}>Kiểm Duyệt Viên</Option>
                  <Option value={'3'}>Quản Lý</Option>
                  <Option value={'4'}>Sale</Option>
                </Select>
              </div>
              <div>
                <Input
                  value={resultAdminID?.name}
                  onChange={(e) => {
                    changeValueAdmin({ name: e.target.value });
                  }}
                  placeholder="Nhập họ và tên"
                  label="Họ và Tên"
                />
              </div>
              <div>
                <Input
                  value={resultAdminID?.email}
                  onChange={(e) => {
                    changeValueAdmin({ email: e.target.value });
                  }}
                  placeholder="Nhập email"
                  label="Email"
                />
              </div>
              <div>
                <Input
                  value={resultAdminID?.phone}
                  onChange={(e) => {
                    changeValueAdmin({ phone: e.target.value });
                  }}
                  placeholder="Nhập số điện thoại"
                  label="Số điện thoại"
                />
              </div>
              <div>
                <Input
                  value={resultAdminID?.address}
                  onChange={(e) => {
                    changeValueAdmin({ address: e.target.value });
                  }}
                  placeholder="Nhập địa chỉ"
                  label="Địa chỉ"
                />
              </div>

              <div>
                <Select
                  label="Trạng thái"
                  value={`${resultAdminID?.status}`}
                  onChange={(e) => {
                    changeValueAdmin({ status: Number(e) });
                  }}
                >
                  <Option value={'1'}>Hoạt Động</Option>
                  <Option value={'2'}>Đã Nghỉ</Option>
                </Select>
              </div>

              <div className="mb-2">
                {checkInfo ? (
                  <Button
                    className="bg-gradient-to-r from-yellow-600 to-orange-500 w-full"
                    disabled={isDisabled}
                    onClick={updateUser}
                  >
                    Cập nhật
                  </Button>
                ) : (
                  <Button
                    className="bg-gradient-to-r from-orange-400 to-orange-900 w-full"
                    disabled={isDisabled}
                    onClick={addUser}
                  >
                    Lưu lại
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
