import { ArrowDownTrayIcon, HeartIcon as AB, UserIcon } from '@heroicons/react/24/outline';
import { addCmt, listProductIDShow, updateBds } from '../../../services/apiBds';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { convertNumberToWords, getCookie, decode, encode } from '../../../utils/helper';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { API_URL } from '../../../utils/urls';
import { BellAlertIcon, HeartIcon, MinusIcon } from '@heroicons/react/24/solid';
import { Alert, Button, Checkbox, Input, Textarea, Typography } from '@material-tailwind/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import DialogBds from '../../../components/DialogBds';
import { historysUser } from '../../../utils/history';
import LoadingFrame from '../../../components/loadingFrame';
import { listIdYeuthich } from '../../../services/apiYeuthich';

export default function BdsDetail() {
  const inputFile = useRef(null);
  const inputFileSo = useRef(null);
  const auth = getCookie('token');
  const params = useParams();
  const [message, setMessage] = useState(false);
  const [resultListBds, setResultListBds] = useState({});
  const [arrBDS, setArrBDS] = useState({
    gia: '',
    noidung: '',
    id_bds: decode(params.id),
    id_user: auth?.id,
    sdtchudat: '',
    anh: '',
    anhso: '',
    nhaclich: '',
    status: 1,
  });
  const [loading, setLoading] = useState(false);
  const [resultYT, setResultYT] = useState([]);
  const [openComment, setOpenComment] = useState({ one: false, two: false, three: false, four: false });
  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const [imagesSo, setImagesSo] = useState([]);
  const [imageURLSSo, setImageURLsSo] = useState([]);
  const [giaCmt, setGiaCmt] = useState(0);
  const [discountCmt, setDiscountCmt] = useState('');

  const listBdsAll = async (pages = 1, code = '') => {
    const resultBDSAll = await listProductIDShow(pages, '', code);
    if (resultBDSAll.length > 0) {
      setResultListBds(resultBDSAll[0]);
      const gia = resultBDSAll[0]?.bl?.filter((record) => parseFloat(record.gia) > 0);
      if (gia.length > 0) {
        setGiaCmt(gia[0]['gia']);
        const discountPercentage =
          parseFloat(resultBDSAll[0]['gia']) > gia[0]['gia']
            ? 'THẤP hơn ' + Math.round(((resultBDSAll[0]['gia'] - gia[0]['gia']) / resultBDSAll[0]['gia']) * 100) + '%'
            : 'TĂNG hơn ' + Math.round(((gia[0]['gia'] - resultBDSAll[0]['gia']) / resultBDSAll[0]['gia']) * 100) + '%';
        setDiscountCmt(discountPercentage);
      }
      historysUser({
        id_bds: decode(params.id),
        truong: 'xemchitiet',
        noidung: '1',
      });
      setLoading(false);
    } else {
      setResultListBds([]);
      setLoading(true);
    }
  };

  const listAllYeuThich = async () => {
    const resultAPI = await listIdYeuthich(decode(params.id), auth?.id);
    if (resultAPI.length > 0) {
      setResultYT(resultAPI[0]);
    }
  };
  useEffect(() => {
    if (params.id) {
      listBdsAll(1, decode(params.id));
      listAllYeuThich();
    }
  }, []);

  const downloadImagesAsZip = (arrImage, namezip) => {
    const zip = new JSZip();
    const imageUrls = arrImage?.split(',').length > 0 ? arrImage?.split(',') : [];

    const promises = imageUrls.map((imageUrl, index) => {
      return fetch(`${API_URL}/imgshop?name=${imageUrl}`)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(imageUrl, blob);
        });
    });

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, namezip + '.zip');
      });
    });
  };

  const downloadImage = (imageURL, index) => {
    saveAs(imageURL, `${index + 1}_${Date.now()}`);
  };

  // HÌNH ẢNH
  const onButtonClickFileImage = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onFilechange = (e) => {
    let tempArr = [];
    let tempFileArr = [];
    if (images.length + resultListBds.anhto?.split(',').length + e.target.files.length > 10) {
      alert('Bạn chỉ được thêm tối đa 10 ảnh');
      return;
    }
    [...e.target.files].forEach((file) => {
      if (file.type.startsWith('image/')) {
        tempArr.push(URL.createObjectURL(file));
        tempFileArr.push(file);
      } else {
        alert('Bạn không được thêm video hoặc tập tin');
        return;
      }
    });
    setImageURLs([...imageURLS, ...tempArr]);
    setImages([...images, ...tempFileArr]);
  };

  // xoa ảnh
  const removeImage = (index) => {
    const arrRemove = imageURLS.filter((_, i) => i !== index) || [];
    const arrRemoveFile = images.filter((_, i) => i !== index) || [];
    setImageURLs(arrRemove);
    setImages(arrRemoveFile);
  };

  // HÌNH ẢNH SỔ
  const onButtonClickFileImageSo = () => {
    // `current` points to the mounted file input element
    inputFileSo.current.click();
  };

  const onFilechangeSo = (e) => {
    let tempArr = [];
    let tempFileArr = [];
    if (imagesSo.length + resultListBds.anhthua?.split(',').length + e.target.files.length > 5) {
      alert('Bạn chỉ được thêm tối đa 5 ảnh hoặc tập tin');
      return;
    }
    [...e.target.files].forEach((file) => {
      if (file.type.startsWith('image/')) {
        tempArr.push(URL.createObjectURL(file));
        tempFileArr.push(file);
      } else if (file.type.startsWith('video/')) {
        alert('Bạn không được thêm video');
        return;
      } else {
        tempArr.push('');
        tempFileArr.push(file);
      }
    });
    setImageURLsSo([...imageURLSSo, ...tempArr]);
    setImagesSo([...imagesSo, ...tempFileArr]);
  };

  // xoa ảnh
  const removeImageSo = (index) => {
    const arrRemove = imageURLSSo.filter((_, i) => i !== index) || [];
    const arrRemoveFile = imagesSo.filter((_, i) => i !== index) || [];
    setImageURLsSo(arrRemove);
    setImagesSo(arrRemoveFile);
  };

  const subComment = async () => {
    const formData = new FormData();

    let arrAnhTo = [];
    let arrAnhThua = [];
    images.forEach((image, i) => {
      const extension = image.name.split('.').pop();
      let timestamp = i + '_img_' + Date.now() + '.' + extension;
      arrAnhTo.push(timestamp);
      formData.append('image', image, timestamp);
    });
    imagesSo.forEach((image, i) => {
      const extension = image.name.split('.').pop();
      let timestamp = i + '_to_' + Date.now() + '.' + extension;
      arrAnhThua.push(timestamp);
      formData.append('image', image, timestamp);
    });
    const arr = {
      gia: arrBDS.gia * 1000000000,
      noidung: arrBDS.noidung,
      sdtchudat: openComment.three ? arrBDS.sdtchudat : '',
      nhaclich: openComment.four ? arrBDS.nhaclich : '',
      anh: openComment.one ? arrAnhTo.join(',') : '',
      anhso: openComment.two ? arrAnhThua.join(',') : '',
      status: 1,
      id_bds: decode(params.id),
      id_user: auth?.id,
    };
    formData.append('data', JSON.stringify(arr));

    let arrBDSSV = {
      id: decode(params.id),
      thoihan: openComment.four ? arrBDS.nhaclich : resultListBds.thoihan,
      anhto: openComment.one ? [...arrAnhTo, ...resultListBds.anhto?.split(',')].join(',') : '',
      anhthua: openComment.two ? [...arrAnhThua, ...resultListBds.anhthua?.split(',')].join(',') : '',
    };
    const result = await updateBds({ data: JSON.stringify(arrBDSSV) });
    if (result.message === 'OK') {
      const upload = await addCmt(formData);
      if (upload.message === 'OK') {
        setImageURLs([]);
        setImageURLsSo([]);
        setMessage(true);
        setTimeout(() => {
          setMessage(false);
        }, 3000);
        setArrBDS({
          gia: '',
          noidung: '',
          id_bds: decode(params.id),
          id_user: auth?.id,
          sdtchudat: '',
          anh: '',
          anhso: '',
          nhaclich: '',
          status: 1,
        });
        setOpenComment({ one: false, two: false, three: false, four: false });
        listBdsAll(1, decode(params.id));
      }
    }
  };

  const subIsMuaBan = async (status) => {
    let arrBDSSV = {
      id: decode(params.id),
      status: status,
    };
    const result = await updateBds({ data: JSON.stringify(arrBDSSV) });
    if (result.message === 'OK') {
      setMessage(true);
      listBdsAll(1, decode(params.id));
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    }
  };

  return (
    <div className="container mx-auto">
      {Object.keys(resultListBds).length > 0 ? (
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">
          <div className="lg:col-span-5">
            <div className="flex gap-4">
              {' '}
              <div className="flex items-center text-pink-600 font-bold">
                {resultYT.id_bds === Number(decode(params.id)) ? (
                  <HeartIcon className="h-5 w-5" />
                ) : (
                  <AB className="h-5 w-5" />
                )}
                Thích
              </div>
              <div className="font-bold">
                Nhập lúc {resultListBds?.create_date?.split('T')[1]} - {resultListBds?.create_date?.split('T')[0]}
              </div>
              <div className="font-bold">MS: #{encode(resultListBds.id)}</div>
            </div>

            <PhotoProvider className="!z-[9999]">
              <div className="grid grid-cols-3 lg:grid-cols-6 gap-2 mt-5 ">
                {resultListBds.anhto &&
                  resultListBds.anhto?.split(',').length > 0 &&
                  resultListBds.anhto?.split(',').map((imageSrc, i) => (
                    <figure className="relative w-full" key={i}>
                      <PhotoView src={`${API_URL}/imgshop?name=${imageSrc}`}>
                        <LazyLoadImage
                          alt={'không có ảnh'}
                          width={'100%'}
                          effect="blur"
                          src={`${API_URL}/imgshop?name=${imageSrc}`}
                          className=" border hover:border-amber-500 hover:border-dashed p-1 rounded-2xl cursor-pointer"
                          // onClick={() => setFixModal(false)}
                        />
                      </PhotoView>
                      <figcaption className="absolute top-[5px] left-[29px] flex px-1 -translate-x-2/4 justify-center border border-white bg-white text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm rounded-lg">
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={() => downloadImage(`${API_URL}/imgshop?name=${imageSrc}`, i)}
                        >
                          <ArrowDownTrayIcon className="w-5 h-5 text-orange-600" />
                          <span className="text-orange-600 text-xs ml-1"> #{i + 1}</span>
                        </div>
                      </figcaption>
                    </figure>
                  ))}
              </div>
            </PhotoProvider>
            {resultListBds.anhto && (
              <div className="flex justify-center">
                <div
                  className="flex items-center cursor-pointer p-2 bg-orange-500 text-gray-900 font-bold rounded-xl hover:bg-green-300"
                  onClick={() => downloadImagesAsZip(resultListBds.anhto, 'anhbds')}
                >
                  <ArrowDownTrayIcon className="w-5 h-5" />
                  <span className="ml-1">Tải về tất cả</span>
                </div>
              </div>
            )}

            <div className="mt-4 leading-7">
              <p className="font-bold">
                {resultListBds?.tieude
                  ? resultListBds?.tieude
                  : `${resultListBds.loaibds ? resultListBds?.loaibds : ''}${
                      resultListBds.loaivitri ? ' - ' + resultListBds?.loaivitri : ''
                    }${resultListBds.phaply ? ' - ' + resultListBds?.phaply : ''}${
                      resultListBds.duong
                        ? ' - đường ' + resultListBds?.duong + ', ' + resultListBds?.xa + ', ' + resultListBds?.huyen
                        : ''
                    }${resultListBds.rongduong ? ', đường trước nhà ' + resultListBds?.rongduong + 'm' : ''}`}
              </p>
              <div className=" font-sans">
                {' '}
                <p>
                  {resultListBds?.dientich
                    ? '👉 Diện tích: ' +
                      (resultListBds.dientich && resultListBds.dai
                        ? ` ${resultListBds.ngang ? resultListBds.ngang + 'm x' : ''} ${
                            resultListBds.dai ? resultListBds.dai + 'm' : ''
                          } - ${resultListBds.dientich}m2`
                        : `  ${resultListBds.dientich ? ' DT ' + resultListBds.dientich + 'm2' : ''}`) +
                      (resultListBds.dientichdatnn !== '0'
                        ? resultListBds?.dientichdatnn
                          ? ' (' + resultListBds?.dientichdatnn + ' m2 đất nông nghiệp) '
                          : ''
                        : '') +
                      (resultListBds?.ngang ? ' ,ngang ' + resultListBds?.ngang + 'm' : '')
                    : ''}
                </p>
                <p>
                  {resultListBds.sotang !== '0'
                    ? '👉 Kết cấu: ' +
                      (resultListBds?.sotang ? resultListBds?.sotang + ' tầng' : '') +
                      (resultListBds.sophong !== '0'
                        ? (resultListBds?.sophong ? ' - ' + resultListBds?.sophong + ' phòng' : '') +
                          (resultListBds.sowc ? ' - ' + resultListBds?.sowc + ' WC' : '')
                        : '')
                    : ''}
                </p>
                <p>{resultListBds?.quyhoach ? '👉 Quy hoạch: ' + resultListBds?.quyhoach : ''}</p>
                <p>{resultListBds?.phaply ? '👉 Pháp lý: ' + resultListBds?.phaply : ''}</p>
                <p>{resultListBds?.huongbds ? '👉 Hướng: ' + resultListBds?.huongbds : ''}</p>
                <p>{resultListBds.dientichsan ? '👉 Tổng diện tích sàn ' + resultListBds?.dientichsan + 'm2' : ''}</p>
                <p>
                  {resultListBds?.dactrung || resultListBds?.tiennghi || resultListBds?.notekhach
                    ? '👉 Mô tả: ' +
                      (resultListBds?.tiennghi ? ' - ' + resultListBds?.tiennghi : '') +
                      (resultListBds?.dactrung ? ' - ' + resultListBds?.dactrung : '')
                    : ''}
                </p>
              </div>
              <div
                className=" font-sans"
                dangerouslySetInnerHTML={{
                  __html: resultListBds?.notekhach ? resultListBds?.notekhach.replace(/\n/g, '<br />') : '',
                }}
              />

              <div className="mt-5 lg:flex lg:items-center">
                {resultListBds.status === '2' ? (
                  <p className="font-bold text-red-700 font-sans ml-1">Đã Bán</p>
                ) : (
                  <p className="font-bold text-green-700 font-sans ml-1">
                    {giaCmt > 0
                      ? convertNumberToWords(giaCmt)
                      : convertNumberToWords(resultListBds?.gia ? resultListBds?.gia : resultListBds?.giathue)}{' '}
                    {giaCmt > 0
                      ? resultListBds?.dientich
                        ? `- ${convertNumberToWords(parseInt(giaCmt) / parseInt(resultListBds?.dientich))}/m2`
                        : ''
                      : resultListBds?.gia && resultListBds?.dientich
                      ? `- ${convertNumberToWords(parseInt(resultListBds?.gia) / parseInt(resultListBds?.dientich))}/m2`
                      : ''}
                    <span className="font-light text-gray-900">
                      {giaCmt > 0 && resultListBds?.gia
                        ? parseInt(resultListBds?.gia) > parseInt(giaCmt)
                          ? ` (đã giảm ${convertNumberToWords(parseInt(resultListBds?.gia) - parseInt(giaCmt))})`
                          : ` (đã tăng ${convertNumberToWords(parseInt(giaCmt) - parseInt(resultListBds?.gia))})`
                        : ''}{' '}
                    </span>
                    <span className="font-thin text-black">- MS: #{encode(resultListBds.id)} </span>
                  </p>
                )}
                {discountCmt && <p className="font-bold text-green-700">| {discountCmt}</p>}
                <Link
                  to={`/bang-gia-m2?lbs=${resultListBds?.loaibds}&lvt=${resultListBds?.loaivitri}&d=${resultListBds?.duong}&h=${resultListBds?.huyen}&gia=${resultListBds?.gia}&dt=${resultListBds?.dientich}`}
                  target="_blank"
                >
                  <p className="hover:underline hover:text-blue-500 cursor-pointer font-sans">
                    {resultListBds?.tlq?.totalRow > 0
                      ? ` | ${convertNumberToWords(
                          parseInt(resultListBds?.tlq?.price) / parseInt(resultListBds?.tlq?.dt),
                        )}/m2 (${resultListBds?.tlq?.totalRow} bđs)`
                      : ''}
                  </p>
                </Link>
              </div>
            </div>
            {resultListBds?.bl?.length > 0 && (
              <div>
                <hr className="my-2 border-blue-gray-50" />
                {resultListBds?.bl?.map((row, i) => (
                  <p key={i} className="flex items-center mb-1">
                    <b className="flex items-center text-sm">
                      <UserIcon className="w-5 h-5 mr-1" /> {row.username} - {row?.update_date?.split('T')[1]} -{' '}
                      {row?.update_date?.split('T')[0]}:
                    </b>
                    <span className="ml-4 text-sm">
                      {row?.gia > 0
                        ? parseInt(resultListBds?.gia) > parseInt(row?.gia)
                          ? ` đã GIẢM ${convertNumberToWords(parseInt(resultListBds?.gia))} còn ${convertNumberToWords(
                              row?.gia,
                            )}`
                          : ` đã TĂNG ${convertNumberToWords(parseInt(resultListBds?.gia))} lên ${convertNumberToWords(
                              row?.gia,
                            )}`
                        : ''}
                      {row?.noidung ? ' - ' + row?.noidung : ''}
                      {row?.sdtchudat ? ' - đã thêm số chủ đất' : ''}
                      {row?.anh ? ' - đã thêm ảnh' : ''}
                      {row?.anhso ? ' - đã thêm ảnh sổ' : ''}
                      {row?.nhaclich
                        ? ' - đã nhắc nhở ' + new Date(row?.nhaclich).toLocaleDateString() + ' hết hạn bán'
                        : ''}
                    </span>
                  </p>
                ))}
              </div>
            )}
            <hr className="my-2 border-blue-gray-50" />
            <div>
              {resultListBds?.notenoibo && (
                <span className="text-black">👉 Ghi chú (nội bộ): {resultListBds?.notenoibo}</span>
              )}
            </div>
            <div className="flex mt-2">
              <DialogBds data={resultListBds} />
            </div>
            <hr className="my-2 border-blue-gray-50" />
            <div className="mt-10 text-center">
              <b>
                👉 Nhập bởi:{' '}
                <b className={`${resultListBds.is_user === 2 ? 'text-gray-500' : ''}`}>
                  {resultListBds.is_user === 2 ? 'ĐÂ NGHỈ' : ''} {resultListBds.name} - {resultListBds.username} -{' '}
                  {resultListBds.address}
                </b>
              </b>
            </div>
          </div>

          <div className="lg:col-span-2 bg-white rounded-2xl">
            <div
              className="border-[6px] border-gray-100 rounded-2xl"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.26) 0px 5px 15px' }}
            >
              <div className="border border-dashed border-orange-500 rounded-2xl">
                <div className="flex items-center bg-orange-400 rounded-2xl p-2 font-bold">
                  <MinusIcon className="h-5 w-5" /> Bình luận công khai
                </div>
                <div className="bg-white mt-2 p-2 rounded-2xl">
                  {message && (
                    <Alert
                      icon={<BellAlertIcon className="h-5 w-5" />}
                      className="rounded-none border-l-4 border-[#2ec946] bg-[#2ec946]/10 font-medium text-[#2ec946] flex items-center mb-2"
                    >
                      <b>THÊM THÀNH CÔNG</b>
                    </Alert>
                  )}
                  <div className="grid grid-cols-2 gap-2">
                    <div
                      className="p-1 bg-orange-500 text-white rounded-xl text-xs text-center cursor-pointer"
                      onClick={() => subIsMuaBan('2')}
                    >
                      Ngừng bán?
                    </div>
                    <div
                      className="p-1 bg-green-500 text-white rounded-xl text-xs text-center cursor-pointer"
                      onClick={() => subIsMuaBan('1')}
                    >
                      Còn bán?
                    </div>
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-xs mb-1 mt-5">
                      <b>Giá Bán Mới (chỉ nhập số - nếu có)</b>
                    </label>
                    <Input
                      value={arrBDS?.gia}
                      placeholder="chỉ nhập số "
                      className="w-full rounded-xl !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, gia: e.target.value });
                        // console.log(e.target.value * 1000000000);
                      }}
                    />
                    {arrBDS?.gia && (
                      <b className="text-red-600 text-xs">{convertNumberToWords(arrBDS?.gia * 1000000000)}</b>
                    )}
                    <div className="text-[10px] font-bold mb-2">VD: 50,95 tỷ nhập 50.95</div>
                    <label className="flex items-end gap-1 text-xs mb-1 mt-3">
                      <b>Nội dung (Không nhập SĐT chủ ở đây)</b>
                    </label>
                    <Textarea
                      value={arrBDS?.noidung}
                      type="text"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, noidung: e.target.value });
                      }}
                    />
                    <div className="text-[10px] font-bold">SĐT bên Chủ thì nhập riêng vào ô SĐT bên dưới</div>
                    <div className="text-[10px] font-bold">Có thể nhấn Ctrl+v để Dán nội dung đã Copy</div>

                    {openComment?.one && (
                      <div>
                        <label className="flex items-end gap-1 text-xs mb-1 mt-3">
                          <b>++ Thêm Hình sản phẩm ?</b>
                        </label>
                        <span className="text-sm">Chỉ hình BĐS, KHÔNG hình Sổ</span>

                        <div>
                          <input
                            type="file"
                            accept="image/*"
                            multiple
                            name="image"
                            id="file"
                            ref={inputFile}
                            style={{ display: 'none' }}
                            onChange={onFilechange}
                          />{' '}
                          <div className="grid lg:grid-cols-2 gap-4">
                            {imageURLS?.map((imageSrc, i) => (
                              <figure className="relative w-full border border-amber-500 border-dashed p-1" key={i}>
                                <img src={imageSrc} alt="not fount" width={'100%'} />
                                <figcaption className="absolute bottom-4 left-2/4 flex px-1 -translate-x-2/4 justify-center rounded-xl border border-white bg-red-900 text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                                  <div>
                                    <span onClick={() => removeImage(i)} className="cursor-pointer">
                                      xóa
                                    </span>
                                  </div>
                                </figcaption>
                              </figure>
                            ))}
                          </div>
                          {imageURLS.length <= 10 && (
                            <Button onClick={onButtonClickFileImage}>CHỌN HÌNH ẢNH (KHÔNG VIDEO)</Button>
                          )}
                        </div>
                      </div>
                    )}
                    {openComment?.two && (
                      <div>
                        <label className="flex items-end gap-1 text-xs mb-1 mt-3">
                          <b>++ Bổ sung Sổ, giấy tờ ?</b>
                        </label>
                        <span className="text-xs">Chỉ liên quan tới Sổ, giấy tờ</span>

                        <div>
                          <div className="col-span-2">
                            <input
                              type="file"
                              // accept="image/*"
                              multiple
                              name="image"
                              id="file"
                              ref={inputFileSo}
                              style={{ display: 'none' }}
                              onChange={onFilechangeSo}
                            />{' '}
                            <div className="grid grid-cols-2 gap-4">
                              {imageURLSSo?.map((imageSrc, i) => (
                                <figure className="relative w-full border border-amber-500 border-dashed p-1" key={i}>
                                  {imageSrc ? (
                                    <img src={imageSrc} alt="not fount" width={'100%'} />
                                  ) : (
                                    <a href={URL.createObjectURL(imagesSo[i])} download={imagesSo[i].name}>
                                      {imagesSo[i].name}
                                    </a>
                                  )}
                                  <figcaption className="absolute bottom-4 left-2/4 flex px-1 -translate-x-2/4 justify-center rounded-xl border border-white bg-red-900 text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                                    <div>
                                      <span onClick={() => removeImageSo(i)} className="cursor-pointer">
                                        xóa
                                      </span>
                                    </div>
                                  </figcaption>
                                </figure>
                              ))}
                            </div>
                            {imageURLSSo.length <= 5 && (
                              <Button onClick={onButtonClickFileImageSo}>
                                CHỌN SỔ: HÌNH ẢNH, TẬP TIN (KHÔNG VIDEO)
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {openComment?.three && (
                      <div>
                        <label className="flex items-end gap-1 text-xs mb-1 mt-3">
                          <b>Nhập SĐT kèm Tên Chủ mới</b>
                        </label>
                        <Input
                          value={arrBDS?.sdtchudat}
                          placeholder="VD: 0912345678 Viet Nam "
                          className="w-full rounded-xl !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                          labelProps={{
                            className: 'hidden',
                          }}
                          onChange={(e) => {
                            setArrBDS({ ...arrBDS, sdtchudat: e.target.value });
                          }}
                        />
                        <div className="text-[10px] font-bold">Theo mẫu: 0912345678 Viet Nam</div>
                      </div>
                    )}

                    {openComment?.four && (
                      <div>
                        <label className="flex items-end gap-1 text-xs mb-1 mt-3">
                          <b>Lên lịch nhắc nhở</b>
                        </label>
                        <Input
                          value={arrBDS?.nhaclich}
                          placeholder="dd/mm/yy"
                          className="w-full rounded-xl !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                          labelProps={{
                            className: 'hidden',
                          }}
                          type="date"
                          onChange={(e) => {
                            setArrBDS({ ...arrBDS, nhaclich: e.target.value });
                          }}
                        />
                      </div>
                    )}

                    <div className="text-xs font-bold mt-2">Tuy chọn thêm</div>
                    <div>
                      <Checkbox
                        color="blue"
                        label={<span className="font-bold text-gray-800">Thêm Hình Sản Phẩm</span>}
                        containerProps={{
                          className: 'mt-1, p-2',
                        }}
                        checked={openComment?.one}
                        onChange={() => {
                          setOpenComment({ ...openComment, one: !openComment.one });
                        }}
                      />
                    </div>
                    <div>
                      <Checkbox
                        color="blue"
                        label={<span className="font-bold text-gray-800">Thêm Sổ, giấy tờ BĐS</span>}
                        containerProps={{
                          className: 'mt-1, p-2',
                        }}
                        checked={openComment?.two}
                        onChange={() => {
                          setOpenComment({ ...openComment, two: !openComment.two });
                        }}
                      />
                    </div>
                    <div>
                      <Checkbox
                        color="blue"
                        label={<span className="font-bold text-gray-800">Thêm SĐT liên hệ, chủ nhà</span>}
                        containerProps={{
                          className: 'mt-1, p-2',
                        }}
                        checked={openComment?.three}
                        onChange={() => setOpenComment({ ...openComment, three: !openComment.three })}
                      />
                    </div>
                    <div>
                      <Checkbox
                        color="blue"
                        label={<span className="font-bold text-gray-800">Lên lịch nhắc nhở?</span>}
                        containerProps={{
                          className: 'mt-1, p-2',
                        }}
                        checked={openComment?.four}
                        onChange={() => setOpenComment({ ...openComment, four: !openComment.four })}
                      />
                    </div>
                    <div>
                      <Button color="orange" size="sm" className="mt-3" onClick={subComment}>
                        Gửi Cập Nhật
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : loading ? (
        <div className="flex justify-center">
          <b>BÀI VIẾT KHÔNG TỒN TẠI</b>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
          <div className="lg:col-span-3">
            <LoadingFrame />
          </div>
        </div>
      )}
    </div>
  );
}
