import { ArrowRightCircleIcon, InformationCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { PencilIcon, TrashIcon, UserMinusIcon, UserPlusIcon } from '@heroicons/react/24/solid';
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { listUserIDAll, listUserIDAllCount, updateUserID } from '../../../services/apiUsers';

const TABS = [
  {
    label: 'Tất Cả',
    value: '',
  },
  {
    label: 'Hoạt_Động',
    value: 1,
  },
  {
    label: 'Đã_Nghỉ',
    value: 2,
  },
];

const TABLE_HEAD = ['Tài khoản', 'Họ tên', 'Số điện thoại', 'Trạng thái', 'Ngày tạo', 'Tác vụ'];

export default function DsUsers() {
  const [resultListUsers, setResultListUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [countUser, setCountUser] = useState(0);
  const [objBoLoc, setObjBoLoc] = useState({ status: '1', username: '' });

  const listUserAll = async (isstatus = '', username = '', pages = 1) => {
    const resultUserAll = await listUserIDAll(isstatus, username, pages);
    if (resultUserAll.length > 0) {
      setResultListUsers(resultUserAll);
      const countUser = await listUserIDAllCount(isstatus, username);
      setCountUser(countUser.totalRows);
    } else {
      setResultListUsers([]);
    }
  };

  useEffect(() => {
    listUserAll(1);
    document.title = 'Danh sách nhân viên';
  }, []);

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      listUserAll(objBoLoc.status, objBoLoc.username, 1);
    }
  };

  const nextPage = () => {
    const total = Math.ceil(countUser / 50);
    if (total > page) {
      setPage(page + 1);
      listUserAll(objBoLoc.status, objBoLoc.username, page + 1);
    }
  };

  const prevPage = () => {
    const total = Math.ceil(countUser / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listUserAll(objBoLoc.status, objBoLoc.username, page - 1);
    }
  };

  const alertRemoveUser = async (id, status) => {
    if (
      window.confirm(status === 1 ? `Bạn đã chắc chắn NGỪNG HOẠT ĐỘNG USER` : `Bạn muốn cho USER HOẠT ĐỘNG chở lại`)
    ) {
      // Save it!
      const body = {
        id: id,
        data: { status: status === 1 ? 2 : 1 },
      };
      const resultAPISIM = await updateUserID(body);
      if (resultAPISIM?.message === 'OK') {
        listUserAll(objBoLoc.status, objBoLoc.username, 1);
      }
    } else {
      // Do nothing!
      console.log('Hủy yêu cầu');
    }
  };
  return (
    <Card className="w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8">
          <div>
            <div className="text-xl font-bold">DANH SÁCH NHÂN VIÊN</div>
            {/* <Typography color="gray" className="mt-1 font-normal">
              Tất cả nhân viên đang hoạt động và đã nghỉ
            </Typography> */}
          </div>
          <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
            {/* <Button variant="outlined" size="sm">
              view all
            </Button> */}
            <Link to="/users">
              <Button className="flex items-center gap-3 bg-amber-900" size="sm">
                <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Thêm Nhân Viên Mới
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
          <Tabs value={1} className="w-full md:w-max">
            <TabsHeader>
              {TABS.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => {
                    listUserAll(value, objBoLoc.username, 1);
                    setObjBoLoc({ ...objBoLoc, status: value });
                  }}
                >
                  &nbsp;&nbsp;{label}&nbsp;&nbsp;
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
          <div className="w-full md:w-80">
            <Input
              label="Tìm kiếm theo tài khoản"
              value={objBoLoc?.username}
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              onChange={(e) => setObjBoLoc({ ...objBoLoc, username: e.target.value })}
              onKeyPress={handleEnterKey}
            />
            <Typography variant="small" color="gray" className="mt-2 flex items-center gap-1 font-normal">
              <InformationCircleIcon className="h-4 w-4" />
              Nhập tài khoản tìm kiếm và sau đó ấn enter
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-auto px-0">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {resultListUsers.map((row, index) => {
              const isLast = index === resultListUsers.length - 1;
              const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50';

              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <label className="font-normal text-base">
                          <b>{row.username}</b>
                        </label>
                        <label className="font-normal text-sm opacity-70">{row.email}</label>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <label className="font-normal text-base">{row.name}</label>
                      <label className="font-normal text-sm opacity-70">
                        {row.permission === 1
                          ? '(Quản Trị)'
                          : row.permission === 2
                          ? '(Kiểm Duyệt Viên)'
                          : row.permission === 3
                          ? '(Quản Lý)'
                          : '(Sale)'}
                      </label>
                    </div>
                  </td>

                  <td className={classes}>
                    <label className="font-normal text-base">{row.phone}</label>
                  </td>
                  <td className={classes}>
                    <div className="w-max">
                      <Chip
                        variant="ghost"
                        size="sm"
                        value={row.status === 1 ? 'Hoạt động' : 'Đã nghỉ'}
                        color={row.status === 1 ? 'green' : 'blue-gray'}
                      />
                    </div>
                  </td>

                  <td className={classes}>
                    <label className="font-normal text-base">{row?.create_date}</label>
                  </td>
                  <td className={classes}>
                    <Tooltip content="Sửa User">
                      <Link to={`/users?u=${row.id}`}>
                        <IconButton variant="text">
                          <PencilIcon className="h-4 w-4" />
                        </IconButton>
                      </Link>
                    </Tooltip>

                    <Tooltip content={`${row.status === 1 ? 'Ngưng hoạt động' : 'Hoạt động lại'}`}>
                      <IconButton variant="text" onClick={() => alertRemoveUser(row.id, row.status)}>
                        <UserMinusIcon className={`h-4 w-4 ${row.status === 1 ? 'text-red-500' : 'text-green-500'}`} />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>
      {Math.ceil(countUser / 50) > page && (
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {page} of {Math.ceil(countUser / 50)}
          </Typography>
          <div className="flex gap-2">
            <Button variant="outlined" size="sm" onClick={prevPage}>
              Trang Trước
            </Button>
            <Button variant="outlined" size="sm" onClick={nextPage}>
              Trang Tiếp
            </Button>
          </div>
        </CardFooter>
      )}
    </Card>
  );
}
